export module EnumType {

    export enum Status {
        PENDING = 'Pending',
        ACTIVE = 'Active',
        DELETED = 'Deleted',
        INACTIVE = 'Inactive'
    }

    export enum Role {
        ACCOUNT_ADMIN = 'account_admin',
        SYS_ADMIN = 'sys_admin',
    }

    export enum Platform {
        WEB = 'Web',
        MOBILE = 'Mobile'
    }

    export enum LocalStorage {
        AUTH = 'auth',
        COMPANY = '_C',
        SHOPPING_CART = '_EC_SC',
        SHIPPING_ADDRESS = '_EC_SA',
        MESSAGES = '_M',
        IDENTIFY_FIELD = '_E',
        PARAMETERS = '_P',
        SEAT = '_S',
        VISANET = '_VN'
    }

    export enum StatusProcess {
        PRE_PROCESS_PENDING = 'PRE_PROCESS_PENDING',
        PRE_PROCESS_FAILED = 'PRE_PROCESS_FAILED',
        PRE_PROCESS_DONE = 'PRE_PROCESS_DONE',
        PROCESS_CANCELLED_BY_USER = 'PROCESS_CANCELLED_BY_USER', // <== when???
        PROCESS_CANCELLED_BY_PROCESS = 'PROCESS_CANCELLED_BY_PROCESS',
        PROCESS_PENDING = 'PROCESS_PENDING',
        PROCESS_DONE = 'PROCESS_DONE',
        PROCESS_DONE_WITH_WARNINGS = 'PROCESS_DONE_WITH_WARNINGS',
        PROCESS_FAILED = 'PROCESS_FAILED'
    }

    export enum StatusFile {
        NOT_REVIEWED = 'NOT_REVIEWED',
        REVIEWED = 'REVIEWED',
        SIGNED = 'SIGNED',
    }

    export enum ContentType {
        JSON = 'application/json'
    }

    export enum ResponseType {
        BLOB = 'blob',
        ARRAY_BUFFER = 'arraybuffer'
    }

    export enum ErrorCode {
        TOKEN_EXPIRED = 'TOKEN_EXPIRED',
        SERVER_UNAVAILABLE = 'SERVER_UNAVAILABLE',
        SERVER_ERROR = 'SERVER_ERROR',
        SERVER_TIMEOUT = 'SERVER_TIMEOUT',
        EMPTY_RESULT = 'EMPTY_RESULT'
    }

    export enum ShippingMethodType {
        DISPATCH = 'DISPATCH',
        STORE = 'STORE'
    }

    export enum PaymentDocumentType {
        FACTURA = 'FACTURA',
        BOLETA = 'BOLETA'
    }

    export enum EcommerceRules {
        MODIFY_QUANTITIES_DETAIL = 'modify_quantities_detail',
        REQUIRES_METADATA_DETAIL = 'requires_metadata_detail',
        METADATA_INTEGRATION_SEARCH = 'metadata_integration_search',
        METADATA_INTEGRATION_SAVE = 'metadata_integration_save',
        IDENTIFY_CUSTOMER = 'identify_customer',
        BYPASS_IDENTIFY_PAGE = 'bypass_identify_page',
        IDENTIFICATION_REQUIRED = 'identification_required',
        SHOW_CUSTOMER_DATA = 'show_customer_data',
        ALLOW_CURRENCY = 'allow_currency',
        ALLOW_SHIPPING = 'allow_shipping',
        ALLOW_STORE_PICKUP = 'allow_store_pickup',
        ALLOW_DELIVERY = 'allow_delivery',
        ALLOW_DELIVERY_TIME = 'allow_delivery_time',
        ALLOW_PAYMENT_METHOD = 'allow_payment_method',
        ALLOW_PAYMENT_DOCUMENT = 'allow_payment_document',
        ALLOW_POLICIES = 'allow_policies',
        REQUIRES_POLICIES = 'requires_policies',
        SAVE_CUSTOMER_DATA = 'save_customer_data',
        FIND_ADDRESS_SHIPPING = 'find_address_shipping',
        SAVE_ADDRESS_SHIPPING = 'save_address_shipping',
        FIND_DELIVERY_TIME = 'find_delivery_time',
        FIND_PAYMENT_METHOD = 'find_payment_method',
        FIND_SEAT = 'find_seat',
        FIND_AGENCIES = 'find_agencies',
        LOCK_SEAT = 'lock_seat',
        UNLOCK_SEAT = 'unlock_seat',
        REMOVE_DETAIL = 'remove_detail',
        INDICATE_METADATA_BUYER = 'indicate_metadata_buyer',
        AFFECTED_IGV_ORDER = 'affected_IGV_order',
        SHOW_SKU_PRODUCT = 'show_sku_product',
        TWO_FACTOR_IDENTIFY_CUSTOMER = 'two_factor_identify_customer',
    }

    export enum Pages {
        SHOPPING_CART = 'Shopping_Cart',
        METADATA_FORM = 'Metadata_Form',
        IDENTIFY_CUSTOMER = 'Identify_Customer',
        SHOPPING_CART_DETAIL = 'Shopping_Cart_Detail',
        CUSTOMER = 'Customer',
        SHIPPING_ADDRESS_LIST = 'Shipping_Address_List',
        SHIPPING_ADDRESS = 'Shipping_Address',
        DELIVERY_TIME = 'Delivery_Time',
        PAYMENT_METHOD = 'Payment_Method',
        PAYMENT_DOCUMENT = 'Payment_Document',
        SEAT_ASSIGNMENT = 'Seat_Assignment',
        CONFIRM = 'Confirm'
    }
}

<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

    <!-- <div class="content-title">
        {{ page_title | labelTranslator }}
    </div> -->

    <div class="dcard p-0" id="card-bus">

        <div class="tab-wrapper">
            <ul class="nav nav-tabs" role="tablist" id="tabBus">
                <li class="nav-item">
                    <a class="nav-link p-0 h-100 active" id="nav-seat-depart-tab" aria-selected="true" data-toggle="tab"
                        href="#nav-seat-depart" role="tab" aria-controls="nav-seat-depart" aria-selected="true">
                        <div class="h-100 d-flex flex-column align-items-center justify-content-center px-2 pt-1">
                            <div class="dfont dfont-bold text-center nav-seat-depart-title">
                                {{ 'IDA' | labelTranslator }} |
                                {{ shoppingCart.extended_fields.cds_origin + ' - ' +
                                shoppingCart.extended_fields.cds_destination | uppercase }}
                            </div>
                            <div class="dfont dfont-bold text-center nav-seat-depart-title sub-title">
                                {{ shoppingCart.extended_fields.cds_service_description_depart}}
                            </div>
                            <div class="dfont dfont-bold text-center nav-seat-depart-title sub-title">
                                {{ shoppingCart.extended_fields.cds_date_depart_trip }}
                            </div>
                        </div>
                    </a>
                </li>
                <li class="nav-item" *ngIf="hasReturn">
                    <a class="nav-link p-0 h-100" id="nav-seat-return-tab" aria-selected="true" data-toggle="tab"
                        href="#nav-seat-return" role="tab" aria-controls="nav-seat-return" aria-selected="true">
                        <div class="h-100 d-flex flex-column align-items-center justify-content-center px-2 pt-1">
                            <div class="dfont dfont-bold text-center nav-seat-return-title">
                                {{ 'RETORNO' | labelTranslator }} |
                                {{ shoppingCart.extended_fields.cds_destination + ' - ' +
                                shoppingCart.extended_fields.cds_origin | uppercase }}
                            </div>
                            <div class="dfont dfont-bold text-center nav-seat-depart-title sub-title">
                                {{ shoppingCart.extended_fields.cds_service_description_return }}
                            </div>
                            <div class="dfont dfont-bold text-center nav-seat-depart-title sub-title">
                                {{ shoppingCart.extended_fields.cds_date_return_trip }}
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <!--seat depart-->
                <div class="tab-pane fade show active" id="nav-seat-depart" role="tabpanel"
                    aria-labelledby="nav-seat-depart-tab">

                    <ng-container *ngIf="messageErrorDepart !== undefined">
                        <div class="alert alert-secondary m-2" role="alert">
                            {{ messageErrorDepart | labelTranslator }} ...
                        </div>
                    </ng-container>
                    <ng-container *ngIf="messageErrorDepart === undefined">
                        <div class="d-flex justify-content-center flex-row">
                            <div class="bus py-2 pl-2 pr-1">
                                <div *ngIf="shoppingCart != undefined" class="mb-3" style="width: 90%;">
                                    <div class="dfont dfont-bold pt-2 pb-2 text-center" style="font-size: 1.2em;">
                                        {{ page_title + (hasReturn ? ' - IDA' : '') | labelTranslator | uppercase }}
                                    </div>
                                    <div class="border-bottom-description">&nbsp;</div>
                                </div>

                                <app-seats [primerPiso]="primerPisoIda" [segundoPiso]="segundoPisoIda"
                                    (onClickSeat)="selectSeatDepart($event)"></app-seats>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!--seat return-->
                <div class="tab-pane fade" id="nav-seat-return" role="tabpanel" aria-labelledby="nav-seat-return-tab"
                    *ngIf="hasReturn">

                    <ng-container *ngIf="messageErrorReturn !== undefined">
                        <div class="alert alert-secondary m-2" role="alert">
                            {{ messageErrorReturn | labelTranslator }}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="messageErrorReturn === undefined">
                        <div class="d-flex justify-content-center flex-row">
                            <!--bus-->
                            <div class="bus py-2 pl-2 pr-1">
                                <div *ngIf="shoppingCart != undefined" class="mb-3" style="width: 90%;">
                                    <div class="dfont dfont-bold pt-2 pb-2 text-center" style="font-size: 1.2em;">
                                        {{page_title + ' - RETORNO' | labelTranslator | uppercase }}
                                        <!-- <div class="border-bottom-description">&nbsp;</div> -->
                                    </div>
                                    <!-- <div class="dfont dfont-bold pt-1 text-center">
                                        {{ shoppingCart.extended_fields.cds_service_description_depart | uppercase }}
                                    </div>
                                    <div class="dfont dfont-bold pb-1 text-center">
                                        {{ shoppingCart.extended_fields.cds_date_depart_trip | uppercase }}
                                    </div>-->
                                    <div class="border-bottom-description">&nbsp;</div>
                                </div>
                                <app-seats [primerPiso]="primerPisoRetorno" [segundoPiso]="segundoPisoRetorno"
                                    (onClickSeat)="selectSeatReturn($event)"></app-seats>

                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <div class="sub-title"
                        style="display: inline-flex;margin-right: 5px;margin-bottom: 10px;width: auto">
                        <div>
                            <span class="legend-icon sub-title"
                                style="background: rgb(255, 255, 255);color: rgb(42, 73, 181);">#</span>
                        </div>
                        <div class="sub-title" style="font-family: Roboto,Helvetica,Arial,sans-serif;color: #132968;">
                            <div style="font-weight: 700;">Disponible</div>
                        </div>
                    </div>
                    <div class="sub-title"
                        style="display: inline-flex;margin-right: 5px;margin-bottom: 10px;width: auto">
                        <div>
                            <span class="legend-icon sub-title"
                                style="background: rgb(255, 255, 255);color: rgb(255, 0, 255);">#</span>
                        </div>
                        <div style="font-family: Roboto,Helvetica,Arial,sans-serif;color: #132968;">
                            <div style="font-weight: 700;">Locura</div>
                        </div>
                    </div>
                    <div class="sub-title"
                        style="display: inline-flex;margin-right: 5px;margin-bottom: 10px;width: auto">
                        <div>
                            <span class="legend-icon sub-title"
                                style="background: rgb(255, 255, 255);color: rgb(245, 34, 45);">#</span>
                        </div>
                        <div style="font-family: Roboto,Helvetica,Arial,sans-serif;color: #132968;">
                            <div style="font-weight: 700;">Insuperable</div>
                        </div>
                    </div>
                    <div class="sub-title"
                        style="display: inline-flex;margin-right: 5px;margin-bottom: 10px;width: auto">
                        <div>
                            <span class="legend-icon sub-title"
                                style="background: rgb(255, 255, 255);color: rgb(247, 156, 21);">#</span>
                        </div>
                        <div style="font-family: Roboto,Helvetica,Arial,sans-serif;color: #132968;">
                            <div style="font-weight: 700;">De Ocación</div>
                        </div>
                    </div>
                    <div class="sub-title"
                        style="display: inline-flex;margin-right: 5px;margin-bottom: 10px;width: auto">
                        <div>
                            <span class="legend-icon sub-title"
                                style="background: rgb(210, 210, 210);color: rgb(210, 210, 210);">#</span>
                        </div>
                        <div style="font-family: Roboto,Helvetica,Arial,sans-serif;color: #132968;">
                            <div style="font-weight: 700;">Ocupado</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <ng-container *ngIf="shoppingCart != undefined">
        <div class="dcard">
            <div class="container-fluid">
                <div class="row">
                    <div class="pt-3"
                        *ngIf="agenciesListDepartRetorno.length > 1 || agenciesListArrivalRetorno.length > 1"
                        style="width: 90%;">
                        <ng-container *ngIf="agenciesListDepartIda.length > 1">
                            <div class="dfont dfont-bold pb-1 text-center">
                                SELECCIONE SU AGENCIA DE EMBARQUE
                            </div>
                            <select class="form-control" [(ngModel)]="key_agency_departIda">
                                <option *ngFor="let agency of agenciesListDepartIda" value="{{agency.agency_key}}">
                                    {{
                                    agency.agency_description | uppercase }}
                                </option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="agenciesListArrivalIda.length > 1">
                            <div class="dfont dfont-bold pb-1 text-center">
                                SELECCIONE SU AGENCIA DE DESEMBARQUE
                            </div>
                            <select class="form-control" [(ngModel)]="key_agency_arrivalIda">
                                <option *ngFor="let agency of agenciesListArrivalIda" value="{{agency.agency_key}}">
                                    {{
                                    agency.agency_description | uppercase }}
                                </option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="agenciesListDepartRetorno.length > 1">
                            <div class="dfont dfont-bold pb-1 text-center mt-3">
                                SELECCIONE SU AGENCIA DE EMBARQUE
                            </div>
                            <select class="form-control" [(ngModel)]="key_agency_departRetorno">
                                <option *ngFor="let agency of agenciesListDepartRetorno" value="{{agency.agency_key}}">
                                    {{
                                    agency.agency_description | uppercase }}
                                </option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="agenciesListArrivalRetorno.length > 1">
                            <div class="dfont dfont-bold pb-1 text-center">
                                SELECCIONE SU AGENCIA DE DESEMBARQUE
                            </div>
                            <select class="form-control" [(ngModel)]="key_agency_arrivalRetorno">
                                <option *ngFor="let agency of agenciesListArrivalRetorno" value="{{agency.agency_key}}">
                                    {{
                                    agency.agency_description | uppercase }}
                                </option>
                            </select>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container> -->

    <div class="dcard">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <div class="text-left">
                        <span class="dfont dfont-bold">
                            Asientos:
                        </span>
                        <span class="dfont">
                            {{ shoppingCart.order_detail.length }}
                        </span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="text-right">
                        <span class="dfont dfont-bold">
                            Total:
                        </span>
                        <span class="dfont">
                            S/ {{ shoppingCart.total_amount | number: '1.2-2' : 'es-PE' }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="messageErrorDepart === undefined" class="container-fluid">
        <div class="row">
            <div class="col p-0 d-flex justify-content-center">
                <button id="btnSeat" type="button" class="btn btn-primary btn-block my-2 my-2 col-12"
                    (click)="redirectToCart()" [ngClass]="{'btn-disabled': progressButton.seat === true}"
                    data-isbusy="false">
                    <div class="svg-icon-spinner" *ngIf="progressButton.seat === true"></div>
                    {{ 'CONTINUAR'| labelTranslator }}
                </button>
            </div>
        </div>
    </div>
    <div class="text-center" *ngIf="isTesting">
        <button class="btn btn-info btn-sm" (click)="reloadPage()">
            <i class="fa fa-refresh" aria-hidden="true"></i>
        </button>
    </div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row">
                    <div class="form-group col-md-12 body-card-pending">
                        <span>{{ messageErrorSeat | labelTranslator }} </span>
                        <ng-container *ngIf="maxSeatDepartLimit">{{ seat_limit }}</ng-container>
                        <ng-container *ngIf="maxSeatReturnLimit">{{ seat_limit }}</ng-container>
                    </div>
                </div>
            </div>
            <div class="modal-footer mf-center">
                <button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
                    {{ 'CERRAR' | labelTranslator }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalMessageConfirm" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row">
                    <div class="form-group col-md-12 body-card-pending">
                        <span>{{ messageErrorSeat | labelTranslator }} </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer mf-center">
                <button type="button" class="btn btn-only-border btn-sm" (click)="redirectDirectToCart()">
                    {{ 'CONTINUAR' | labelTranslator }}
                </button>
                <button type="button" class="btn btn-primary btn-sm" (click)="selectMissingDestination()">
                    {{ 'SELECCIONAR ASIENTOS' | labelTranslator }}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalMessageLimit" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row">
                    <div class="form-group col-md-12 body-card-pending">
                        <span>{{ messageErrorSeatLimit | labelTranslator }} [{{ seat_limit }}]</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer mf-center">
                <button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
                    {{ 'CERRAR' | labelTranslator }}
                </button>
            </div>
        </div>
    </div>
</div>
export const environment = {
    production: false,
    base_url: 'https://api-dev.conexaris.com:52003',
    base_url_files: '',
    app_id: 'bWPipaxEXWATWXMNiN7gAzPw93z2wqug',
    bcrypt_salt: '$2a$10$2eUfZ70Uvt6GJv6am2zRjO',
    storage_encrypt_flag: true,
    ws_url_process: 'ws://localhost:52003',
    messages: {
        token_expired: {
            code: 'TOKEN_EXPIRED',
            description: 'Tu sesión ha expirado. Por favor iniciar sesión nuevamente.'
        },
        server_unavailable: {
            code: 'SERVER_UNAVAILABLE',
            description: 'El servicio se encuentra fuera de línea, será restablecido muy pronto.'
        },
        server_error: {
            code: 'SERVER_ERROR',
            description: 'Ha habido un incoveniente en el servicio. Intentelo nuevamente por favor.'
        },
        server_timeout: {
            code: 'SERVER_TIMEOUT',
            description: 'El servidor no ha respondido en el tiempo esperado. Intentelo nuevamente por favor.'
        },
        empty_result: {
            code: 'EMPTY_RESULT',
            description: 'No existen resultados.'
        },
        invalid_parameters: {
            code: "INVALID_PARAMETERS",
            description: 'Los parametros no son válidos.'
        }
    },
    companies: [
        {
            name: "conexaris",
            logo: "http://r1live.conexaris.com/LogosVirtualAssistant/Conexaris/ConexarisLogin.png",
            logo_app: "http://r1live.conexaris.com/LogosVirtualAssistant/Conexaris/ConexarisApp.png",
            account_string: 'AoExrvSaoBw9ywuUCN5w2HgtRT3q5K9u'
        },
        {
            name: "cruzdelsur",
            logo: "http://r1live.conexaris.com/LogosVirtualAssistant/CruzDelSur/CruzDelSurLogin.png",
            logo_app: "http://r1live.conexaris.com/LogosVirtualAssistant/CruzDelSur/CruzDelSurApp.png",
            account_string: 'ZGOMPWgbv0L5kPYGLLOv3MTcNJIEjtci'
        }
    ]
};

import { Pipe, PipeTransform } from '@angular/core';
import { CheckoutService } from '../../services/checkout/checkout.service';

@Pipe({
	name: 'labelTranslator'
})
export class LabelTranslatorPipe implements PipeTransform {

	constructor(
		private checkoutService: CheckoutService
	) { }

	transform(value: any): any {
		let json = this.checkoutService.getPageLabel();
		if (json[value])
			return json[value];
		else
			return value;
	}

}

<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title }}
	</div>

	<ng-container *ngFor="let shippingAddress of shippingAddressList;let i = index">
		<div id="dcard_{{i}}" class="dcard">
			<div class="d-flex flex-row pb-2">
				<div class="d-flex flex-fill flex-column">
					<div class="dfont body-card-complete text-uppercase">{{ shippingAddress.receiver.full_name }}
					</div>
					<div class="dfont body-card-pending"> {{ shippingAddress.address }}</div>
					<div class="dfont body-card-pending"> {{ shippingAddress.department }},
						{{ shippingAddress.province }}, {{ shippingAddress.district }}</div>
				</div>
				<div class="d-flex flex-column">
					<button id="btnSelectAddress_{{i}}" type="button" class="btn btn-block"
						[ngClass]="{'btn-primary': selectedShippingAddress.address_id == shippingAddress.address_id, 'btn-only-border': selectedShippingAddress.address_id != shippingAddress.address_id}"
						(click)="selectShippingAddress(shippingAddress)" data-isbusy="false">
						<i class="fa fa-check" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			<div class="d-flex">
				<button id="btnDeleteAddress_{{i}}" class="btn btn-danger-border mr-2"
					(click)="deleteShippingAddress(i, shippingAddress.address_id)" data-isbusy="false">
					<div class="svg-icon-spinner-black d-none"></div>
					BORRAR
				</button>
				<button id="btnEditAddress_{{i}}" class="btn btn-only-border mr-2"
					(click)="editShippingAddress(i, shippingAddress.address_id)"
					[ngClass]="{'btn-disabled': progressButton.edit === true}" data-isbusy="false">
					<div class="svg-icon-spinner-black d-none"></div>
					EDITAR
				</button>
			</div>
		</div>
	</ng-container>

	<div class="dcard">
		<div class="d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card text-uppercase pb-1">NUEVA DIRECCIÓN</div>
				<div class="dfont flex-fill body-card-pending">Incluye una dirección adicional</div>
			</div>
			<div class="d-flex flex-column">
				<button id="btnAddAddress" type="button" class="btn btn-block btn-primary"
					(click)="editShippingAddress()" [ngClass]="{'btn-disabled': progressButton.add === true}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.add === true"></div>
					AGREGAR
				</button>
			</div>
		</div>
	</div>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnRedirectCheckout" type="button" class="btn btn-primary btn-block my-2"
					(click)="redirectToCheckoutDetail()" [ngClass]="{'btn-disabled': progressButton.redirect === true}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.redirect === true"></div>
					CONTINUAR
				</button>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">MENSAJE</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					CERRAR
				</button>
			</div>
		</div>
	</div>
</div>
<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title }}
	</div>

	<ng-container *ngFor="let store of storelist;let i = index; trackBy: customTrackBy">
		<div class="dcard">
			<div class="d-flex flex-row">
				<div class="d-flex flex-fill flex-column">
					<div class="dfont body-card-complete text-uppercase">{{store.name}}</div>
					<div class="dfont body-card-pending"> {{store.address}}</div>
				</div>
				<div class="d-flex flex-column">
					<button type="button" class="btn btn-block"
						[ngClass]="{'btn-primary': store.store_id == selectedStoreId, 'btn-only-border': store.store_id != selectedStoreId}"
						(click)="selectStore(store.store_id)" data-isbusy="false">
						<i class="fa fa-check" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</div>
	</ng-container>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2"
					[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
					(click)="saveStoreSelected()">
					<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
					CONTINUAR
				</button>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">MENSAJE</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					CERRAR
				</button>
			</div>
		</div>
	</div>
</div>
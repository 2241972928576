import { Component, OnInit } from '@angular/core';
// import { EcommerceParametersModel } from '../../../models/ecommerce-parameters';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { ShoppingCartModel } from '../../../models/shopping-cart';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';

@Component({
  selector: 'app-checkout-payment-error',
  templateUrl: './checkout-payment-error.page.html',
  styleUrls: ['./checkout-payment-error.page.scss']
})
export class CheckoutPaymentErrorPageComponent implements OnInit {

  // ecommerce_parameters: EcommerceParametersModel = new EcommerceParametersModel();
  shopping_cart: ShoppingCartModel = new ShoppingCartModel();

  message: string = '';
  constructor(
    private checkoutService: CheckoutService,
    private progressbarService: ProgressBarService
  ) {
    this.progressbarService.setVisibility(false);
    this.shopping_cart = this.checkoutService.getShoppingCart();
  }

  ngOnInit() {
    this.message = this.shopping_cart.payment_method.summary_object.message_response;
  }

}

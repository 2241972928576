<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title }}
	</div>

	<div class="dcard p-0">
		<div class="container-fluid">
			<div class="row" [formGroup]="shippingAddressFormGroup">
				<div class="col">
					<div class="form-group">
						<label for="lblInputShippingAddress_1" class="dfont dfont-bold text-uppercase">Nombre
							completo del receptor *</label>
						<div class="input-group">
							<input id="lblInputShippingAddress_1" type="text" class="form-control"
								formControlName="fullName" [(ngModel)]="shippingAddress.receiver.full_name"
								[ngClass]="{'is-invalid': shippingAddressFormGroup.controls.fullName.invalid && shippingAddressFormGroup.controls.fullName.dirty}"
								placeholder="Ingresar nombre" (keyup.enter)="focusInputField($event)" uppercase>
							<div class="form-control-input" *ngIf="shippingAddress.receiver.full_name != ''"
								(click)="shippingAddress.receiver.full_name = '';focusTarget('#lblInputShippingAddress_1')">
								<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
							</div>
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col">
							<label for="lblInputShippingAddress_2" class="dfont dfont-bold text-uppercase">Doc.
								Identidad *</label>
							<div class="input-group">
								<input id="lblInputShippingAddress_2" type="text" class="form-control"
									formControlName="documentNumber"
									[(ngModel)]="shippingAddress.receiver.document_number"
									[ngClass]="{'is-invalid': shippingAddressFormGroup.controls.documentNumber.invalid && shippingAddressFormGroup.controls.documentNumber.dirty}"
									placeholder="Ingresar DOI" (keyup.enter)="focusInputField($event)" integers>
								<div class="form-control-input" *ngIf="shippingAddress.receiver.document_number != ''"
									(click)="shippingAddress.receiver.document_number = '';focusTarget('#lblInputShippingAddress_1')">
									<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
								</div>
							</div>
						</div>
						<div class="form-group col">
							<label for="lblInputShippingAddress_3" class="dfont dfont-bold text-uppercase">Teléf.
								Contacto</label>
							<div class="input-group">
								<input id="lblInputShippingAddress_3" type="text" class="form-control"
									formControlName="phoneNumber" [(ngModel)]="shippingAddress.receiver.phone_number"
									placeholder="Ingresar teléfono" (keyup.enter)="focusInputField($event)" integers>
								<div class="form-control-input" *ngIf="shippingAddress.receiver.phone_number != ''"
									(click)="shippingAddress.receiver.phone_number = '';focusTarget('#lblInputShippingAddress_3')">
									<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label for="lblInputShippingAddress_4" class="dfont dfont-bold text-uppercase">Dirección
							*</label>
						<div class="input-group">
							<input id="lblInputShippingAddress_4" type="text" class="form-control"
								formControlName="address" [(ngModel)]="shippingAddress.address"
								[ngClass]="{'is-invalid': shippingAddressFormGroup.controls.address.invalid && shippingAddressFormGroup.controls.address.dirty}"
								placeholder="Ingresar dirección" (keyup.enter)="focusInputField($event)" uppercase>
							<div class="form-control-input" *ngIf="shippingAddress.address != ''"
								(click)="shippingAddress.address = '';focusTarget('#lblInputShippingAddress_4')">
								<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label for="lblInputShippingAddress_5" class="dfont dfont-bold text-uppercase">Referencia de
							dirección</label>
						<div class="input-group">
							<input id="lblInputShippingAddress_5" type="text" class="form-control"
								formControlName="addressReference" [(ngModel)]="shippingAddress.references"
								placeholder="Ingresar referencia" (keyup.enter)="focusInputField($event)" uppercase>
							<div class="form-control-input" *ngIf="shippingAddress.references != ''"
								(click)="shippingAddress.references = '';focusTarget('#lblInputShippingAddress_5')">
								<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label for="lblInputShippingAddress_6" class="dfont dfont-bold text-uppercase">Departamento
							*</label>
						<select id="lblInputShippingAddress_6" class="form-control" formControlName="department"
							[(ngModel)]="shippingAddress.department" (change)="onChangeDepartment()"
							[ngClass]="{'is-invalid': shippingAddressFormGroup.controls.department.invalid && shippingAddressFormGroup.controls.department.dirty}">
							<option value="">SELECCIONAR</option>
							<option value="{{item.code}}" *ngFor="let item of regionList">{{item.name}}</option>
						</select>
					</div>
					<div class="form-group">
						<label for="lblInputShippingAddress_7" class="dfont dfont-bold text-uppercase">Provincia
							*</label>
						<select id="lblInputShippingAddress_7" class="form-control" formControlName="province"
							[(ngModel)]="shippingAddress.province" (change)="onChangeProvince()"
							[ngClass]="{'is-invalid': shippingAddressFormGroup.controls.province.invalid && shippingAddressFormGroup.controls.province.dirty}">
							<option value="">SELECCIONAR</option>
							<option value="{{item.code}}" *ngFor="let item of provinceList">{{item.name}}</option>
						</select>
					</div>
					<div class="form-group">
						<label for="lblInputShippingAddress_8" class="dfont dfont-bold text-uppercase">Distrito
							*</label>
						<select id="lblInputShippingAddress_8" class="form-control" formControlName="district"
							[(ngModel)]="shippingAddress.district"
							[ngClass]="{'is-invalid': shippingAddressFormGroup.controls.district.invalid && shippingAddressFormGroup.controls.district.dirty}">
							<option value="">SELECCIONAR</option>
							<option value="{{item.code}}" *ngFor="let item of districtList">{{item.name}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnSaveShippingAddress" type="button" class="btn btn-primary btn-block my-2"
					(click)="validateForm()" [ngClass]="{'btn-disabled': progressButton.process === true}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
					CONTINUAR
				</button>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">MENSAJE</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					CERRAR
				</button>
			</div>
		</div>
	</div>
</div>
<div style="border-top: solid 5px #1E4B74;">
  <div style="margin-top: 100px;">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-8 col-md-6 col-lg-6">
          <ng-container *ngIf="progressBar === true">
            <div class="alert alert-primary text-center" role="alert">
              <!-- <div class="d-flex justify-content-center bd-highlight">
                Conexaris Platform
              </div> -->
              <div class="d-flex justify-content-center bd-highlight">
                <div class="lds-css">
                  <div class="lds-gear" style="width:100%;height:100%">
                    <div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <br/> No Autorizado -->
            </div>
          </ng-container>
          <ng-container *ngIf="progressBar !== true">
            <div class="alert alert-danger text-center" role="alert">
              {{ messageError }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
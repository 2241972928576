import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../../../utils/http-util';
import { environment } from '../../../../environments/environment';
import { EnumType } from '../../../models/enums/enum';
import { Router } from '@angular/router';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  /**
   * Call API Rest for validate Order
   * @param token token
   */
  validateToken(token: string, session_language: string) {
    let tokenEntity = {
      ecommerceToken: token,
      language: session_language
    };
    const body = JSON.stringify(tokenEntity);
    const url = environment.base_url + '/v1.0.0/auth/validate-ecommerce-token';
    return this.http.post(url, body, HttpUtil.getHttpOptions(EnumType.ContentType.JSON)).pipe(
      map(HttpUtil.extractData),
      catchError(HttpUtil.handleErrorObservable)
    );
  }

  /**
   * Call API Rest for validate Order
   * @param token token
   */
  validateTokenWithoutOrder(token: string) {
    let tokenEntity = {
      ecommerceToken: token
    };
    const body = JSON.stringify(tokenEntity);
    const url = environment.base_url + '/v1.0.0/auth/validate-ecommerce-token-without-order';
    return this.http.post(url, body, HttpUtil.getHttpOptions(EnumType.ContentType.JSON)).pipe(
      map(HttpUtil.extractData),
      catchError(HttpUtil.handleErrorObservable)
    );
  }

  redirectLogin() {
    this.router.navigate(['/auth/', '0']);
  }
}

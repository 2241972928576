/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkout-payment-error.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./checkout-payment-error.page";
import * as i3 from "../../../services/checkout/checkout.service";
import * as i4 from "../../../services/progress-bar/progress-bar.service";
var styles_CheckoutPaymentErrorPageComponent = [i0.styles];
var RenderType_CheckoutPaymentErrorPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckoutPaymentErrorPageComponent, data: {} });
export { RenderType_CheckoutPaymentErrorPageComponent as RenderType_CheckoutPaymentErrorPageComponent };
export function View_CheckoutPaymentErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["style", "border-top: solid 5px #1E4B74;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["style", "margin-top: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "alert alert-danger text-center"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 6, 0, currVal_0); }); }
export function View_CheckoutPaymentErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-checkout-payment-error", [], null, null, null, View_CheckoutPaymentErrorPageComponent_0, RenderType_CheckoutPaymentErrorPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.CheckoutPaymentErrorPageComponent, [i3.CheckoutService, i4.ProgressBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckoutPaymentErrorPageComponentNgFactory = i1.ɵccf("app-checkout-payment-error", i2.CheckoutPaymentErrorPageComponent, View_CheckoutPaymentErrorPageComponent_Host_0, {}, {}, []);
export { CheckoutPaymentErrorPageComponentNgFactory as CheckoutPaymentErrorPageComponentNgFactory };

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpUtil } from '../../utils/http-util';
import { environment } from '../../../environments/environment';
import { EnumType } from '../../models/enums/enum';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class IntegrationsVisaService {

  constructor(
    private http: HttpClient
  ) { }

  /**
	 * Call API Rest for get token security of visa net
	 */
  getTokenSecurity() {
    const url = environment.base_url + '/v1.0.0/integrations-visanet/visa-security-token';
    return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
      map(HttpUtil.extractData),
      catchError(HttpUtil.handleErrorObservable)
    );
  }

  /**
	 * Call API Rest for get communication session
   * @param entity entity for create session
	 */
  getCommunicationSession(entity: any) {
    let communication_entity = {
      communication: entity
    }

    const body = JSON.stringify(communication_entity);
    const url = environment.base_url + '/v1.0.0/integrations-visanet/visa-communication-session';
    return this.http.post(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
      map(HttpUtil.extractData),
      catchError(HttpUtil.handleErrorObservable)
    );
  }
}

<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false && ecommerceFieldTemplate.values != undefined">

	<div class="content-title">
		{{ page_title | labelTranslator }}
	</div>

	<ng-container *ngIf="shoppingCart.order_detail">
		<ng-container *ngFor="let item of shoppingCart.order_detail;let i = index">
			<div id="dcard_{{i}}" class="dcard">
				<div class="p-0 d-flex flex-row">
					<div class="d-flex flex-fill flex-column">
						<div class="d-flex flex-fill">
							<div class="w-115p">
								<img [src]="item.image | safeUrl" alt="" class="img-fluid">
							</div>
							<div class="px-2 d-flex flex-column flex-fill">
								<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
									<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
										*ngIf="fieldValue.is_visible==true">
										{{ fieldValue.output[i] }}
									</div>
								</ng-container>
							</div>
						</div>
						<div class="d-flex pt-2">
							<div class="w-115p">
								<button id="btnDeleteProduct_{{i}}" type="button" class="btn btn-block btn-only-border"
									(click)="deleteItem(item, i)" data-isbusy="false">
									<div class="svg-icon-spinner d-none"></div>
									{{ 'REMOVER' | labelTranslator }}
								</button>
							</div>
							<div class="px-2 d-flex flex-column flex-fill">
								<!-- <div class="dfont dfont-bold body-card-complete">
										<button class="btn btn-block btn-only-border" (click)="getSeats()">ASIENTOS</button>
									</div> -->
							</div>
							<ng-container *ngIf="view_metadata">
								<div class="w-115p">
									<button id="btnRedirectMetadata_{{i}}" type="button"
										class="btn btn-block btn-only-border" (click)="redirectFromMetadata(item, i)"
										data-isbusy="false"
										[ngClass]="{'btn-primary': item.metadata_detail === undefined, 'btn-only-border': item.metadata_detail !== undefined}">
										<div class="svg-icon-spinner d-none"></div>
										{{ label_metadata | labelTranslator }}
									</button>
								</div>
							</ng-container>
						</div>
					</div>
					<ng-container *ngIf="modifiy_quantities">
						<div class="dcard-content-option">
							<div class="dcard-content-option-item" (click)="addItems(i)">
								<i class="fa fa-plus" aria-hidden="true"></i>
							</div>
							<div class="dcard-content-option-item px-0">
								<input type="text" class="form-control-plaintext text-center"
									value="{{ item.quantity }}" readonly>
							</div>
							<div class="dcard-content-option-item" (click)="removeItems(i)">
								<i class="fa fa-minus" aria-hidden="true"></i>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="shoppingCart.order_detail !== undefined && shoppingCart.order_detail.length > 0">
		<div class="dcard">
			<div class="container-fluid">
				<div class="row">
					<div class="col-6">
						<div class="text-left">
							<span class="dfont dfont-bold">
								Items:
							</span>
							<span class="dfont">
								{{ productCalc.quantityTotal }}
							</span>
						</div>
					</div>
					<div class="col-6">
						<div class="text-right">
							<span class="dfont dfont-bold">
								Total:
							</span>
							<span class="dfont">
								S/ {{ productCalc.total | number: '1.2-2' : 'es-PE' }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card" style="background-color: transparent; border: 0px;">
			<div class="container-fluid">
				<div class="row">
					<div class="col-6 pl-0 pr-0" style="padding-right:  0.25rem !important;">
						<button id="btnProcess" type="button" class="btn btn-block btn-only-border my-2 my-2"
							data-isbusy="false" (click)="redirectToPreviousStep()"
							[ngClass]="{'btn-disabled': progressButton.back === true}">
							<div class="svg-icon-spinner" *ngIf="progressButton.back === true"></div>
							{{ 'REGRESAR' | labelTranslator }}
						</button>
					</div>
					<div class="col-6 pl-0 pr-0" style="padding-left: 0.25rem !important;">
						<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
							(click)="redirectToNextStep()" [ngClass]="{'btn-disabled': progressButton.process === true}"
							data-isbusy="false">
							<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
							{{ 'CONTINUAR' | labelTranslator }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="shoppingCart.order_detail === undefined || shoppingCart.order_detail.length == 0">
		<div class="dcard animated fadeIn">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12 text-center">
						<span>{{ '¡Tu carrito está vacío!' | labelTranslator }}</span>
					</div>
				</div>
			</div>
		</div>
	</ng-container>


</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError | labelTranslator }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					{{ 'CERRAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="modalBuyer" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'Indicar el Comprador' | labelTranslator }}</div>
			</div>
			<div class="modal-body">
				<ng-container *ngFor="let item of metadata_buyers;let i = index">
					<div class="pb-2 d-flex flex-row col-md-12">
						<div class="d-flex flex-fill flex-column" style="padding-right: 75px;">
							<div class="dfont dfont-bold title-card pb-1">
								{{ item.customer.names + ' ' + item.customer.last_names + ' - ' +
								item.customer.document_number }}
							</div>
						</div>
						<div class="d-flex flex-column">
							<button type="button" class="btn btn-block " data-isbusy="false"
								(click)="setBuyerOfMetadata(i)"
								[ngClass]="{'btn-primary': item.is_select === true, 'btn-only-border': item.is_select !== true}">
								<i class="fa fa-check" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</ng-container>

				<!-- <div class="pb-2 d-flex flex-row col-md-12">
					<div class="d-flex flex-fill flex-column" style="padding-right: 75px;">
						<div class="dfont dfont-bold title-card pb-1">Edgar David Vasques de la cruz 1234567</div>
					</div>
					<div class="d-flex flex-column">
						<button type="button" class="btn btn-block btn-only-border" data-isbusy="false">
							<i class="fa fa-check" aria-hidden="true"></i>
						</button>
					</div>
				</div> -->
			</div>
			<div class="modal-footer mf-center">
				<button id="btnProcess_Customer" type="button" class="btn btn-primary btn-block my-2"
					(click)="saveShoppingCartWithCustomer()"
					[ngClass]="{'btn-disabled': progressButton.customer === true}" data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.customer === true"></div>
					{{ 'CONTINUAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>
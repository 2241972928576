import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShoppingCartModel, ShoppingCartDeliveryTimeModel, DeliveryTimeModel } from '../../../models/shopping-cart';
import * as lodash from 'lodash';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { Router } from '@angular/router';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
declare let $: any;

@Component({
	selector: 'app-checkout-delivery-time',
	templateUrl: './checkout-delivery-time.page.html',
	styleUrls: ['./checkout-delivery-time.page.scss']
})
export class CheckoutDeliveryTimePageComponent implements OnInit, OnDestroy {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	deliveryTimeList: DeliveryTimeModel[] = new Array<DeliveryTimeModel>();
	deliveryTime: ShoppingCartDeliveryTimeModel = new ShoppingCartDeliveryTimeModel();
	dayList: any[] = new Array<any>();
	scheduleList: any[] = new Array<any>();
	deliveryTimeFormGroup: FormGroup;
	progressBar: any = {
		list_page: true
	};
	progressButton: any = {
		process: false
	};
	messageError: string = undefined;
	page_title: string = "";

	constructor(
		private progressbarService: ProgressBarService,
		private checkoutService: CheckoutService,
		private formBuilder: FormBuilder,
		private router: Router,
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.deliveryTime.date = '';
		this.deliveryTime.schedule = '';
		this.shoppingCart = this.checkoutService.getShoppingCart();
		this.deliveryTime = this.shoppingCart.delivery_time;
	}

	ngOnInit() {

		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {

			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.shipping_time;
			}
			this.getDeliveryTimeList();
			this.initForm();
			this.appendModalsToBody();
		}
	}

	getDeliveryTimeList() {
		this.deliveryTimeList = [
			{
				"_id": "5c93f4ec1d8c2e4fe86b4ea0",
				"date": "01-12-2018",
				"schedule": [
					"03:30 pm a 04:00 pm",
					"05:30 pm a 07:00 pm"
				]
			},
			{
				"_id": "5c93f4ec1d8c2e4fe86b4e9f",
				"date": "02-12-2018",
				"schedule": [
					"09:30 pm a 12:00 pm",
					"01:30 pm a 03:00 pm",
					"03:30 pm a 04:00 pm",
					"05:30 pm a 07:00 pm"
				]
			}
		];
		this.dayList = lodash.map(this.deliveryTimeList, 'date');
		this.progressBar.list_page = false;
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.onChangeDay();
	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
	}

	initForm() {
		this.deliveryTimeFormGroup = this.formBuilder.group({
			date: ['', Validators.required],
			schedule: ['', Validators.required]
		});
	}

	onChangeDay() {

		let findDate = lodash.find(this.deliveryTimeList, ['date', this.deliveryTime.date]);
		if (findDate != undefined) {
			this.scheduleList = lodash.get(findDate, 'schedule');
		} else {
			this.scheduleList = [];
			this.deliveryTime.schedule = '';
		}

	}

	validateForm() {
		if (this.deliveryTimeFormGroup.valid) {

			let btn = $('#btnSaveDeliveryTime');
			if (btn.data('isbusy') === false) {
				btn.data('isbusy', true);
				this.progressButton.process = true;
				this.checkoutService.updateDeliveryTime(this.deliveryTime).subscribe(
					(response: any) => {
						if (response.header.status) {
							this.router.navigate(['/checkout/detail']);
						} else {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}
					},
					(error) => {
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = error.description;
						$('#modalMessage').modal('show');
						console.log('error => ', error);
					}
				)
			}

		} else {
			for (const field in this.deliveryTimeFormGroup.controls) {
				this.deliveryTimeFormGroup.get(field).markAsDirty({ onlySelf: true });
			}
		}
	}
}

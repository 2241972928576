<div class="d-flex flex-column h-100">
    <!-- header -->
    <nav class="container-fluid d-flex py-1 site-header">
        <div class="flex-fill" (click)="reloadPage()">
            <img [src]="company.logo | safeUrl" alt="" class="img-fluid img-banner">
        </div>
        <div class="flex-fill">
            <div class="row">
                <div class="col d-flex justify-content-end" style="font-size: 10px">
                    <i class="fa {{ company.icono_phone }} mr-1 pt-1" aria-hidden="true"></i>
                    <span>{{ company.call_center_phone }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col d-flex justify-content-end" style="font-size: 10px">
                    <i class="fa {{ company.icono_mail }} mr-1 pt-1" aria-hidden="true"></i>
                    <span>{{ company.contact_mail }}</span>
                </div>
            </div>
        </div>
    </nav>
    <!-- content -->
    <div class="site-content flex-fill">
        <ng-container *ngIf="progressBar">
            <div class="dcard-loading">
                <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
                <app-template-loading [template]="'card'" [count]="2"></app-template-loading>
            </div>
        </ng-container>
        <div class="container-fluid">
            <div class="row">
                <div class="offset-sm-1 offset-md-2 offset-lg-2 offset-xl-3 col-12 col-sm-9 col-md-7 col-lg-7 col-xl-4"
                    role="main">
                    <ng-container *ngIf="!progressBar">
                        <div class="lable_version">
                            {{version_app}}
                        </div>
                    </ng-container>
                    <router-outlet></router-outlet>


                </div>
            </div>
        </div>
    </div>
    <!-- footer -->
    <!-- <div class="site-footer align-items-end">
        <div class="container-fluid">
            <div class="flex-fill d-flex flex-row flex-row-reverse">
                1.1.1
            </div>

        </div>
    </div> -->

</div>
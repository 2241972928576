//import CryptoJS from 'crypto-js';
import * as CryptoJS from 'crypto-js';

import { environment } from '../../environments/environment';

declare var dcodeIO: any;

var BCrypt = dcodeIO.bcrypt;


export class CryptoUtil {

    static decrypt(keyword: string, nonce: string) {
        const decrypted_password_bytes = CryptoJS.AES.decrypt(keyword, nonce);
        const decrypted_password_string = decrypted_password_bytes.toString(CryptoJS.enc.Utf8);
        return decrypted_password_string;
    }
    /**
      * AES Algorithm to encrypt keyword with nonce
     * @param keyword
     * @param nonce
     */
    static encrypt(keyword: string, nonce: string) {
        const encrypted_password_bytes = CryptoJS.AES.encrypt(keyword, nonce);
        const encrypted_password_string = encrypted_password_bytes.toString();
        return encrypted_password_string;
    }

    static hashit(keyword: string) {
        return BCrypt.hashSync(keyword, environment.bcrypt_salt);
    }

    static getSaltSync() {
        return BCrypt.genSaltSync(10);
    }

    // /**
    //  * Asymmetric encryption using RSA with server_public_key
    //  * @param inStr String to be encrypted
    //  */
    // static encryptRSA(inStr: string) {
    //     let keyObj, encHex, encString;
    //     try {
    //         keyObj = rs.KEYUTIL.getKey(environment.server_public_key);
    //     } 
    //     catch(ex) {
    //         console.log('Encryption could not obtain key ' + ex);
    //     };

    //     if (keyObj instanceof rs.RSAKey && keyObj.isPublic) {
    //         encHex = rs.KJUR.crypto.Cipher.encrypt(inStr, keyObj, 'RSA');
    //         encString = CryptoUtil.HexToString(encHex);
    //     } else {
    //         console.log("Data encryption failed");
    //     }
    //     return encString;
    // }

    // /**
    //  * Asymmetric decryption using RSA with client_private_key
    //  * @param encString String to be decrypted
    //  */
    // static decryptRSA(encString: string) {
    //     let keyObj, encHex, plainStr;
    //     try {
    //         keyObj = rs.KEYUTIL.getKey(environment.encrypted_client_private_key);
    //     } 
    //     catch(ex) {
    //         console.log('Decryption could not obtain key ' + ex);
    //     };
    //     let inHex = CryptoUtil.StringToHex(encString);

    //     if (keyObj instanceof rs.RSAKey && keyObj.isPrivate) {
    //         plainStr = rs.KJUR.crypto.Cipher.decrypt(inHex, keyObj, 'RSA');
    //     } else {
    //         console.log("Data decryption failed");
    //     }
    //     return plainStr;
    // }

    // /**
    //  * Helper to convert hex to string
    //  * @param str
    //  */
    // static HexToString(str) {
    //     var result = [];
    //     while (str.length >= 8) {
    //         result.push(parseInt(str.substring(0, 8), 16));

    //         str = str.substring(8, str.length);
    //     }

    //     return result;
    // }

    // /**
    //  * Helper to convert string to hex
    //  */
    // static StringToHex(arr) {
    //     var result = "";
    //     var z;

    //     for (var i = 0; i < arr.length; i++) {
    //         var str = arr[i].toString(16);

    //         z = 8 - str.length + 1;
    //         str = Array(z).join("0") + str;

    //         result += str;
    //     }

    //     return result;
    // }
}

export class CompanyModel {

    name: string;
    business_name: string;
    address: string;
    icono_phone: string;
    call_center_phone: string;
    contact_mail: string;
    icono_mail: string;
    logo: string;

}
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./template-loading.component";
var styles_TemplateLoadingComponent = [i0.styles];
var RenderType_TemplateLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplateLoadingComponent, data: {} });
export { RenderType_TemplateLoadingComponent as RenderType_TemplateLoadingComponent };
function View_TemplateLoadingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "lg-content-body-item-empty"]], null, null, null, null, null))], null, null); }
function View_TemplateLoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "lg-empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "lg-content-header-empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "lg-content-body-empty"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateLoadingComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.indexArray; _ck(_v, 5, 0, currVal_0); }, null); }
function View_TemplateLoadingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "card-content-empty"]], null, null, null, null, null))], null, null); }
function View_TemplateLoadingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "card-empty"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateLoadingComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.indexArray; _ck(_v, 3, 0, currVal_0); }, null); }
function View_TemplateLoadingComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_TemplateLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateLoadingComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateLoadingComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplateLoadingComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.template; _ck(_v, 1, 0, currVal_0); var currVal_1 = "list"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "card"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_TemplateLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-loading", [], null, null, null, View_TemplateLoadingComponent_0, RenderType_TemplateLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.TemplateLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplateLoadingComponentNgFactory = i1.ɵccf("app-template-loading", i3.TemplateLoadingComponent, View_TemplateLoadingComponent_Host_0, { template: "template", count: "count" }, {}, []);
export { TemplateLoadingComponentNgFactory as TemplateLoadingComponentNgFactory };

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-service',
  templateUrl: './status-service.page.html',
  styleUrls: ['./status-service.page.scss']
})
export class StatusServicePageComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
    // this.statusService.verifyStatusService('Checkout').subscribe(
    //   (response) => {
    //     console.log(response);
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../../utils/http-util';

import { environment } from '../../../environments/environment';
import { EnumType } from '../../models/enums/enum';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class EcommerceFieldTemplateService {

	constructor(
		private http: HttpClient
	) { }

    /**
	 * Call API Rest to get all rules by page
	 */
	getFieldMap(page_name: string) {
		const url = environment.base_url + '/v1.0.0/ecommerce-field/' + page_name;
		return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

}

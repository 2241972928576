<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title | labelTranslator }}
	</div>

	<ng-container *ngFor="let method of paymentMethodsList;let i = index; trackBy: customTrackBy">
		<div class="dcard">
			<div class="d-flex flex-row">
				<div class="d-flex flex-fill flex-column">
					<div class="dfont dfont-bold title-card pb-1 text-uppercase">
						{{ method.description | labelTranslator }}</div>
				</div>
				<div class="d-flex flex-column">
					<button type="button" class="btn btn-block"
						[ngClass]="{'btn-primary': method.payment_id == selectedPaymentMethod.payment_id, 'btn-only-border': method.payment_id != selectedPaymentMethod.payment_id }"
						(click)="selectPaymentMethod(method)" data-isbusy="false">
						<i class="fa fa-check" aria-hidden="true"></i>
					</button>
				</div>
			</div>
			
			<ng-container *ngIf="method.code === 'PMEF' && selectedPaymentMethod.payment_id === method.payment_id">
				<div class="container animated fadeIn">
					<div class="row">
						<div class="form-group col p-0 m-0">
							<label for="lblInputPayment_1" class="dfont dfont-bold">
								{{ '¿Con cúanto vas a pagar?' | labelTranslator }}</label>
							<div class="input-group">
								<input id="lblInputPayment_1" type="text" [(ngModel)]="amount" class="form-control"
									integers>
								<div class="form-control-input" *ngIf="amount != 0"
									(click)="amount = 0;focusTarget('#lblInputPayment_1')">
									<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="method.code === 'PMCC' && selectedPaymentMethod.payment_id === method.payment_id">
				<div class="container animated fadeIn">
					<div class="row">
						<div class="form-group col p-0 m-0">
							<label class="dfont dfont-bold">{{ 'Seleccionar tarjeta' | labelTranslator }}:</label>
							<div>
								<ng-container *ngFor="let item of method.cards;let i = index; trackBy: customTrackBy">
									<img [src]="item.image | safeUrl" class="credit-card" (click)="selectCard(item)"
										[ngClass]="{'card-image-unselect': item.id !== selectedCard.id, 'card-image-select': item.id === selectedCard.id}" />
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="method.code === 'PMIL' && selectedPaymentMethod.payment_id === method.payment_id">
				<div class="container animated fadeIn">
					<div class="row">
						<div class="form-group col p-0 m-0">
							<label class="dfont dfont-bold">{{ 'Seleccionar pago en línea' | labelTranslator }}:</label>
							<div>
								<ng-container *ngFor="let item of method.cards;let i = index; trackBy: customTrackBy">
									<img [src]="item.image | safeUrl" class="credit-card"
										(click)="selectPaymentInLine(item)"
										[ngClass]="{'card-image-unselect': item.id !== selectedCard.id, 'card-image-select': item.id === selectedCard.id}" />
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="method.code === 'PMPE' && selectedPaymentMethod.payment_id === method.payment_id">
				<div class="container animated fadeIn">
					<div class="row">
						<div class="form-group col p-0 m-0">
							<!-- <label class="dfont dfont-bold">{{ 'Seleccionar pago en línea' | labelTranslator }}:</label> -->
							<div>
								<ng-container *ngFor="let item of method.cards;let i = index; trackBy: customTrackBy">
									<img [src]="item.image | safeUrl" class="credit-card card-image-select"/>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="method.code === 'PMDB' && selectedPaymentMethod.payment_id === method.payment_id">
				<div class="container animated fadeIn">
					<div class="row">
						<div class="form-group col p-0 m-0 m-0">
							<label class="dfont dfont-bold">{{ 'Cuentas bancarias' | labelTranslator }}:</label>
							<div>
								<ng-container
									*ngFor="let item of method.bank_account;let i = index; trackBy: customTrackBy">
									<!-- <img [src]="item.image | safeUrl" class="credit-card" (click)="selectCard(item)" [ngClass]="{'card-image-unselect': item.id !== selectedCard.id, 'card-image-select': item.id === selectedCard.id}" /> -->
									<div class="dfont-sm flex-fill body-card-complete text-uppercase">
										{{ item.bank }}: {{ item.number_account }}
										({{ 'Cuenta Corriente' | labelTranslator }} {{ item.money }})</div>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</ng-container>

		</div>
	</ng-container>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2"
					[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
					(click)="validPaymentMethod()">
					<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
					{{ 'CONTINUAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError | labelTranslator }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					{{ 'CERRAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="modalPoliciesPaymentMethod" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'TÉRMINOS Y CONDICIONES' | labelTranslator }}</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending" id="divPolicies"></div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-primary btn-block my-2" (click)="savePaymentMethod()"
					[ngClass]="{'btn-disabled': progressButton.polices === true}" data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.polices === true"></div>
					{{ 'ACEPTAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>
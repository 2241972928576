<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title | labelTranslator }}
	</div>

	<div class="dcard" *ngIf="show_customer_data.value ==='true' ">
		<div class="d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1 text-uppercase">
					{{ 'DATOS DEL COMPRADOR' | labelTranslator }}
				</div>
				<ng-container *ngIf="shoppingCart.customer._id === ''">
					<div class="dfont flex-fill body-card-pending">
						{{ 'Agregar datos personales del comprador' | labelTranslator }}</div>
				</ng-container>
				<ng-container *ngIf="shoppingCart.customer._id !== ''">
					<div class="dfont flex-fill body-card-complete text-uppercase">
						{{shoppingCart.customer.names + ' ' + shoppingCart.customer.last_names }}
					</div>
					<div class="dfont flex-fill body-card-pending">{{shoppingCart.customer.document_number}}</div>
				</ng-container>
				<ng-container *ngIf="!customerDataComplete">
					<div class="dfont flex-fill body-card-pending alert-message mt-2">* Completar los datos requeridos *
					</div>
				</ng-container>
			</div>
			<div class="d-flex flex-column">
				<button id="btnCustomer" type="button" class="btn btn-block btn-primary" (click)="redirectToCustomer()"
					[ngClass]="{'btn-disabled dfont-white': progressButton.customer === true, 'btn-primary': shoppingCart.customer._id === undefined || shoppingCart.customer._id === '' || !customerDataComplete, 'btn-only-border': shoppingCart.customer._id !== undefined && shoppingCart.customer._id != '' && customerDataComplete}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.customer === true"></div>
					<ng-container *ngIf="shoppingCart.customer._id === undefined || shoppingCart.customer._id === ''">
						{{ 'AGREGAR' | labelTranslator }}
					</ng-container>
					<ng-container *ngIf="shoppingCart.customer._id !== undefined && shoppingCart.customer._id !== ''">
						{{ 'CAMBIAR' | labelTranslator }}
					</ng-container>
				</button>
			</div>
		</div>
	</div>

	<ng-container
		*ngIf="allow_shipping.value === 'true' && allow_store_pickup.value === 'true' && allow_delivery.value === 'true'">
		<div class="dcard">
			<div class="container-fluid">
				<div class="row">
					<div class="col p-0">
						<div class="form-group m-0">
							<div class="dfont dfont-bold title-card text-uppercase pb-2">
								{{ 'MÉTODO DE ENTREGA' | labelTranslator }}</div>
							<select id="lblInputShipping1" class="form-control"
								[(ngModel)]="shoppingCart.shipping_method.type">
								<option value=""> SELECCIONAR </option>
								<option value="STORE">Retiro en tienda</option>
								<option value="DISPATCH">Despacho a domicilio</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<!-- card store -->
	<div class="dcard animated fadeIn"
		*ngIf="allow_store_pickup.value === 'true' && shoppingCart.shipping_method.type === 'STORE'">
		<div class="d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1 text-uppercase">
					{{ 'TIENDA DE RECOJO' | labelTranslator }}</div>
				<ng-container *ngIf="shoppingCart.shipping_method.pick_up.store_id === ''">
					<div class="dfont flex-fill body-card-pending">{{ 'Tienda para el recojo' | labelTranslator }}
					</div>
				</ng-container>
				<ng-container *ngIf="shoppingCart.shipping_method.pick_up.store_id !== ''">
					<div class="dfont flex-fill body-card-complete text-uppercase">
						{{ shoppingCart.shipping_method.pick_up.store_name }}</div>
					<div class="dfont flex-fill body-card-pending">
						{{ shoppingCart.shipping_method.pick_up.store_address }}</div>
				</ng-container>
			</div>
			<div class="d-flex flex-column">
				<button id="btnStore" type="button" class="btn btn-block" (click)="redirectToStoreList()"
					[ngClass]="{'btn-disabled dfont-white': progressButton.store === true, 'btn-primary': shoppingCart.shipping_method.pick_up.store_id === '', 'btn-only-border': shoppingCart.shipping_method.pick_up.store_id != ''}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.store === true"></div>
					<ng-container *ngIf="shoppingCart.shipping_method.pick_up.store_id === ''">
						{{ 'AGREGAR' | labelTranslator }}
					</ng-container>
					<ng-container *ngIf="shoppingCart.shipping_method.pick_up.store_id !== ''">
						{{ 'CAMBIAR' | labelTranslator }}
					</ng-container>
				</button>
			</div>
		</div>
	</div>

	<!-- card dispatch -->
	<div class="dcard animated fadeIn"
		*ngIf="allow_delivery.value === 'true' && shoppingCart.shipping_method.type === 'DISPATCH'">
		<div class="d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1 text-uppercase">
					{{ 'DATOS DE ENVÍO' | labelTranslator }}</div>
				<ng-container *ngIf="shoppingCart.shipping_method.shipping_address.address_id === ''">
					<div class="dfont flex-fill body-card-pending"> {{ 'Agregar datos de envío' | labelTranslator }}
					</div>
				</ng-container>
				<ng-container *ngIf="shoppingCart.shipping_method.shipping_address.address_id !== ''">
					<div class="dfont flex-fill body-card-complete text-uppercase">
						{{ shoppingCart.shipping_method.shipping_address.receiver.full_name }}</div>
					<div class="dfont flex-fill body-card-pending">
						{{ shoppingCart.shipping_method.shipping_address.address }}</div>
					<div class="dfont flex-fill body-card-pending">
						{{ shoppingCart.shipping_method.shipping_address.district }}
					</div>
				</ng-container>
			</div>
			<div class="d-flex flex-column">
				<button id="btnShippingAddress" type="button" class="btn btn-block"
					(click)="redirectToDeliveryAddressMain()"
					[ngClass]="{'dfont-white btn-disabled': progressButton.shipping_address === true, 'btn-primary': shoppingCart.shipping_method.shipping_address.address_id === '', 'btn-only-border': shoppingCart.shipping_method.shipping_address.address_id != ''}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.shipping_address === true"></div>
					<ng-container *ngIf="shoppingCart.shipping_method.shipping_address.address_id === ''">
						{{ 'AGREGAR' | labelTranslator }}
					</ng-container>
					<ng-container *ngIf="shoppingCart.shipping_method.shipping_address.address_id !== ''">
						{{ 'CAMBIAR' | labelTranslator }}
					</ng-container>
				</button>
			</div>
		</div>
	</div>

	<!-- card delivery time -->
	<div class="dcard animated fadeIn"
		*ngIf="shoppingCart.shipping_method.type === 'DISPATCH' && allow_delivery_time.value === 'true'">
		<div class="d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1 text-uppercase">
					{{ 'HORARIO DE ENTREGA' | labelTranslator }}</div>
				<ng-container *ngIf="shoppingCart.delivery_time.date === ''">
					<div class="dfont flex-fill body-card-pending">
						{{ 'Agregar la fecha y hora de la entrega del producto' | labelTranslator }}
					</div>
				</ng-container>
				<ng-container *ngIf="shoppingCart.delivery_time.date !== ''">
					<div class="dfont flex-fill body-card-complete text-uppercase">
						{{ shoppingCart.delivery_time.date | date: 'fullDate'}}
					</div>
					<div class="dfont flex-fill body-card-pending">{{ shoppingCart.delivery_time.schedule }}</div>
				</ng-container>
			</div>
			<div class="d-flex flex-column">
				<button id="btnDeliveryTime" type="button" class="btn btn-block"
					[ngClass]="{'dfont-white btn-disabled': progressButton.delivery_time === true, 'btn-primary': shoppingCart.delivery_time.date === '', 'btn-only-border': shoppingCart.delivery_time.date != ''}"
					(click)="redirectToDeliveryTime()" data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.delivery_time === true"></div>
					<ng-container *ngIf="shoppingCart.delivery_time.date === ''"></ng-container>
					<ng-container *ngIf="shoppingCart.delivery_time.date !== ''"> {{ 'CAMBIAR' | labelTranslator }}
					</ng-container>
				</button>
			</div>
		</div>
	</div>

	<!-- card method payment -->
	<div class="dcard" *ngIf="allow_payment_method.value === 'true'">
		<div class="d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1 text-uppercase">{{ 'MEDIO DE PAGO' | labelTranslator }}
				</div>
				<ng-container *ngIf="shoppingCart.payment_method.payment_id === ''">
					<div class="dfont flex-fill body-card-pending">
						{{ 'Seleccionar con que medio se va realizar el pago' | labelTranslator }}
					</div>
				</ng-container>
				<ng-container *ngIf="shoppingCart.payment_method.payment_id !== ''">
					<div class="dfont flex-fill body-card-complete text-uppercase">
						{{ shoppingCart.payment_method.description | labelTranslator }}</div>
				</ng-container>
				<ng-container
					*ngIf="shoppingCart.payment_method.code === 'PMCC' || shoppingCart.payment_method.code === 'PMIL'">
					<div class="dfont flex-fill body-card-pending">{{ shoppingCart.payment_method.card.name }}</div>
				</ng-container>
				<ng-container *ngIf="shoppingCart.payment_method.code === 'PMEF'">
					<div class="dfont flex-fill body-card-pending"> S/
						{{ shoppingCart.payment_method.payment_amount | number: '1.2-2' : 'es-PE'}}
					</div>
				</ng-container>
			</div>
			<div class="d-flex flex-column">
				<button id="btnPaymentMethod" type="button" class="btn btn-block" (click)="redirectToPaymentMethod()"
					[ngClass]="{'dfont-white btn-disabled': progressButton.payment_method === true, 'btn-primary': shoppingCart.payment_method.payment_id === '', 'btn-only-border': shoppingCart.payment_method.payment_id != ''}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.payment_method === true"></div>
					<ng-container *ngIf="shoppingCart.payment_method.payment_id === ''">
						{{ 'AGREGAR' | labelTranslator }}</ng-container>
					<ng-container *ngIf="shoppingCart.payment_method.payment_id !== ''">
						{{ 'CAMBIAR' | labelTranslator }}</ng-container>
				</button>
			</div>
		</div>
	</div>

	<!-- card payment document -->
	<div class="dcard" *ngIf="allow_payment_document.value === 'true'">
		<div class="d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1 text-uppercase">
					{{ 'COMPROBANTE DE PAGO' | labelTranslator }}</div>
				<ng-container *ngIf="shoppingCart.payment_document.type === ''">
					<div class="dfont flex-fill body-card-pending">
						{{ 'Elegir su comprobante de pago' | labelTranslator }}</div>
				</ng-container>
				<ng-container *ngIf="shoppingCart.payment_document.type !== ''">
					<div class="dfont flex-fill body-card-complete text-uppercase">
						{{ shoppingCart.payment_document.type }}</div>
					<ng-container *ngIf="shoppingCart.payment_document.type === 'FACTURA'">
						<div class="dfont flex-fill body-card-pending">{{ shoppingCart.payment_document.company }}
						</div>
						<div class="dfont flex-fill body-card-pending">
							{{ shoppingCart.payment_document.ruc_number }}</div>
					</ng-container>
				</ng-container>
			</div>
			<div class="d-flex flex-column">
				<button id="btnPaymentDocument" type="button" class="btn btn-block"
					(click)="redirectToPaymentDocument()"
					[ngClass]="{'dfont-white btn-disabled': progressButton.payment_document === true, 'btn-primary': shoppingCart.payment_document.type === '', 'btn-only-border': shoppingCart.payment_document.type != ''}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.payment_document === true"></div>
					<ng-container *ngIf="shoppingCart.payment_document.type === ''">
						{{ 'AGREGAR' | labelTranslator }}</ng-container>
					<ng-container *ngIf="shoppingCart.payment_document.type !== ''">
						{{ 'CAMBIAR' | labelTranslator }}</ng-container>
				</button>
			</div>
		</div>
	</div>

	<!-- currency -->
	<ng-container *ngIf="allow_currency.value === 'true'">
		<div class="dcard">
			<div class="container-fluid">
				<div class="row">
					<div class="col p-0">
						<div class="form-group m-0">
							<div class="dfont dfont-bold title-card text-uppercase pb-2">
								{{ 'TIPO DE MONEDA' | labelTranslator }}</div>
							<select id="lblInputCurrency1" class="form-control" [(ngModel)]="shoppingCart.currency.code"
								(ngModelChange)="updateCurrency()">
								<option *ngFor="let currency of currencyList" [value]="currency.code">
									{{ currency.description }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<!-- detail shopping cart -->
	<div class="dcard">
		<div class="pb-2 d-flex flex-fill flex-row">
			<div class="flex-fill d-flex">
				<div class="dfont dfont-bold title-card pb-1 text-uppercase">
					{{ 'DETALLES' | labelTranslator }}:&nbsp;</div>
				<div class="dfont body-card-pending">{{ quantityProducts }} {{ 'producto(s)' | labelTranslator }}
				</div>
			</div>
			<div>
				<button id="btnCart" type="button" class="btn btn-only-border" (click)="redirectToCart()"
					[ngClass]="{'dfont-white btn-disabled': progressButton.cart === true}" data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.cart === true"></div>
					{{ 'CAMBIAR' | labelTranslator }}
				</button>
			</div>
		</div>

		<ng-container *ngFor="let item of shoppingCart.order_detail;let i = index">
			<div class="pb-2 d-flex flex-row">
				<div class="d-flex flex-fill col-9 col-sm-9 col-md-10 col-lg-10 p-0">
					<div class="d-flex flex-fill flex-column">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values;let indexValue = index">
							<ng-container *ngIf="fieldValue.group_order == 1">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[i] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
				<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values;let indexValue = index">
					<ng-container *ngIf="fieldValue.group_order == 2">
						<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
							*ngIf="fieldValue.is_visible==true">
							{{ fieldValue.output[i] }}
						</div>
					</ng-container>
				</ng-container>
			</div>
		</ng-container>

		<hr class="my-0">
		<div class="py-2 d-flex flex-row">
			<div class="d-flex flex-fill col-9 col-sm-9 col-md-10 col-lg-10 p-0">
				<div class="d-flex flex-fill flex-column">
					<ng-container *ngIf="affected_IGV">
						<div class="dfont body-card-complete">{{ 'Subtotal' | labelTranslator }}</div>
						<div class="dfont body-card-complete">{{ 'IGV' | labelTranslator }}</div>
					</ng-container>
					<ng-container
						*ngIf="allow_delivery.value === 'true' && shoppingCart.shipping_method.type === 'DISPATCH'">
						<div class="dfont body-card-complete">{{ 'Costo de envío' | labelTranslator }}</div>
					</ng-container>
					<div class="dfont body-card-complete">&nbsp;</div>
					<div class="dfont dfont-bold body-card-complete">{{ 'Total a pagar' | labelTranslator }}</div>
				</div>
			</div>
			<div class="col-3 col-sm-3 col-md-2 col-lg-2 p-0 text-right">
				<div class="d-flex flex-fill flex-column">
					<ng-container *ngIf="affected_IGV">
						<div class="dfont body-card-complete">S/
							{{ shoppingCart.sub_total | number: '1.2-2' : 'en-PE' }}</div>
						<div class="dfont body-card-complete">S/
							{{ shoppingCart.igv_amount | number: '1.2-2' : 'en-PE' }}</div>
					</ng-container>
					<ng-container
						*ngIf="allow_delivery.value === 'true' && shoppingCart.shipping_method.type === 'DISPATCH'">
						<div class="dfont body-card-complete">S/
							{{ shoppingCart.shipping_amount | number: '1.2-2' : 'en-PE' }}</div>
					</ng-container>
					<div class="dfont body-card-complete">&nbsp;</div>
					<div class="dfont dfont-bold body-card-complete dfont-bold">S/
						{{ shoppingCart.total_amount | number: '1.2-2' : 'en-PE' }}
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="allow_policies.value === 'true'">
			<div class="d-flex flex-row">
				<div class="d-flex flex-fill flex-column">
					<div class="d-flex">
						<div class="d-flex flex-fill flex-column">
							<div class="custom-control custom-checkbox d-flex">
								<input id="chkAcceptPolicies" class="custom-control-input" type="checkbox"
									[(ngModel)]="shoppingCart.accepted_policies">
								<label for="chkAcceptPolicies" class="custom-control-label">
									{{ 'Acepto la' | labelTranslator }}
								</label>
								<label class="ml-1">
									<a class="text-warning hyperlink clickeable" (click)="showPolices()">{{ policies |
										labelTranslator }}: (*)
									</a>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
					[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
					(click)="processPayment()">
					<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
					{{ 'FINALIZAR' | labelTranslator }}
				</button>
				<ng-container *ngIf='payment_in_line && payment_with_visa'>
					<form id='frmVisa' style="display: none;" method='post'>
					</form>
				</ng-container>
				<ng-container *ngIf='payment_in_line && payment_with_MC'>
					<div id='divMasterCard' style="display: none;">
					</div>
				</ng-container>
			</div>
			<!-- <div class="col p-0">
				<ng-container *ngIf="!payment_in_line">
					<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
						[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
						(click)="processOrder()">
						<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
						{{ 'FINALIZAR' | labelTranslator }}
					</button>
				</ng-container>
				<ng-container *ngIf='payment_in_line'>
					<ng-container *ngIf='payment_with_visa'>
						<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
							[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
							(click)="processPaymentVisa()">
							<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
							{{ 'FINALIZAR' | labelTranslator }}
						</button>
						<form id='frmVisa' style="display: none;" method='post'>
						</form>
					</ng-container>
					<ng-container *ngIf='payment_with_MC'>
						<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
							[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
							(click)="processPaymentMasterCard()">
							<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
							{{ 'FINALIZAR' | labelTranslator }}
						</button>
						<div id='divMasterCard' style="display: none;">
						</div>
					</ng-container>
					<ng-container *ngIf='payment_pago_efectivo'>
						<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
							[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
							(click)="processPaymentMasterCard()">
							<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
							{{ 'FINALIZAR' | labelTranslator }}
						</button>
						<div id='divMasterCard' style="display: none;">
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf='payment_pago_efectivo'>
					<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
						[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
						(click)="processPaymentMasterCard()">
						<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
						{{ 'FINALIZAR' | labelTranslator }}
					</button>
					<div id='divMasterCard' style="display: none;">
					</div>
				</ng-container>
			</div> -->
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError | labelTranslator }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					{{ 'CERRAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="modalPolicies" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'TÉRMINOS Y CONDICIONES' | labelTranslator }}</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending" id="divPolicies"></div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-primary btn-block my-2" data-dismiss="modal" aria-label="Close">
					{{ 'CERRAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>
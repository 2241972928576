<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title | labelTranslator }}
	</div>

	<div class="dcard p-0">

		<div class="container-fluid">
			<div class="row">
				<div class="col">
					<ng-container *ngFor="let item of fields;let i = index">
						<ng-container *ngIf="item.is_visible">
							<div class="form-group">
								<label for="{{'lInputInfo_' + i}}"
									class="dfont dfont-bold text-uppercase">{{ item.text_label | labelTranslator }}
									<span *ngIf="item.required">*</span>
								</label>
								<div class="input-group">
									<ng-container *ngIf="item.type_data === 'numeric'">
										<input id="{{'lblInputInfo_' + i}}" [(ngModel)]="fields[i].value" type="number"
											class="form-control" placeholder="{{ item.placeholder | labelTranslator }}"
											(keyup.enter)="executeEvent(item,i)" (blur)="executeEvent(item,i)" integers>
										<div class="form-control-input" *ngIf="fields[i].value != ''"
											(click)="fields[i].value = '';focusTarget('#lblInputInfo_' + i)">
											<i class="fa fa-lg fa-times-circle  fa-icon-input" aria-hidden="true"></i>
										</div>
									</ng-container>
									<ng-container *ngIf="item.type_data === 'telephone'">
										<input id="{{'lblInputInfo_' + i}}" [(ngModel)]="fields[i].value" type="tel"
											class="form-control" placeholder="{{ item.placeholder | labelTranslator }}"
											(keyup.enter)="executeEvent(item,i)" (blur)="executeEvent(item,i)" integers>
										<div class="form-control-input" *ngIf="fields[i].value != ''"
											(click)="fields[i].value = '';focusTarget('#lblInputInfo_' + i)">
											<i class="fa fa-lg fa-times-circle  fa-icon-input" aria-hidden="true"></i>
										</div>
									</ng-container>
									<ng-container *ngIf="item.uppercase">
										<ng-container *ngIf="item.type_data === 'text'">
											<input id="{{'lblInputInfo_' + i}}" [(ngModel)]="fields[i].value"
												(keyup.enter)="executeEvent(item,i)" (blur)="executeEvent(item,i)" type="text" class="form-control"
												placeholder="{{ item.placeholder | labelTranslator }}" uppercase>
											<div class="form-control-input" *ngIf="fields[i].value != ''"
												(click)="fields[i].value = '';focusTarget('#lblInputInfo_' + i)">
												<i class="fa fa-lg fa-times-circle  fa-icon-input"
													aria-hidden="true"></i>
											</div>
										</ng-container>
										<!-- <ng-container *ngIf="item.type_data === 'numeric'">
											<input id="{{'lblInputInfo_' + i}}" [(ngModel)]="fields[i].value"
												type="number" class="form-control"
												placeholder="{{ item.placeholder | labelTranslator }}"
												(keyup.enter)="executeEvent(item,i)" integers>
											<div class="form-control-input" *ngIf="fields[i].value != ''"
												(click)="fields[i].value = '';focusTarget('#lblInputInfo_' + i)">
												<i class="fa fa-lg fa-times-circle  fa-icon-input"
													aria-hidden="true"></i>
											</div>
										</ng-container> -->
										<ng-container *ngIf="item.type_data === 'dropdown'">
											<select id="{{'lblInputInfo_' + i}}" [(ngModel)]="fields[i].value"
												class="form-control" (change)="executeEvent(item,i)">
												<option *ngFor="let option of item.selectable_values"
													value="{{option.value}}">{{ option.text | uppercase }}
												</option>
											</select>
										</ng-container>
										<ng-container *ngIf="item.type_data === 'date'">
											<input type="text" id="{{'lblInputInfo_' + i}}"
												class="form-control form-control-sm" [(ngModel)]="fields[i].value_date"
												placeholder="{{ item.placeholder | labelTranslator }}"
												[outsideClick]="true"
												[bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false }"
												bsDatepicker readonly>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="!item.uppercase">
										<ng-container *ngIf="item.type_data === 'text'">
											<input id="{{'lblInputInfo_' + i}}" [(ngModel)]="fields[i].value"
												(keyup.enter)="executeEvent(item,i)" (blur)="executeEvent(item,i)" type="text" lowercase
												class="form-control"
												placeholder="{{ item.placeholder | labelTranslator }}">
											<div class="form-control-input" *ngIf="fields[i].value != ''"
												(click)="fields[i].value = '';focusTarget('#lblInputInfo_' + i)">
												<i class="fa fa-lg fa-times-circle  fa-icon-input"
													aria-hidden="true"></i>
											</div>
										</ng-container>
										<ng-container *ngIf="item.type_data === 'dropdown'">
											<select id="{{'lblInputInfo_' + i}}" [(ngModel)]="fields[i].value"
												class="form-control" (change)="executeEvent(item,i)">
												<option *ngFor="let option of item.selectable_values"
													value="{{option.value}}">{{ option.text | lowercase }}
												</option>
											</select>
										</ng-container>
										<ng-container *ngIf="item.type_data === 'date'">
											<input type="text" id="{{'lblInputInfo_' + i}}"
												class="form-control form-control-sm" [(ngModel)]="fields[i].value_date"
												placeholder="{{ item.placeholder | labelTranslator }}"
												[outsideClick]="true"
												[bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false }"
												bsDatepicker readonly>
										</ng-container>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnSaveMetadata" type="button" class="btn btn-primary btn-block my-2"
					(click)="saveMetadata()" [ngClass]="{'btn-disabled': progressButton.process === true}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
					{{ 'CONTINUAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError | labelTranslator }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					{{ 'CERRAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCartModel, ShippingAddressModel } from '../../../../models/shopping-cart';
import { CheckoutService } from '../../../../services/checkout/checkout.service';
import { v4 as uuid } from 'uuid';
import * as lodash from 'lodash';
import { EcommerceRulesService } from '../../../../services/ecommerce-rules/ecommerce-rules.service';
import { EnumType } from '../../../../models/enums/enum';
import { EcommerceRulesModel } from '../../../../models/ecommerce-rules';
import { ProgressBarService } from '../../../../services/progress-bar/progress-bar.service';
declare let $: any;

@Component({
	selector: 'app-checkout-shipping',
	templateUrl: './checkout-shipping.page.html',
	styleUrls: ['./checkout-shipping.page.scss']
})
export class CheckoutShippingPageComponent implements OnInit, OnDestroy {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	shippingAddressList: ShippingAddressModel[] = new Array<ShippingAddressModel>();
	selectedShippingAddress: ShippingAddressModel = new ShippingAddressModel();

	find_address_shipping: EcommerceRulesModel = new EcommerceRulesModel();

	progressButton: any = {
		redirect: false,
		add: false,
		edit: false,
		delete: false
	};
	progressBar: any = {
		list_page: true
	};
	messageError: string = undefined;

	page_title: string = "";

	constructor(
		private checkoutService: CheckoutService,
		private router: Router,
		private ecommerceRulesService: EcommerceRulesService,
		private progressbarService: ProgressBarService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
		this.shippingAddressList = this.checkoutService.getShippingAddressList();
		this.selectedShippingAddress = this.shoppingCart.shipping_method.shipping_address;
	}

	ngOnInit() {
		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {

			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.shipping_address;
				this.getRules();
			}
			this.appendModalsToBody();
			this.progressBar.list_page = false;
			this.progressbarService.setVisibility(this.progressBar.list_page);
		}
	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	getRules() {
		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.SHIPPING_ADDRESS_LIST).subscribe(
			(response: any) => {
				console.log(response);
				if (response.header.status) {
					let address_shipping: any = lodash.find(response.body, { name: EnumType.EcommerceRules.FIND_ADDRESS_SHIPPING });

					if (address_shipping) {
						if (address_shipping.value == 'API') {
							let spec: any = lodash.find(address_shipping.specs, { value: address_shipping.value });

							let entity = {};

							address_shipping.adapter_fields.forEach(field => {
								entity[field] = this.shoppingCart.customer[field];
							});

							this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
								(response: any) => {
									if (response.header.status) {
										this.shippingAddressList = response.body;
									}
								},
								(err) => {
									console.log(err);
								}
							);
						}
					} else {

					}

				} else {

				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
	}

	editShippingAddress(index?: number, address_id?: number) {
		if (address_id) {
			let btn = $("#btnEditAddress_" + index);
			if (btn.data('isbusy') === false) {
				btn.data('isbusy', true);
				$('#btnEditAddress_' + index + ' > .svg-icon-spinner-black').removeClass('d-none');
				this.router.navigate(['checkout/shipping/address', address_id]);
			}
		} else {
			let btn = $("#btnAddAddress");
			if (btn.data('isbusy') === false) {
				btn.data('isbusy', true);
				this.progressButton.add = true;
				this.router.navigate(['checkout/shipping/address', uuid()]);
			}
		}
	}

	deleteShippingAddress(index: number, address_id: string) {
		console.log('address_id => ', address_id);
		let btn = $('#btnDeleteAddress_' + index);
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			$('#btnDeleteAddress_' + index + ' > .svg-icon-spinner-black').removeClass('d-none');
			this.checkoutService.deleteShippingAddress(this.shoppingCart.customer.customer_id, address_id).subscribe(
				(response: any) => {
					if (response.header.status) {
						btn.data('isbusy', false);
						$('#btnDeleteAddress_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
						$('#dcard_' + index).addClass('animated fadeOut');
						setTimeout(() => {
							if (this.selectedShippingAddress.address_id == address_id) {
								this.selectedShippingAddress = new ShippingAddressModel();
							}
							lodash.remove(this.shippingAddressList, ['address_id', address_id]);
						}, 500);
					} else {
						btn.data('isbusy', false);
						$('#btnDeleteAddress_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
						this.messageError = response.header.message_description;
						$('#modalMessage').modal('show');
					}
				},
				(error) => {
					$('#btnDeleteAddress_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
					btn.data('isbusy', false);
					this.messageError = error.description;
					$('#modalMessage').modal('show');
					console.log('error => ', error);
				}
			);
		}
	}
	selectShippingAddress(item: ShippingAddressModel) {
		this.selectedShippingAddress = item;
	}

	redirectToCheckoutDetail() {

		let btn = $('#btnRedirectCheckout');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			this.progressButton.redirect = true;
			if (this.shippingAddressList.length == 0) {
				this.router.navigate(['/checkout/detail']);
			} else if (this.selectedShippingAddress && this.selectedShippingAddress.address_id && this.selectedShippingAddress.address_id != '') {
				this.checkoutService.updateShippingAddress(this.shoppingCart.customer.customer_id, this.selectedShippingAddress).subscribe(
					(response: any) => {
						if (response.header.status) {
							this.router.navigate(['/checkout/detail']);
						} else {
							this.progressButton.redirect = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}
					},
					(err) => {
						this.progressButton.redirect = false;
						btn.data('isbusy', false);
						this.messageError = err.description;
						$('#modalMessage').modal('show');
						console.log('error => ', err);
					}
				);
			} else {
				this.progressButton.redirect = false;
				btn.data('isbusy', false);
				this.messageError = 'Por favor seleccionar una dirección de envío';
				$('#modalMessage').modal('show');
			}

		}
	}

}

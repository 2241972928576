import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { CheckoutService } from '../../../services/checkout/checkout.service';
import { ShoppingCartModel, CurrencyModel, CustomerModel } from '../../../models/shopping-cart';
import { EnumType } from '../../../models/enums/enum';
import { environment } from '../../../../environments/environment';
import { EcommerceRulesModel } from '../../../models/ecommerce-rules';
import { EcommerceRulesService } from '../../../services/ecommerce-rules/ecommerce-rules.service';
import { IntegrationsVisaService } from '../../../services/integrations-visa/integrations-visa.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StorageUtil } from '../../../utils/storage-util';

import * as lodash from 'lodash';
import { IntegrationsMasterCardService } from '../../../services/integrations-master-card/integrations-master-card.service';
import { IntegrationsPagoEfectivoService } from '../../../services/integrations-pago-efectivo/integrations-pago-efectivo.service';
// import { setTimeout } from 'timers';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
import { EcommerceParametersModel } from '../../../models/ecommerce-parameters';
import { EcommerceFieldTemplateService } from '../../../services/ecommerce-field-template/ecommerce-field-template.service';
import { DateUtil } from '../../../utils/date-util';
import { EcommerceFieldTemplateModel } from '../../../models/ecommerce-field-template';
declare let $: any;

@Component({
	selector: 'app-checkout-detail',
	templateUrl: './checkout-detail.page.html',
	styleUrls: ['./checkout-detail.page.scss']
})

export class CheckoutDetailPageComponent implements OnInit, OnDestroy {

	session_language: string;
	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	ecommerce_parameters: EcommerceParametersModel = new EcommerceParametersModel();
	ecommerceFieldTemplate: EcommerceFieldTemplateModel = new EcommerceFieldTemplateModel();
	currencyList: CurrencyModel[] = new Array<CurrencyModel>();
	policies: string = 'Política de uso de datos personales';
	total: number = 0;
	quantityProducts: number = 0;
	progressBar: any = {
		list_page: true
	};
	progressButton: any = {
		customer: false,
		store: false,
		shipping: false,
		delivery_time: false,
		payment_method: false,
		payment_document: false,
		cart: false,
		process: false
	};
	messageError: string = undefined;
	page_title: string = "";

	tokenEcommerce: string = '';
	vnToken: string = '';

	show_customer_data: EcommerceRulesModel = new EcommerceRulesModel();
	allow_shipping: EcommerceRulesModel = new EcommerceRulesModel();
	allow_store_pickup: EcommerceRulesModel = new EcommerceRulesModel();
	allow_delivery: EcommerceRulesModel = new EcommerceRulesModel();
	allow_delivery_time: EcommerceRulesModel = new EcommerceRulesModel();
	allow_payment_method: EcommerceRulesModel = new EcommerceRulesModel();
	allow_payment_document: EcommerceRulesModel = new EcommerceRulesModel();
	allow_policies: EcommerceRulesModel = new EcommerceRulesModel();
	allow_currency: EcommerceRulesModel = new EcommerceRulesModel();
	requires_policies: EcommerceRulesModel = new EcommerceRulesModel();

	payment_in_line: boolean = false;
	payment_with_visa: boolean = false;
	payment_with_MC: boolean = false;
	payment_pago_efectivo: boolean = false;
	affected_IGV: boolean = true;

	customerDataComplete = false;
	// validShoppingCart: boolean = false;

	constructor(
		private router: Router,
		private checkoutService: CheckoutService,
		private integrationVisaService: IntegrationsVisaService,
		private spinnerService: Ng4LoadingSpinnerService,
		private integrationsPagoEfectivoService: IntegrationsPagoEfectivoService,
		private ecommerceRulesService: EcommerceRulesService,
		private integrationsMCService: IntegrationsMasterCardService,
		private progressbarService: ProgressBarService,
		private ecommerceFieldService: EcommerceFieldTemplateService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
		this.session_language = this.checkoutService.getlanguage();
		this.ecommerce_parameters = this.checkoutService.getEcommerceParameters();
	}

	ngOnInit() {
		this.getFieldMap();
		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {
			this.shoppingCart.order_detail.forEach(detail => {
				this.quantityProducts += detail.quantity;
			});

			let authSession = StorageUtil.get(EnumType.LocalStorage.AUTH, environment.storage_encrypt_flag);
			this.vnToken = StorageUtil.get(EnumType.LocalStorage.VISANET, environment.storage_encrypt_flag);

			this.tokenEcommerce = authSession.token;

			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.detail;
				this.appendModalsToBody();
				this.getRules();
				let policieIndex = lodash.findIndex(this.checkoutService.getEcommerceParameters().terms_and_conditions, { language_code: this.session_language })

				if (policieIndex != -1)
					$('#divPolicies').append(this.checkoutService.getEcommerceParameters().terms_and_conditions[policieIndex].terms);
			}

		}


	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	getRules() {

		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.SHOPPING_CART).subscribe(
			(response: any) => {
				if (response.header.status) {
					let rule_IGV: any = lodash.find(response.body, { name: EnumType.EcommerceRules.AFFECTED_IGV_ORDER });
					let rule_sku: any = lodash.find(response.body, { name: EnumType.EcommerceRules.SHOW_SKU_PRODUCT });
					if (rule_IGV && rule_sku) {
						this.affected_IGV = (rule_IGV.value === "true");
						// this.view_sku_product = (rule_sku.value === "true");
					}
				} else {
					this.messageError = 'Ocurrio un error en el sistema';
					console.log("rules not configured")
					$('#modalMessage').modal('show');
				}
			},
			(err) => {
				console.log(err);
			}
		);

		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.SHOPPING_CART_DETAIL).subscribe(
			(response: any) => {
				// console.log(response);
				if (response.header.status) {
					let customer_data: any = lodash.find(response.body, { name: EnumType.EcommerceRules.SHOW_CUSTOMER_DATA });
					let shipping: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_SHIPPING });
					let store_pickup: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_STORE_PICKUP });
					let delivery: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_DELIVERY });
					let delivery_time: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_DELIVERY_TIME });
					let payment_method: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_PAYMENT_METHOD });
					let payment_document: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_PAYMENT_DOCUMENT });
					let allow_policies: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_POLICIES });
					let requires_policies: any = lodash.find(response.body, { name: EnumType.EcommerceRules.REQUIRES_POLICIES });
					let allow_currency: any = lodash.find(response.body, { name: EnumType.EcommerceRules.ALLOW_CURRENCY });

					if (customer_data && shipping && store_pickup && store_pickup && delivery && delivery_time && payment_method && payment_document && allow_policies && requires_policies && allow_currency) {
						this.show_customer_data = customer_data;
						this.allow_shipping = shipping;
						this.allow_store_pickup = store_pickup;
						this.allow_delivery = delivery;
						this.allow_delivery_time = delivery_time;
						this.allow_payment_method = payment_method;
						this.allow_payment_document = payment_document;
						this.allow_policies = allow_policies;
						this.requires_policies = requires_policies;
						this.allow_currency = allow_currency;

						if (this.shoppingCart.shipping_method.type == '') {
							if (this.allow_shipping.value == 'true' && this.allow_delivery.value != 'true' && this.allow_store_pickup.value == 'true') this.shoppingCart.shipping_method.type = 'STORE';
							if (this.allow_shipping.value == 'true' && this.allow_delivery.value == 'true' && this.allow_store_pickup.value != 'true') this.shoppingCart.shipping_method.type = 'DISPATCH'
						}

						// Get currency list
						this.currencyList = this.checkoutService.getEcommerceParameters().currencies;
						if (this.allow_currency.value == 'false') {
							this.shoppingCart.currency = this.currencyList[0];
							this.updateCurrency();
						}

						if (this.shoppingCart.payment_method && this.shoppingCart.payment_method.code == 'PMIL') {
							this.payment_in_line = true;
							// payment with visa
							if (this.shoppingCart.payment_method.card && this.shoppingCart.payment_method.card.name == 'Visa') {
								this.payment_with_visa = true;
								this.payment_with_MC = false;
							}

							// payment with master card
							if (this.shoppingCart.payment_method.card &&
								(this.shoppingCart.payment_method.card.name == 'Mastercard' ||
									this.shoppingCart.payment_method.card.name == 'Dinners Club' ||
									this.shoppingCart.payment_method.card.name == 'American Express')
							) {
								this.payment_with_visa = false;
								this.payment_with_MC = true;
							}
						} else if (this.shoppingCart.payment_method.code == 'PMPE') {
							this.payment_pago_efectivo = true;
						} else {
							this.payment_in_line = false;
							this.payment_with_visa = false;
							this.payment_with_MC = false;
						}

						this.customerDataComplete = this.validCustomerData(this.shoppingCart.customer);

						this.progressBar.list_page = false;
						this.progressbarService.setVisibility(this.progressBar.list_page);
					} else {

					}
				} else {

				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	getFieldMap() {
		this.ecommerceFieldService.getFieldMap(EnumType.Pages.SHOPPING_CART_DETAIL).subscribe(
			(response: any) => {
				this.ecommerceFieldTemplate = response.body;
			},
			(error) => {
				console.log('error => ', error);
			}
		);
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
		$('#modalPolicies').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
		$('body > #modalPolicies').remove();
	}

	updateCurrency() {
		this.spinnerService.show();
		let index = lodash.findIndex(this.currencyList, { 'code': this.shoppingCart.currency.code });
		let currency = this.currencyList[index];
		this.checkoutService.updateCurrency(currency).subscribe(
			(response: any) => {
				this.spinnerService.hide();
			},
			(error) => {
				this.spinnerService.hide();
			}
		);
	}

	processOrder() {

		let btn = $('#btnProcess');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			this.progressButton.process = true;

			if (this.validateModel()) {

				this.checkoutService.updateShoppingCartStatus(this.shoppingCart).subscribe(
					(response: any) => {
						// btn.data('isbusy', false);
						// this.progressButton.process = false;
						if (response.header.status) {
							this.router.navigate(['checkout/confirm']);
							$('#modalPaymentInLine').modal('hide');
						} else {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}
					},
					(err) => {
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = err.description;
						$('#modalMessage').modal('show');
						console.log('error => ', err);
					}
				);
			} else {
				$('#modalMessage').modal('show');
				this.progressButton.process = false;
				btn.data('isbusy', false);
			}
		}


	}

	redirectToCustomer() {
		let btn = $('#btnCustomer');
		if (btn.data('isbusy') === false) {
			this.progressButton.customer = true;
			btn.data('isbusy', true);
			this.router.navigate(['checkout/customer']);
		}
	}

	redirectToDeliveryAddressMain() {
		let btn = $('#btnShippingAddress');
		if (btn.data('isbusy') === false) {
			this.progressButton.shipping_address = true;
			btn.data('isbusy', true);
			this.router.navigate(['checkout/shipping']);
		}
	}

	redirectToPaymentDocument() {
		let btn = $('#btnPaymentDocument');
		if (btn.data('isbusy') === false) {
			this.progressButton.payment_document = true;
			btn.data('isbusy', true);
			this.router.navigate(['checkout/payment-document']);
		}
	}

	redirectToStoreList() {
		let btn = $('#btnStore');
		if (btn.data('isbusy') === false) {
			this.progressButton.store = true;
			btn.data('isbusy', true);
			this.router.navigate(['checkout/shipping/store']);
		}
	}

	redirectToDeliveryTime() {
		let btn = $('#btnDeliveryTime');
		if (btn.data('isbusy') === false) {
			this.progressButton.delivery_time = true;
			btn.data('isbusy', true);
			this.router.navigate(['checkout/delivery-time']);
		}
	}

	redirectToPaymentMethod() {
		let btn = $('#btnPaymentMethod');
		if (btn.data('isbusy') === false) {
			this.progressButton.payment_method = true;
			btn.data('isbusy', true);
			this.router.navigate(['checkout/payment-method']);
		}
	}

	redirectToCart() {
		let btn = $('#btnCart');
		if (btn.data('isbusy') === false) {
			this.progressButton.cart = true;
			btn.data('isbusy', true);
			this.router.navigate(['checkout/cart']);
		}
	}

	validateModel() {
		this.messageError = undefined;
		let valid = true;
		if (this.shoppingCart.customer._id == '') valid = false;

		if (this.allow_shipping.value == 'true') {
			if (this.shoppingCart.shipping_method.type == '') {
				valid = false;
			} else {
				if (this.shoppingCart.shipping_method.type == EnumType.ShippingMethodType.STORE) {
					if (this.shoppingCart.shipping_method.pick_up.store_id == '') valid = false;
				} else {
					if (this.shoppingCart.shipping_method.shipping_address.address_id == '') valid = false;
					else {
						if (this.allow_delivery_time.value == 'true') {
							if (this.shoppingCart.delivery_time.date == '') valid = false;
						} else {
							this.shoppingCart.shipping_method.shipping_address.shipping_time = '';
						}
					}
				}
			}
		} else {
			this.shoppingCart.shipping_method.type = '';

			this.shoppingCart.shipping_method.pick_up.store_id = '';
			this.shoppingCart.shipping_method.pick_up.store_name = '';
			this.shoppingCart.shipping_method.pick_up.store_address = '';

			this.shoppingCart.shipping_method.shipping_address._id = '';
			this.shoppingCart.shipping_method.shipping_address.address = '';
			this.shoppingCart.shipping_method.shipping_address.address_id = '';
			this.shoppingCart.shipping_method.shipping_address.department = '';
			this.shoppingCart.shipping_method.shipping_address.district = '';
			this.shoppingCart.shipping_method.shipping_address.province = '';
			this.shoppingCart.shipping_method.shipping_address.references = '';
			this.shoppingCart.shipping_method.shipping_address.shipping_time = '';
			this.shoppingCart.shipping_method.shipping_address.receiver.document_number = '';
			this.shoppingCart.shipping_method.shipping_address.receiver.full_name = '';
			this.shoppingCart.shipping_method.shipping_address.receiver.phone_number = '';
		}

		if (this.allow_payment_method.value == 'true' && this.shoppingCart.payment_method.payment_id == '') valid = false;

		if (this.allow_payment_document.value == 'true' && this.shoppingCart.payment_document.type == '') {
			valid = false
		} else {
			if (this.shoppingCart.payment_document.type == EnumType.PaymentDocumentType.FACTURA) {
				if (this.shoppingCart.payment_document.company == '') valid = false;
				if (this.shoppingCart.payment_document.ruc_number == '') valid = false;
				if (this.shoppingCart.payment_document.address == '') valid = false;
			}
		}

		let clean_detail = false;
		console.log(this.ecommerce_parameters.session.timeout_detail_limit);
		for (let i = 0; i < this.shoppingCart.order_detail.length; i++) {

			if (!DateUtil.compareDatesByMinuts(this.shoppingCart.order_detail[i].added_timestamp, this.ecommerce_parameters.session.timeout_detail_limit)) {
				clean_detail = true;
			}
		}

		if (clean_detail) {
			this.router.navigate(['/checkout/seat-assignment'], { queryParams: { s: 'err' } });
		}

		if (!valid) {
			this.messageError = "Por favor completar los datos solicitados";
			return false;
		} else {
			if (this.allow_policies.value == 'true' && this.requires_policies.value == 'true' && !this.shoppingCart.accepted_policies) {
				this.messageError = this.ecommerce_parameters.messages_client.no_accepted_policy;
				return false;
			} else if (this.shoppingCart.payment_method.code == 'PMEF' && this.shoppingCart.payment_method.payment_amount < this.shoppingCart.total_amount) {
				this.messageError = "El monto con el que va a pagar debe ser mayor al total de la orden";
				return false;
			} else {
				return true;
			}
		}
	}

	showPolices() {
		$('#modalPolicies').modal('show');
	}

	processPayment() {
		console.log("click payment");
		// in line
		if (this.payment_in_line) {
			// visanet
			if (this.payment_with_visa) {
				this.processPaymentVisa();
			}
			// MC procesos
			else {
				this.processPaymentMasterCard();
			}
		}
		// pago efectivo
		else if (this.payment_pago_efectivo) {
			this.processPaymentPagoEfectivo();
		}
	}

	processPaymentVisa() {
		// console.log(this.shoppingCart.payment_method.visa_net.session_Key);
		this.progressButton.process = true;
		let btn = $('#btnProcess');

		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);

			if (this.validateModel()) {
				if (!this.vnToken || !this.shoppingCart.payment_method.visa_net || !this.shoppingCart.payment_method.visa_net.session_Key || this.shoppingCart.payment_method.visa_net.session_Key == '') {
					// this.getSessionVisaNet();
					// crete token connection
					this.integrationVisaService.getTokenSecurity().subscribe(
						(response: any) => {
							// console.log(response);
							if (response.header.status) {
								this.vnToken = response.body;
								// save token in local storage
								StorageUtil.set(EnumType.LocalStorage.VISANET, response.body, environment.storage_encrypt_flag);

								// create communication session
								let session_parameters = {
									amount: this.shoppingCart.total_amount,
									recurrenceMaxAmount: undefined
								};

								this.integrationVisaService.getCommunicationSession(session_parameters).subscribe(
									(response_session: any) => {
										if (response.header.status) {
											this.shoppingCart.payment_method.visa_net = {
												session_Key: response_session.body.sessionKey,
												expirationTime: response_session.body.expirationTime,
												attempt_number: response_session.body.attempt_number,

												merchant_id: response_session.body.merchant_id,
												src_script: response_session.body.src_script,
												timeout_url: response_session.body.timeout_url,
												merchant_logo: response_session.body.merchant_logo,

												created_timestamp: response_session.body.created_timestamp,
												transaction_authorization: {}
											};

											var frm = document.getElementById('frmVisa');
											frm.setAttribute('action', environment.base_url + '/v1.0.0/integrations-visanet/post-form/' + this.shoppingCart.order_id);

											if (frm) {
												var s_VN = document.getElementById('script_vn');
												// console.log(s_VN);
												if (!s_VN) {
													console.log(this.shoppingCart.payment_method.visa_net);
													var s = document.createElement('script');
													s.id = 'script_vn';
													s.type = 'text/javascript';

													s.src = this.shoppingCart.payment_method.visa_net.src_script;
													s.setAttribute('data-sessiontoken', this.shoppingCart.payment_method.visa_net.session_Key);
													s.setAttribute('data-channel', 'web');
													s.setAttribute('data-merchantid', this.shoppingCart.payment_method.visa_net.merchant_id);
													s.setAttribute('data-merchantlogo', this.shoppingCart.payment_method.visa_net.merchant_logo);
													s.setAttribute('data-formbuttoncolor', this.ecommerce_parameters.color_buttons.primary.background);
													s.setAttribute('data-purchasenumber', this.shoppingCart.payment_method.visa_net.attempt_number);
													s.setAttribute('data-amount', this.shoppingCart.total_amount.toString());
													s.setAttribute('data-expirationminutes', '5');
													s.setAttribute('data-timeouturl', this.shoppingCart.payment_method.visa_net.timeout_url);

													frm.appendChild(s);
												}

												setTimeout(() => {
													var btnVisa = $("#frmVisa").find(":button");

													if (btnVisa.length != 0) {
														btnVisa.click();

														setTimeout(() => {
															let bodyStyle = document.body.style;

															// button primary
															bodyStyle.setProperty('--button-primary-border-color', this.ecommerce_parameters.color_buttons.primary.border);
															bodyStyle.setProperty('--button-primary-background-color', this.ecommerce_parameters.color_buttons.primary.background);
															bodyStyle.setProperty('--button-primary-active-border-color', this.ecommerce_parameters.color_buttons.primary.active_border);
															bodyStyle.setProperty('--button-primary-active-background-color', this.ecommerce_parameters.color_buttons.primary.active_background);
															bodyStyle.setProperty('--button-font-color', this.ecommerce_parameters.color_buttons.primary.font);
															this.progressButton.process = false;
															btn.data('isbusy', false);
														}, 1500);
													} else {
														this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
														$('#modalMessage').modal('show');
														this.progressButton.process = false;
														btn.data('isbusy', false);
													}
												}, 1500);
											}
										} else {
											this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
											$('#modalMessage').modal('show');
											this.progressButton.process = false;
											btn.data('isbusy', false);
										}
									},
									(err) => {
										console.log(err);
										this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
										$('#modalMessage').modal('show');
										this.progressButton.process = false;
										btn.data('isbusy', false);
									}
								);

							} else {
								console.log(response.header.message_description);
								this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
								$('#modalMessage').modal('show');
								this.progressButton.process = false;
								btn.data('isbusy', false);
							}
						},
						(err) => {
							console.log(err);
							this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
							$('#modalMessage').modal('show');
							this.progressButton.process = false;
							btn.data('isbusy', false);
						}
					);
				} else {

					var frm = document.getElementById('frmVisa');
					if (frm) {
						var s_VN = document.getElementById('script_vn');
						if (!s_VN) {
							// create script
							var s = document.createElement('script');
							s.id = 'script_vn';
							s.type = 'text/javascript';
							s.src = this.shoppingCart.payment_method.visa_net.src_script;
							s.setAttribute('data-sessiontoken', this.shoppingCart.payment_method.visa_net.session_Key);
							s.setAttribute('data-channel', 'web');
							s.setAttribute('data-merchantid', this.shoppingCart.payment_method.visa_net.merchant_id);
							s.setAttribute('data-merchantlogo', this.shoppingCart.payment_method.visa_net.merchant_logo);
							s.setAttribute('data-formbuttoncolor', this.ecommerce_parameters.color_buttons.primary.background);
							s.setAttribute('data-purchasenumber', this.shoppingCart.payment_method.visa_net.attempt_number);
							s.setAttribute('data-amount', this.shoppingCart.total_amount.toString());
							s.setAttribute('data-expirationminutes', '5');
							s.setAttribute('data-timeouturl', this.shoppingCart.payment_method.visa_net.timeout_url);

							frm.appendChild(s);
						}
						frm.setAttribute('action', environment.base_url + '/v1.0.0/integrations-visanet/post-form/' + this.shoppingCart.order_id);

						setTimeout(() => {
							var btnVisa = $("#frmVisa").find(":button");
							if (btnVisa.length != 0) {
								btnVisa.click();
								setTimeout(() => {
									let bodyStyle = document.body.style;
									// button primary
									bodyStyle.setProperty('--button-primary-border-color', this.ecommerce_parameters.color_buttons.primary.border);
									bodyStyle.setProperty('--button-primary-background-color', this.ecommerce_parameters.color_buttons.primary.background);
									bodyStyle.setProperty('--button-primary-active-border-color', this.ecommerce_parameters.color_buttons.primary.active_border);
									bodyStyle.setProperty('--button-primary-active-background-color', this.ecommerce_parameters.color_buttons.primary.active_background);
									bodyStyle.setProperty('--button-font-color', this.ecommerce_parameters.color_buttons.primary.font);
									this.progressButton.process = false;
									btn.data('isbusy', false);
								}, 1500);
							} else {
								this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
								$('#modalMessage').modal('show');
								this.progressButton.process = false;
								btn.data('isbusy', false);
							}
						}, 1500);
					}
				}
			} else {
				$('#modalMessage').modal('show');
				this.progressButton.process = false;
				btn.data('isbusy', false);
			}
		}

	}

	processPaymentMasterCard() {
		this.progressButton.process = true;
		let btn = $('#btnProcess');

		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);

			if (this.validateModel()) {
				// $("#frmMasterCard").find(":button").click();

				// generar firma
				this.integrationsMCService.getSignatureMC().subscribe(
					(response: any) => {
						console.log(response.body);
						if (response.header.status) {

							let url_action = '';
							switch (this.shoppingCart.payment_method.card.name) {
								case 'Mastercard':
									url_action = response.body.url_action;
									break;
								case 'Dinners Club':
									url_action = response.body.url_action_dinners;
									break;
								case 'American Express':
									url_action = response.body.url_action_amex;
									break;
								default:
									url_action = response.body.url_action;
									break;
							}
							// enctype="multipart/form-data"
							var tagHtml = '<form id="frmMasterCard" method="post" action="' + url_action + '">';
							tagHtml += '<input type="hidden" name="I1" value="' + response.body.mc_trade_code + '" />';
							tagHtml += '<input type="hidden" name="I2" value="' + response.body.references_number + '" />';
							tagHtml += '<input type="hidden" name="I3" value="' + this.shoppingCart.total_amount.toFixed(2) + '" />';
							tagHtml += '<input type="hidden" name="I4" value="' + this.shoppingCart.currency.code + '" />';
							tagHtml += '<input type="hidden" name="I5" value="' + response.body.date + '" />';
							tagHtml += '<input type="hidden" name="I6" value="' + response.body.hour + '" />';
							tagHtml += '<input type="hidden" name="I7" value="' + response.body.random + '" />';
							tagHtml += '<input type="hidden" name="I8" value="' + this.shoppingCart.customer.document_number + '" />';
							tagHtml += '<input type="hidden" name="I9" value="' + response.body.country + '" />';
							tagHtml += '<input type="hidden" name="I10" value="' + response.body.signature + '" />';
							tagHtml += '<button id="btnProcess" type="submit" class="btn btn-primary btn-block my-2 my-2">FINALIZAR MC</button>';

							tagHtml += '</form>';
							var divMC = document.getElementById('divMasterCard');
							divMC.innerHTML = tagHtml;

							setTimeout(() => {
								var btnMasterCard = $("#frmMasterCard").find(":button");
								console.log(btnMasterCard);
								if (btnMasterCard.length != 0) {
									btnMasterCard.click();
								} else {
									this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
									$('#modalMessage').modal('show');
									this.progressButton.process = false;
									btn.data('isbusy', false);
								}
							}, 2000);

						} else {
							this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
							$('#modalMessage').modal('show');
							this.progressButton.process = false;
							btn.data('isbusy', false);
						}

						// setTimeout(() => {
						// 	this.loadFormMC(response.body);
						// }, 500);
					},
					(err) => {
						console.log(err);
						this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
						$('#modalMessage').modal('show');
						this.progressButton.process = false;
						btn.data('isbusy', false);
					}
				);

			} else {
				$('#modalMessage').modal('show');
				this.progressButton.process = false;
				btn.data('isbusy', false);
			}

		}

	}

	processPaymentPagoEfectivo() {
		this.progressButton.process = true;
		let btn = $('#btnProcess');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);

			if (this.validateModel()) {
				this.integrationsPagoEfectivoService.generateCipCode().subscribe(
					(response: any) => {
						if (response.header.status) {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.router.navigate(['checkout/confirm']);
						} else {
							this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
							$('#modalMessage').modal('show');
							this.progressButton.process = false;
							btn.data('isbusy', false);
						}
					},
					(err) => {
						console.log(err);
						this.messageError = "Ocurrio un error al realizar el pago, por favor volver a intentar";
						$('#modalMessage').modal('show');
						this.progressButton.process = false;
						btn.data('isbusy', false);
					}
				);
			} else {
				$('#modalMessage').modal('show');
				this.progressButton.process = false;
				btn.data('isbusy', false);
			}
		}
	}

	validCustomerData(customer: CustomerModel) {
		console.log(customer);
		let valid = true;

		if (customer && customer.customer_id) {
			if (!customer.document_number || customer.document_number == '') {
				valid = false;
			}
			if (!customer.document_type || customer.document_type == '') {
				valid = false;
			}
			if (!customer.last_names || customer.last_names == '') {
				valid = false;
			}
			if (!customer.mail || customer.mail == '') {
				valid = false;
			}
			if (!customer.names || customer.names == '') {
				valid = false;
			}
			if (!customer.phone_number || customer.phone_number == '') {
				valid = false;
			}
		}


		// if(customer.)

		return valid;
	}
}

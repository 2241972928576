export class EcommerceRulesModel {
    name: string;
    description: string;
    value: string;
    page: string;
    specs: any[];
    adapter_fields: string[];
    status: string;
    created_timestamp: Date;
    modified_timestamp: Date;
    // page_shopping_cart: {
    //     modify_quantities: boolean,
    //     metadata: {
    //         is_active: boolean,
    //         template_name: string,
    //         button_label: string,
    //         search_fields: string[],
    //         adapter_find: string,
    //         adapter_save: string
    //     }
    // };
    // page_detection: {
    //     bypass: boolean,
    //     adapter_find: string,
    //     search_field: string
    //     regular_expression: string,
    // };
    // page_detail: {
    //     customer: {
    //         is_active: boolean,
    //         is_editable: boolean,
    //         adapter_save: string
    //     },
    //     shipping_method: {
    //         is_active: boolean,
    //         shipping_store: {
    //             is_active: boolean,
    //             adapter_find: string
    //         },
    //         shipping_address: {
    //             is_active: boolean,
    //             adapter_find: string,
    //             adapter_save: string
    //         },
    //         delivery_time: {
    //             is_active: boolean,
    //             adapter_find: string
    //         },
    //     },
    //     payment_method: {
    //         is_active: boolean,
    //         adapter_find: string
    //     },
    //     payment_document: {
    //         is_active: boolean,
    //     },
    //     policies: {
    //         is_active: boolean,
    //         is_required: boolean,
    //     }
    // };
}
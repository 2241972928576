<div style="width: 322px; margin: auto;">
    <div class="box-bus">
        <div class="wrapper" style="width: 100%;">
            <ng-container *ngIf="primerPiso != undefined">
                <div class="head-bus radius">
                    <figure>
                        <svg _ngcontent-serverApp-c3="" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink"
                            enable-background="new 0 0 270 271" id="Capa_1" version="1.1" viewBox="0 0 270 271" x="0px"
                            xmlns="http://www.w3.org/2000/svg" y="0px">
                            <g _ngcontent-serverApp-c3="">
                                <path _ngcontent-serverApp-c3=""
                                    d="M264.13,134.85c-0.01,71.87-57.83,129.16-130.61,128.83C62.53,263.35,5.01,204.15,5.76,132.83
                            C6.49,62.64,64.71,4.39,135.78,5.1C209.95,5.85,264.98,66.37,264.13,134.85z M131.79,31.66c-10.23,0.17-23.47,2.35-36.03,7.78
                            c-31.17,13.49-51.32,36.65-60.15,69.45c-0.58,2.16,0.18,5.65,1.68,7.12c1.48,1.45,5.01,2.08,7.1,1.41
                            c18.06-5.77,36.12-11.55,53.93-18.03c21.32-7.75,42.69-10.03,64.54-2.84c20.53,6.75,40.98,13.77,61.55,20.37
                            c2.47,0.79,6.43,0.75,8.06-0.72c1.67-1.5,2.28-5.46,1.71-7.97C224.07,64.26,181.69,31.59,131.79,31.66z M47.91,153.25
                            c-8.61-0.25-13.42,4.44-11.17,11.4c9.58,29.61,28.5,51.01,56.9,63.94c4.82,2.19,9.14,1.37,12.81-2.25
                            c3.89-3.83,4.34-8.28,1.48-13.69c-5.99-11.32-12.1-22.57-18.14-33.86C80.85,162.09,67.12,153.22,47.91,153.25z M218.93,153.45
                            c-15.42,0.37-27.87,7.47-36.01,21.15c-7.57,12.71-14.33,25.91-21.25,38.99c-3.28,6.2-0.35,12.79,6.16,15.5
                            c4.7,1.95,8.49-0.32,12.28-2.22c25.46-12.78,42.89-32.66,52.36-59.55c3.09-8.77,0.79-12.69-8.34-13.56
                            C222.63,153.63,221.13,153.58,218.93,153.45z M134.93,169.89c16.85-0.01,30.54-13.46,30.15-30.51
                            c-0.39-16.93-14.22-29.51-30.2-29.48c-15.16,0.03-29.81,12.13-29.96,30.3C104.78,155.56,117.98,170.13,134.93,169.89z"
                                    fill="#FFFFFF"></path>
                            </g>
                        </svg>
                    </figure>
                    <div class="label_piso">PRIMER PISO</div>
                </div>
                <div class="bus" [style.width.px]="primerPiso.props.bw" [style.height.px]="primerPiso.props.bh">
                    <ng-container *ngFor="let seat of primerPiso.elements; let i = index">
                        <ng-container *ngIf="seat.type == 'asiento'">
                            <div class="element-bus" [style.top.px]="seat.y" [style.left.px]="seat.x">
                                <div class="manija-left-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>
                                <div class="manija-right-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>
                                <div class="respaldo-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>
                                <div class="cabecera-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>

                                <button class="asiento" style="background: rgb(255, 255, 255); color: rgb(85, 85, 85);"
                                    [ngClass]="{'disabled': seat.bloq_inter === '1','selected': seat.selected}"
                                    (click)="blockSeat('primerPiso',i)">
                                    <span style="font-size: 8px !important;">S/</span>
                                    <!-- <span class="number">{{seat.asiento}}</span>  -->
                                    <span>{{seat.precio}}</span>
                                </button>

                            </div>
                        </ng-container>
                        <ng-container *ngIf="seat.type == 'escalera'">
                            <div class="element-bus escalera" [style.top.px]="seat.y" [style.left.px]="seat.x">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="seat.type == 'sshh'">
                            <div class="element-bus sshh" [style.top.px]="segundoPiso == undefined ? seat.y : 7"
                                [style.left.px]="seat.x">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="segundoPiso != undefined">

                <div class="head-bus radius" style="margin-top: 20px;">
                    <div class="label_piso">SEGUNDO PISO</div>
                </div>

                <div class="bus" style="margin-top: 10px;" [style.width.px]="segundoPiso.props.bw"
                    [style.height.px]="segundoPiso.props.bh">
                    <ng-container *ngFor="let seat of segundoPiso.elements; let i = index">
                        <ng-container *ngIf="seat.type == 'asiento'">
                            <div class="element-bus" [style.top.px]="seat.y" [style.left.px]="seat.x">
                                <div class="manija-left-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>
                                <div class="manija-right-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>
                                <div class="respaldo-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>
                                <div class="cabecera-asientobus" [ngClass]="{'disabled': seat.bloq_inter === '1'}">
                                </div>

                                <button class="asiento" style="background: rgb(255, 255, 255); color: rgb(85, 85, 85);"
                                    [ngClass]="{'disabled': seat.bloq_inter === '1','selected': seat.selected}"
                                    (click)="blockSeat('segundoPiso',i)">
                                    <span style="font-size: 9px !important;">S/</span>
                                    <!-- <span class="number">{{seat.asiento}}</span>  -->
                                    <span style="">{{seat.precio}}</span>
                                </button>

                            </div>
                        </ng-container>
                        <ng-container *ngIf="seat.type == 'escalera'">
                            <div class="element-bus escalera" [style.top.px]="seat.y" [style.left.px]="seat.x">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="seat.type == 'sshh'">
                            <div class="element-bus sshh" [style.top.px]="seat.y" [style.left.px]="seat.x">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
// import { AuthService } from '../../services/auth/auth.service';
import { StorageUtil } from '../../../utils/storage-util';
import { environment } from '../../../../environments/environment';
import { EnumType } from '../../../models/enums/enum';
import { CheckoutService } from '../../../services/checkout/checkout.service';

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(
    // private router: Router,
    // private auth: AuthService,
    private checkoutServices: CheckoutService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      try {
        let tokenSession: any = StorageUtil.get(EnumType.LocalStorage.AUTH, environment.storage_encrypt_flag);
        if (!tokenSession) {
          // this.auth.redirectLogin();
          resolve(false);
        } else {

          let page = this.getPageId(state.url);
          this.checkoutServices.getOrderDetail(page).subscribe(
            (response: any) => {
              if (response.header.status) {

                if (response.body.labels) this.checkoutServices.setPageLabel(response.body.labels);
                if (response.body.shoppingCart) this.checkoutServices.setShoppingCart(response.body.shoppingCart);
                if (response.body.stores_list) this.checkoutServices.setStoreList(response.body.stores_list);
                if (response.body.session_language) this.checkoutServices.setlanguage(response.body.session_language);
                
                if (response.body.shipping_addresses) this.checkoutServices.setShippingAddressList(response.body.shipping_addresses);
                if (response.body.ecommerce_parameters) {
                  this.checkoutServices.setEcommerceParameters(response.body.ecommerce_parameters);
                  this.initCustomCss();
                }

                resolve(true);
              }
            },
            (err) => {
              resolve(false);
            }
          );
          // }
        }
      } catch (exp) {
        console.log(exp);
        resolve(false);
      }
    });
  }

  canActivateChild(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // if (!this.auth.isLoggedIn) {
    //     this.auth.redirectLogin();
    //     return false;
    // }
    return true;
  }

  initCustomCss() {
    let bodyStyle = document.body.style;

    // button primary
    bodyStyle.setProperty('--button-primary-border-color', this.checkoutServices.getEcommerceParameters().color_buttons.primary.border);
    bodyStyle.setProperty('--button-primary-background-color', this.checkoutServices.getEcommerceParameters().color_buttons.primary.background);
    bodyStyle.setProperty('--button-primary-active-border-color', this.checkoutServices.getEcommerceParameters().color_buttons.primary.active_border);
    bodyStyle.setProperty('--button-primary-active-background-color', this.checkoutServices.getEcommerceParameters().color_buttons.primary.active_background);
    bodyStyle.setProperty('--button-font-color', this.checkoutServices.getEcommerceParameters().color_buttons.primary.font);
    // button secondary
    bodyStyle.setProperty('--button-only-border-border-color', this.checkoutServices.getEcommerceParameters().color_buttons.secondary.border);
    bodyStyle.setProperty('--button-only-border-background-color', this.checkoutServices.getEcommerceParameters().color_buttons.secondary.background);
    bodyStyle.setProperty('--button-only-border-active-border-color', this.checkoutServices.getEcommerceParameters().color_buttons.secondary.active_border);
    bodyStyle.setProperty('--button-only-border-active-background-color', this.checkoutServices.getEcommerceParameters().color_buttons.secondary.active_background);
    bodyStyle.setProperty('--button-alternative-font-color', this.checkoutServices.getEcommerceParameters().color_buttons.secondary.font);
    // button third
    bodyStyle.setProperty('--button-danger-border-color', this.checkoutServices.getEcommerceParameters().color_buttons.third.border);
    bodyStyle.setProperty('--button-danger-background-color', this.checkoutServices.getEcommerceParameters().color_buttons.third.background);
    bodyStyle.setProperty('--button-danger-active-border-color', this.checkoutServices.getEcommerceParameters().color_buttons.third.active_border);
    bodyStyle.setProperty('--button-danger-active-background-color', this.checkoutServices.getEcommerceParameters().color_buttons.third.active_background);
    bodyStyle.setProperty('--button-danger-font-color', this.checkoutServices.getEcommerceParameters().color_buttons.third.font);
    // seats
    bodyStyle.setProperty('--seat-depart-background-color', this.checkoutServices.getEcommerceParameters().css.seat_depart_tab.background);
    bodyStyle.setProperty('--seat-depart-border-bottom-color', this.checkoutServices.getEcommerceParameters().css.seat_depart_tab.border);
    bodyStyle.setProperty('--seat-depart-font-color', this.checkoutServices.getEcommerceParameters().css.seat_depart_tab.font);
    bodyStyle.setProperty('--seat-depart-active-background-color', this.checkoutServices.getEcommerceParameters().css.seat_depart_tab.active_background);
    bodyStyle.setProperty('--seat-depart-active-border-bottom-color', this.checkoutServices.getEcommerceParameters().css.seat_depart_tab.active_border);
    bodyStyle.setProperty('--seat-depart-active-font-color', this.checkoutServices.getEcommerceParameters().css.seat_depart_tab.active_font);
    bodyStyle.setProperty('--seat-return-background-color', this.checkoutServices.getEcommerceParameters().css.seat_return_tab.background);
    bodyStyle.setProperty('--seat-return-border-bottom-color', this.checkoutServices.getEcommerceParameters().css.seat_return_tab.border);
    bodyStyle.setProperty('--seat-return-font-color', this.checkoutServices.getEcommerceParameters().css.seat_return_tab.font);
    bodyStyle.setProperty('--seat-return-active-background-color', this.checkoutServices.getEcommerceParameters().css.seat_return_tab.active_background);
    bodyStyle.setProperty('--seat-return-active-border-bottom-color', this.checkoutServices.getEcommerceParameters().css.seat_return_tab.active_border);
    bodyStyle.setProperty('--seat-return-active-font-color', this.checkoutServices.getEcommerceParameters().css.seat_return_tab.active_font);
  }

  getPageId(url: string) {
    let page;
    if (url.includes('/checkout/cart')) {
      page = EnumType.Pages.SHOPPING_CART;
    } else if (url.includes('/checkout/detection')) {
      page = EnumType.Pages.IDENTIFY_CUSTOMER;
    } else if (url.includes('/checkout/detail')) {
      page = EnumType.Pages.SHOPPING_CART_DETAIL;
    } else if (url.includes('/checkout/customer')) {
      page = EnumType.Pages.CUSTOMER;
    } else if (url.includes('/checkout/shipping/address')) {
      page = EnumType.Pages.SHIPPING_ADDRESS_LIST;
    } else if (url.includes('/checkout/shipping/store')) {
      page = EnumType.Pages.SHIPPING_ADDRESS;
    } else if (url.includes('/checkout/shipping')) {
      page = EnumType.Pages.SHIPPING_ADDRESS;
    } else if (url.includes('/checkout/delivery-time')) {
      page = EnumType.Pages.DELIVERY_TIME;
    } else if (url.includes('/checkout/payment-document')) {
      page = EnumType.Pages.PAYMENT_DOCUMENT;
    } else if (url.includes('/checkout/payment-method')) {
      page = EnumType.Pages.PAYMENT_METHOD;
    } else if (url.includes('/checkout/confirm')) {
      page = EnumType.Pages.CONFIRM;
    } else if (url.includes('/checkout/seat-assignment')) {
      page = EnumType.Pages.SEAT_ASSIGNMENT;
    } else if (url.includes('/checkout/metadata')) {
      page = EnumType.Pages.METADATA_FORM;
    }
    return page;
  }
}

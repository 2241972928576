import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-template-loading',
  templateUrl: './template-loading.component.html',
  styleUrls: ['./template-loading.component.scss']
})
export class TemplateLoadingComponent implements OnInit {

  @Input() template: string;
  @Input() count: number;

  indexArray = [] = new Array<number>();

  constructor() { }

  ngOnInit() {
    this.generateRowIndexes(this.count);
  }

  /**
   * Generate an array of numbers to iterate
   * @param count Number of empty objects to display by template. Default 5 items
   */
  public generateRowIndexes(count: number = 5): Array<number> {
    for (let i = 0; i < count; i++) {
      this.indexArray.push(i);
    }
    return this.indexArray;
  }

}

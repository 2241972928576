// Native
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import { AuthGuard } from './auth/guards/auth/auth.guard';

// Pages
import { MasterPageComponent } from './pages/master/master.page';
import { CheckoutCartPageComponent } from './pages/checkout/checkout-cart/checkout-cart.page';
import { CheckoutDetectionPageComponent } from './pages/checkout/checkout-detection/checkout-detection.page';
import { CheckoutDetailPageComponent } from './pages/checkout/checkout-detail/checkout-detail.page';
import { CheckoutCustomerPageComponent } from './pages/checkout/checkout-customer/checkout-customer.page';
import { CheckoutDeliveryTimePageComponent } from './pages/checkout/checkout-delivery-time/checkout-delivery-time.page';
import { CheckoutShippingPageComponent } from './pages/checkout/checkout-shipping/checkout-shipping/checkout-shipping.page';
import { CheckoutShippingAddressPageComponent } from './pages/checkout/checkout-shipping/checkout-shipping-address/checkout-shipping-address.page';
import { CheckoutShippingStorePageComponent } from './pages/checkout/checkout-shipping/checkout-shipping-store/checkout-shipping-store.page';
import { CheckoutPaymentMethodPageComponent } from './pages/checkout/checkout-payment-method/checkout-payment-method.page';
import { CheckoutPaymentDocumentPageComponent } from './pages/checkout/checkout-payment-document/checkout-payment-document.page';
import { CheckoutStepPaymentConfirmationPageComponent } from './pages/checkout/checkout-payment-confirmation/checkout-payment-confirmation.page';
// import { CheckoutSeatAssignmentPageComponent } from './pages/checkout/checkout-seat-assignment/checkout-seat-assignment.component';
import { CheckoutPaymentErrorPageComponent } from './pages/checkout/checkout-payment-error/checkout-payment-error.page';
import { CheckoutSeatAssignmentV2Component } from './pages/checkout/checkout-seat-assignment-v2/checkout-seat-assignment-v2.component';
import { StatusServicePageComponent } from './pages/status-service/status-service.page';
import { MetadataPageComponent } from './pages/metadata/metadata.page'
import { AuthComponent } from './auth/component/auth.component';
import { NotFoundPageComponent } from './pages/not-found/not-found.page';
// import { from } from 'rxjs/observable/from';

// Routes
export const routes: Routes = [
    { path: '', redirectTo: 'auth/', pathMatch: 'full' },
    { path: 'verify-status', component: StatusServicePageComponent },
    { path: 'auth/:token', component: AuthComponent },
    { path: 'auth/:token/:language', component: AuthComponent },
    {
        path: 'checkout', component: MasterPageComponent, children: [
            { path: 'cart', canActivate: [AuthGuard], component: CheckoutCartPageComponent },
            { path: 'detection', canActivate: [AuthGuard], component: CheckoutDetectionPageComponent },
            { path: 'detail', canActivate: [AuthGuard], component: CheckoutDetailPageComponent },
            { path: 'customer', canActivate: [AuthGuard], component: CheckoutCustomerPageComponent },
            {
                path: 'shipping', canActivate: [AuthGuard], children: [
                    { path: '', component: CheckoutShippingPageComponent },
                    { path: 'address/:addressId', component: CheckoutShippingAddressPageComponent },
                    { path: 'store', component: CheckoutShippingStorePageComponent }
                ]
            },
            { path: 'delivery-time', canActivate: [AuthGuard], component: CheckoutDeliveryTimePageComponent },
            { path: 'payment-document', canActivate: [AuthGuard], component: CheckoutPaymentDocumentPageComponent },
            { path: 'payment-method', canActivate: [AuthGuard], component: CheckoutPaymentMethodPageComponent },
            { path: 'confirm', canActivate: [AuthGuard], component: CheckoutStepPaymentConfirmationPageComponent },
            { path: 'seat-assignment', canActivate: [AuthGuard], component: CheckoutSeatAssignmentV2Component },
            
            // {
            //     path: 'shipping', children: [
            //         { path: ':orderId', component: CheckoutStepShippingPageComponent },
            //         { path: 'store/:orderId', component: CheckoutStepShippingStorePageComponent },
            //     ]
            // },
            // {
            //     path: 'payment', children: [
            //         { path: ':orderId', component: CheckoutStepPaymentPageComponent },
            //         { path: 'confirm/:orderId', component: CheckoutStepPaymentConfirmationPageComponent },
            //     ]
            // },
            { path: 'metadata/:itemId/:templateName', canActivate: [AuthGuard], component: MetadataPageComponent },
            { path: 'payment-error', canActivate: [AuthGuard], component: CheckoutPaymentErrorPageComponent },
        ]
    },
    // { path: 'seat-assignment-v2', component: CheckoutSeatAssignmentV2Component },
    { path: '**', component: NotFoundPageComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard
    ]
})
export class AppRoutingModule { }
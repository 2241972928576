import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ShoppingCartModel } from '../../../models/shopping-cart';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
declare let $: any;

@Component({
	selector: 'app-checkout-payment-document',
	templateUrl: './checkout-payment-document.page.html',
	styleUrls: ['./checkout-payment-document.page.scss']
})
export class CheckoutPaymentDocumentPageComponent implements OnInit, OnDestroy {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	paymentFormGroup: FormGroup;
	progressBar: any = {
		list_page: true
	};
	progressButton: any = {
		process: false
	};
	messageError: string = undefined;

	page_title: string = "";

	constructor(
		private checkoutService: CheckoutService,
		private formBuilder: FormBuilder,
		private router: Router,
		private progressbarService: ProgressBarService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
		this.progressBar.list_page = false;
		this.progressbarService.setVisibility(this.progressBar.list_page);
	}

	ngOnInit() {
		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);
		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {
			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.payment_document;
			}

			this.initForm();
			this.appendModalsToBody();
		}

	}

	initForm() {
		this.paymentFormGroup = this.formBuilder.group({
			company: ['', Validators.required],
			ruc: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
			address: ['', Validators.required]
		});
	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
	}

	// getShoppingCartInfo() {

	// }

	savePaymentDocument() {
		let btn = $('#btnProcess');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			this.progressButton.process = true;
			if (this.shoppingCart.payment_document.type == 'BOLETA') {
				this.shoppingCart.payment_document.company = '';
				this.shoppingCart.payment_document.ruc_number = '';
				this.shoppingCart.payment_document.address = '';
			}

			this.checkoutService.updatePaymentDocument(this.shoppingCart.payment_document).subscribe(
				(response: any) => {
					if (response.header.status) {
						this.checkoutService.setShoppingCart(this.shoppingCart);
						this.router.navigate(['/checkout/detail']);
					} else {
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = response.header.message_description;
						$('#modalMessage').modal('show');
					}
				},
				(error) => {
					this.progressButton.process = false;
					btn.data('isbusy', false);
					this.messageError = error.description;
					$('#modalMessage').modal('show');
					console.log('error => ', error);
				}
			);

		}
	}

	selectPaymentDocument(type: string) {
		this.shoppingCart.payment_document.type = type;

		if (type == 'FACTURA') {
			setTimeout(() => {
				$('#lblInputPaymentDocument_1').focus();
			}, 300);
		}
	}

	focusTarget(id: String) {
		$(id).focus();
	}

	focusInputField(event: Event) {
		let id: string = (event.target as Element).id;
		if (id == "lblInputPaymentDocument_1") {
			$("#lblInputPaymentDocument_2").focus();
		} else if (id == "lblInputPaymentDocument_2") {
			$("#lblInputPaymentDocument_3").focus();
		}
	}

}

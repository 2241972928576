import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as lodash from 'lodash';
import { environment } from '../../environments/environment';
import { StorageUtil } from './storage-util';
import { CryptoUtil } from './crypto-util';
import { EnumType } from '../models/enums/enum';
import { throwError } from 'rxjs';

/**
 * Class Utilirian to extract http response
 */
export class HttpUtil {

    constructor(private router: Router) { }

    /**
     * 
     * @param contentType Content-Type to set in Request Headers
     * @param headerParams Header Parameters
     * @param queryParams Query String Parameters
     * @param responseType Response-Type
     */
    static getHttpOptionsWithTokenV2(contentType?: EnumType.ContentType, headerParams?: any, queryParams?: any, responseType?: EnumType.ResponseType): Object {

        let accountString: string = StorageUtil.getAccountString();

        let headerPayload = {};
        const authSession = StorageUtil.get(EnumType.LocalStorage.AUTH, environment.storage_encrypt_flag);

        const token = authSession.token;

        // Add Content Type
        if (contentType) {
            headerPayload['Content-Type'] = contentType;
        }

        // Check token
        if (token) {
            headerPayload['x-access-token'] = token;
        } else {
            headerPayload['account-string'] = accountString
            headerPayload['app-id'] = environment.app_id
        }

        // Add Header Params to request
        if (headerParams) {
            lodash.forEach(Object.getOwnPropertyNames(headerParams), function (value, index) {
                let objKey = value;
                let objValue = headerParams[objKey];
                headerPayload[objKey] = objValue;
            });
        }

        // Add Query Params to request
        let queryPayload = {};
        if (queryParams) {
            lodash.forEach(Object.getOwnPropertyNames(queryParams), function (value, index) {
                let objKey = value;
                let objValue = queryParams[objKey];
                queryPayload[objKey] = objValue;
            });
        }

        // Add Headers to HttpHeaders
        let httpOptions = {
            headers: new HttpHeaders(headerPayload),
            observe: 'response' as 'response'
        };

        if (queryParams) { httpOptions['params'] = queryParams; }
        if (responseType) { httpOptions['responseType'] = responseType; }

        return httpOptions;
    }

    static getHttpOptions(headerParams?: any, queryParams?: any): Object {

        let headerPayload;

        let accountString: string = StorageUtil.getAccountString();

        headerPayload = {
            'Content-Type': 'application/json',
            'account-string': CryptoUtil.encrypt(accountString, environment.bcrypt_salt),
            'app-id': CryptoUtil.encrypt(environment.app_id, environment.bcrypt_salt)
        };

        if (headerParams) {
            lodash.forEach(Object.getOwnPropertyNames(headerParams), function (value, index) {
                let objKey = value;
                let objValue = headerParams[objKey];
                headerPayload[objKey] = objValue;
            });
        }

        let queryPayload = {};
        if (queryParams) {
            lodash.forEach(Object.getOwnPropertyNames(queryParams), function (value, index) {
                let objKey = value;
                let objValue = queryParams[objKey];
                queryPayload[objKey] = objValue;
            });
        }

        let httpOptions = {
            headers: new HttpHeaders(headerPayload),
            observe: 'response' as 'response'
        };

        if (queryParams) { httpOptions['params'] = queryParams; }

        return httpOptions;
    }

    static extractData(res: Response) {
        const body = res ? res.body : {};
        return body;
    }

    // static extractBlob(res: Response) {
    //     const fileName = res.headers.get('Content-Disposition') ? res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '') : null;
    //     const blob = new Blob([res.body], { 'type': 'application/octet-stream' });
    //     return { file_name: fileName, blob: blob }
    // }

    static handleErrorObservable(error: any) {
        let message;
        if (error instanceof HttpErrorResponse) {
            let statusCode = error.status;
            if (statusCode === 0) {
                message = environment.messages.server_unavailable;
            } else if (statusCode === 400) {
                message = environment.messages.server_error;
            } else if (statusCode === 401) {
                message = environment.messages.token_expired;
            } else if (statusCode === 408) {
                message = environment.messages.server_timeout;
            } else if (statusCode === 500) {
                message = environment.messages.server_error;
            } else {
                message = environment.messages.server_error;
            }
        }
        return throwError(message);
    }

    static handleErrorObservableReturnHttpCode(error: any) {
        return throwError(error.status);
    }

}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { ShoppingCartModel } from '../../../models/shopping-cart';
import * as lodash from 'lodash';
import { EcommerceRulesService } from '../../../services/ecommerce-rules/ecommerce-rules.service';
import { EnumType } from '../../../models/enums/enum';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
declare let $: any;

@Component({
	selector: 'app-checkout-payment-method',
	templateUrl: './checkout-payment-method.page.html',
	styleUrls: ['./checkout-payment-method.page.scss']
})
export class CheckoutPaymentMethodPageComponent implements OnInit {

	session_language: string;

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	paymentMethodsList: any[] = new Array<any>();
	selectedPaymentMethod: any = {
		payment_id: '',
		name: '',
		description: '',
		payment_amount: '',
	};
	selectedCard: any = {
		id: '',
		name: '',
	};
	amount: number = 0;
	progressButton: any = {
		process: false,
		polices: false
	};
	progressBar: any = {
		list_page: true
	};
	messageError: string = undefined;

	page_title: string = "";

	constructor(
		private checkoutService: CheckoutService,
		private router: Router,
		private ecommerceRulesService: EcommerceRulesService,
		private progressbarService: ProgressBarService
	) {
		this.shoppingCart = this.checkoutService.getShoppingCart();
		this.session_language = this.checkoutService.getlanguage();
	}

	ngOnInit() {
		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {
			// reset data visanet
			this.shoppingCart.payment_method.visa_net = {
				session_Key: '',
				attempt_number: '',
				created_timestamp: '',
				expirationTime: '',
				merchant_id: '',
				src_script: '',
				timeout_url: '',
				merchant_logo: '',
				transaction_authorization: undefined
			};

			this.selectedPaymentMethod = this.shoppingCart.payment_method;
			this.amount = this.shoppingCart.payment_method.payment_amount;
			this.selectedCard = this.shoppingCart.payment_method.card;
			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.payment_method;
				this.getRules();
				this.appendModalsToBody();

				if (this.selectedPaymentMethod.code == 'PMEF') {
					setTimeout(() => {
						$('#lblInputPayment_1').focus();
					}, 300);
				}

				let policieIndex = lodash.findIndex(this.checkoutService.getEcommerceParameters().terms_and_conditions, { language_code: this.session_language })

				if (policieIndex != -1)
					$('#divPolicies').append(this.checkoutService.getEcommerceParameters().terms_and_conditions[policieIndex].terms);
			}
		}
	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	getRules() {
		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.PAYMENT_METHOD).subscribe(
			(response: any) => {
				if (response.header.status) {
					let payment_method: any = lodash.find(response.body, { name: EnumType.EcommerceRules.FIND_PAYMENT_METHOD });
					if (payment_method) {
						if (payment_method.value == 'API') {
							let spec: any = lodash.find(payment_method.specs, { value: payment_method.value });

							let entity = {};

							payment_method.adapter_fields.forEach(field => {
								entity[field] = this.shoppingCart.customer[field];
							});
							console.log(spec.adapter_find);
							this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
								(response: any) => {
									if (response.header.status) {
										this.paymentMethodsList = response.body;
										this.progressBar.list_page = false;
										this.progressbarService.setVisibility(this.progressBar.list_page);
									}
								},
								(err) => {
									console.log(err);
									this.progressBar.list_page = false;
									this.progressbarService.setVisibility(this.progressBar.list_page);
								}
							);
						} else {
							this.getPaymentMethods();
						}
					} else {

					}

				} else {

				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
		$('#modalPoliciesPaymentMethod').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
		$('body > #modalPoliciesPaymentMethod').remove();
	}

	getPaymentMethods() {
		this.checkoutService.getPaymentMethods().subscribe(
			(response: any) => {
				this.progressBar.list_page = false;
				this.progressbarService.setVisibility(this.progressBar.list_page);
				if (response.header.status) {
					console.log(response.body);
					this.paymentMethodsList = response.body;
				}
			},
			(err) => {
				console.log(err);
				this.progressBar.list_page = false;
				this.progressbarService.setVisibility(this.progressBar.list_page);
			}
		);
	}

	selectPaymentMethod(method: any) {
		this.selectedPaymentMethod.payment_id = '';
		this.selectedCard.id = '';

		this.selectedPaymentMethod = {
			payment_id: method.payment_id,
			code: method.code,
			description: method.description,
			payment_amount: method.payment_amount,
			card: {},
			visa_net: {
				session_Key: '',
				expirationTime: '',
				attempt_number: '',
				merchant_id: '',
				src_script: '',
				timeout_url: '',
				merchant_logo: '',
				created_timestamp: '',
				transaction_authorization: {}
			},
			master_card: {
				signature: '',
				transaction_result: {}
			},
			summary_object: {
				number_references: '',
				tx_date: '',
				tx_time: '',
				auth_code: '',
				tx_card: '',
				message_response: ''
			}
		};

		if (this.selectedPaymentMethod.code == 'PMEF') {
			setTimeout(() => {
				$('#lblInputPayment_1').focus();
			}, 100);
		}
	}

	selectCard(item) {
		let paymentCard: any = lodash.find(this.paymentMethodsList, ['code', 'PMCC']);
		if (paymentCard && paymentCard.payment_id == this.selectedPaymentMethod.payment_id) {
			this.selectedCard = {
				id: item.id,
				name: item.name
			}
		}
	}

	selectPaymentInLine(item) {
		let payment_in_line: any = lodash.find(this.paymentMethodsList, ['code', 'PMIL']);
		if (payment_in_line && payment_in_line.payment_id == this.selectedPaymentMethod.payment_id) {
			this.selectedCard = {
				id: item.id,
				name: item.name
			}
		}
	}

	validPaymentMethod() {
		this.messageError = undefined;
		let valid = false;
		if (this.selectedPaymentMethod.payment_id == '') {
			this.messageError = 'Por favor seleccionar un método de pago';
			$('#modalMessage').modal('show');
		} else {
			if (this.selectedPaymentMethod.code == 'PMCC') {
				if (this.selectedCard.id == '') {
					this.messageError = 'Seleccionar un tipo de tarjeta';
					$('#modalMessage').modal('show');
				} else {
					this.shoppingCart.payment_method = this.selectedPaymentMethod;
					this.shoppingCart.payment_method.card = this.selectedCard;
					//this.savePaymentMethod();
					valid = true;
				}
			} else if (this.selectedPaymentMethod.code == 'PMEF') {
				if (this.amount == undefined || this.amount == 0) {
					this.messageError = 'Por favor indicar el monto con el que va a pagar';
					$('#modalMessage').modal('show');
				} else if (this.amount < this.shoppingCart.total_amount) {
					this.messageError = 'El monto con el que va a pagar debe ser mayor al total de la orden';
					$('#modalMessage').modal('show');
				} else {
					this.selectedPaymentMethod.payment_amount = this.amount;
					this.shoppingCart.payment_method = this.selectedPaymentMethod;
					this.shoppingCart.payment_method.card = this.selectedCard;
					// this.savePaymentMethod();
					valid = true;
				}
			} else if (this.selectedPaymentMethod.code == 'PMIL') {
				if (this.selectedCard.id == '') {
					this.messageError = 'Seleccionar un tipo de pago en línea';
					$('#modalMessage').modal('show');
				} else {
					this.shoppingCart.payment_method = this.selectedPaymentMethod;
					this.shoppingCart.payment_method.card = this.selectedCard;
					// this.savePaymentMethod();
					valid = true;
				}
			} else {
				this.shoppingCart.payment_method = this.selectedPaymentMethod;
				// this.savePaymentMethod();
				valid = true;
			}
		}

		if (valid) {
			if (this.shoppingCart.payment_method.code == 'PMCC' || this.shoppingCart.payment_method.code == 'PMIL') {
				if (this.shoppingCart.payment_method.code == 'PMIL' && this.shoppingCart.payment_method.card.name == 'Visa') {
					this.shoppingCart.accepted_policies = true;
					$('#modalPoliciesPaymentMethod').modal('show');
				} else {
					this.shoppingCart.accepted_policies = false;
					this.savePaymentMethod();
				}
			} else {
				this.shoppingCart.accepted_policies = false;
				this.shoppingCart.payment_method.card = {
					id: '',
					name: ''
				};
				this.savePaymentMethod();
			}
		}
	}

	savePaymentMethod() {
		this.messageError = undefined;
		this.progressButton.process = true;
		this.progressButton.polices = true;
		let btn = $('#btnProcess');
		if (btn.data('isbusy') === false) {
			if (this.shoppingCart.payment_method.code != 'PMEF') {
				this.shoppingCart.payment_method.payment_amount = 0;
			}

			// if (this.shoppingCart.payment_method.code != 'PMCC' && this.shoppingCart.payment_method.code != 'PMIL') {
			// 	this.shoppingCart.payment_method.card = {
			// 		id: '',
			// 		name: ''
			// 	};				
			// }

			this.checkoutService.updatePaymentMethod(this.shoppingCart).subscribe(
				(response: any) => {
					$('#modalPoliciesPaymentMethod').modal('hide');
					if (response.header.status) {
						this.checkoutService.setShoppingCart(this.shoppingCart);
						this.router.navigate(['/checkout/detail']);
					} else {
						this.progressButton.process = false;
						this.progressButton.polices = false;
						btn.data('isbusy', false);
						this.messageError = response.header.message_description;
						$('#modalMessage').modal('show');
					}
				}, (err) => {
					$('#modalPoliciesPaymentMethod').modal('hide');
					this.progressButton.process = false;
					this.progressButton.polices = false;
					btn.data('isbusy', false);
					this.messageError = err.description;
					$('#modalMessage').modal('show');
					console.log('error => ', err);
				}
			);
		}
	}

	focusTarget(id: String) {
		$(id).focus();
	}

}

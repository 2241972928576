import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../../utils/http-util';

import { environment } from '../../../environments/environment';
import { EnumType } from '../../models/enums/enum';

import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MetadataService {

  constructor(
    private http: HttpClient
  ) { }

  /**
	 * Call API Rest to get fields of the metadata
	 */
  getFieldOfMetadata(template_name: string, product_code: string) {
    let entity = {
      template_name: template_name,
      product_code: product_code
    }
    const body = JSON.stringify(entity);

    const url = environment.base_url + '/v1.0.0/metadata';
    return this.http.post(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
      map(HttpUtil.extractData),
      catchError(HttpUtil.handleErrorObservable)
    );
  }

  /**
	 * Call API Rest to update metadata on order detaail
	 * @param product_code product code
   * @param metadata metada
	 */
  updateMetadataByOrderDetail(product_code, metadata) {

    const body = JSON.stringify(metadata);
    const url = environment.base_url + '/v1.0.0/metadata/' + product_code;
    return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
      map(HttpUtil.extractData),
      catchError(HttpUtil.handleErrorObservable)
    );
  }


}

import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	// templateSpinner:string = `<div style="background: url('/assets/images/spinner.svg') no-repeat;position: absolute;left: calc(100vw / 2); top: calc(100vh / 2); width: 14px;height: 14px;margin-bottom: -0.12em;"></div>`
	templateSpinner:string = `<div class="svg-icon-spinner_1"></div>`
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../../environments/environment';

import { EnumType } from '../../../models/enums/enum';
import { AsientoModel, ItineraryDetailModel, LevelModel } from '../../../models/travels/itinerary-detail';
import { EcommerceRulesModel } from '../../../models/ecommerce-rules';
import { ShoppingCartDetailModel, ShoppingCartModel } from '../../../models/shopping-cart';

import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { EcommerceRulesService } from '../../../services/ecommerce-rules/ecommerce-rules.service';

import * as lodash from 'lodash';
declare let $: any;

@Component({
  selector: 'app-checkout-seat-assignment-v2',
  templateUrl: './checkout-seat-assignment-v2.component.html',
  styleUrls: ['./checkout-seat-assignment-v2.component.scss']
})
export class CheckoutSeatAssignmentV2Component implements OnInit {

  page_title: string = "";
  shoppingCart: ShoppingCartModel = new ShoppingCartModel();
  seat_assignment: EcommerceRulesModel = new EcommerceRulesModel();
  seat_limit: number = 0;

  // Depart
  seatSelectedIdaList: ShoppingCartDetailModel[] = new Array<ShoppingCartDetailModel>();
  itineraryDetailIda: ItineraryDetailModel = new ItineraryDetailModel();
  primerPisoIda: LevelModel;
  segundoPisoIda: LevelModel;
  maxSeatDepartLimit: boolean = false;
  agenciesListDepartIda: any[] = new Array<any>();
  agenciesListArrivalIda: any[] = new Array<any>();

  // Return
  seatSelectedRetornoList: ShoppingCartDetailModel[] = new Array<ShoppingCartDetailModel>();
  itineraryDetailRetorno: ItineraryDetailModel = new ItineraryDetailModel();
  primerPisoRetorno: LevelModel;
  segundoPisoRetorno: LevelModel;
  maxSeatReturnLimit: boolean = false;
  agenciesListDepartRetorno: any[] = new Array<any>();
  agenciesListArrivalRetorno: any[] = new Array<any>();

  hasReturn: boolean = false;

  // Data config adapter
  seat_list_depart: any;
  seat_list_return: any;
  agency_list_depart: any;
  agency_list_return: any;

  lock_seat: any;
  unlock_seat: any;
  progressButton: any = {
    seat: false
  };
  progressBar: any = {
    list_page: true
  }
  messageErrorSeat: string = undefined;
  messageErrorSeatLimit: string = undefined;
  messageErrorDepart: string = '';
  messageErrorReturn: string = '';
  messageSeatLimit: string = '';

  isTesting: boolean = false;

  key_agency_departIda = '';
  key_agency_arrivalIda = '';

  key_agency_departRetorno = '';
  key_agency_arrivalRetorno = '';

  tab_active_depart: boolean;
  tab_active_return: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    private ecommerceRulesService: EcommerceRulesService,
    private spinnerService: Ng4LoadingSpinnerService,
    private progressbarService: ProgressBarService
  ) {
    if (environment.production == true) {
      this.isTesting = false;
    } else {
      this.isTesting = true;
    }
    
    let valid_result = this.checkoutService.validShoppingCart();
    if(valid_result != ''){
      this.router.navigate([valid_result]);
    }
    this.shoppingCart = this.checkoutService.getShoppingCart();
    // Travel has return itinerary ?
    if (this.shoppingCart.extended_fields.cds_is_round_trip) {
      this.hasReturn = true;
    } else {
      this.hasReturn = false;
    }

    this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.seat_assignment;
    this.seat_limit = this.checkoutService.getEcommerceParameters().session.detail_limit;
  }

  ngOnInit() {
    this.progressbarService.setVisibility(this.progressBar.list_page);
        
    // if (this.shoppingCart.order_number != 0) {
    //   this.progressbarService.setVisibility(false);
    //   this.router.navigate(['/checkout/confirm']);

    // } else if (this.shoppingCart.payment_method.code == 'PMIL' &&
    //   this.shoppingCart.payment_method.card.name == 'Visa' &&
    //   this.shoppingCart.payment_method.visa_net &&
    //   this.shoppingCart.payment_method.visa_net.transaction_authorization &&
    //   !this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
    //   this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
    //   this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

    //   // Validate if order have a visa success transaction
    //   // If Exists, an error occurred during the purchase generation
    //   this.progressbarService.setVisibility(false);
    //   this.router.navigate(['/checkout/payment-error']);

    // } else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
    //   this.shoppingCart.payment_method.card.name == 'Mastercard' &&
    //   this.shoppingCart.payment_method.master_card &&
    //   this.shoppingCart.payment_method.master_card.transaction_result &&
    //   this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
    //   this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

    //   // Validate if order have a visa success transaction
    //   // If Exists, an error occurred during the purchase generation
    //   this.progressbarService.setVisibility(false);
    //   this.router.navigate(['/checkout/payment-error']);

    // } else {
    //   this.getRules();
    //   this.appendModalsToBody();
    // }
    this.getRules();
    this.appendModalsToBody();
    this.tab_active_depart = true;

    $('#tabBus a').on('click', function (e) {
      console.log(e);
      $(this).tab('show');
    })
  }

  ngOnDestroy() {
    this.removeModalsFromBody();
  }

  appendModalsToBody() {
    $('#modalMessage').appendTo('body');
    $('#modalMessageConfirm').appendTo('body');
    $('#modalMessageLimit').appendTo('body');
  }

  removeModalsFromBody() {
    $('body > #modalMessage').remove();
    $('body > #modalMessageConfirm').remove();
    $('body > #modalMessageLimit').remove();
  }

  getRules() {
    this.ecommerceRulesService.getRulesBypage(EnumType.Pages.SEAT_ASSIGNMENT).subscribe(
      (response: any) => {
        if (response.header.status) {

          this.seat_list_depart = lodash.find(response.body, { name: EnumType.EcommerceRules.FIND_SEAT });
          this.seat_list_return = lodash.find(response.body, { name: EnumType.EcommerceRules.FIND_SEAT });
          this.agency_list_depart = lodash.find(response.body, { name: EnumType.EcommerceRules.FIND_AGENCIES });
          this.agency_list_return = lodash.find(response.body, { name: EnumType.EcommerceRules.FIND_AGENCIES });

          this.lock_seat = lodash.find(response.body, { name: EnumType.EcommerceRules.LOCK_SEAT });
          this.unlock_seat = lodash.find(response.body, { name: EnumType.EcommerceRules.UNLOCK_SEAT });

          this.route.params.subscribe(
            param => {
              let status = this.route.snapshot.queryParams["s"];
              if (status !== undefined) {
                this.messageErrorSeat = this.checkoutService.getEcommerceParameters().messages_client.clean_detail_by_timeout;
                $('#modalMessage').modal('show');
              }
              this.getSeatListDepart();
              this.getAgenciesDepart();

              if (this.hasReturn) {
                this.getSeatListReturn();
                this.getAgenciesReturn();
              }
            }
          );

        } else {
          // TODO: Intentalo nuevamente
          console.log('response => ', response);
        }
      },
      (error) => {
        // TODO: Ocurrio un error inesperado
        console.log(error);
      }
    )
  }

  getAgenciesDepart() {
    if (this.agency_list_depart) {

      this.messageErrorDepart = undefined;
      if (this.agency_list_depart.value == 'API') {

        let spec: any = lodash.find(this.agency_list_depart.specs, { value: this.agency_list_depart.value });

        let entity: any = {};

        entity.sender_id = this.shoppingCart.sender_id;
        entity.localidadEmbarque = this.shoppingCart.extended_fields['cds_origin_code'];
        entity.localidadDesembarque = this.shoppingCart.extended_fields['cds_destination_code'];
        entity.fechaProgramacion = this.shoppingCart.extended_fields['cds_date_depart_trip'];
        entity.service_key = this.shoppingCart.extended_fields['cds_service_key_depart'];
        entity.key_programming = this.shoppingCart.extended_fields['cds_key_programming_depart'];

        this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
          (response: any) => {
            console.log('ida > ', response);
            if (response.header.status) {
              this.agenciesListDepartIda = lodash.filter(response.body, { direction: 'embarque' });
              this.agenciesListArrivalIda = lodash.filter(response.body, { direction: 'desembarque' });

              this.key_agency_departIda = this.agenciesListDepartIda[0].agency_key;
              this.key_agency_arrivalIda = this.agenciesListArrivalIda[0].agency_key;

              this.progressBar.list_page = false;
              this.progressbarService.setVisibility(this.progressBar.list_page);
            }
            // else {
            //   this.messageErrorDepart = response.header.message_description;
            // }

          },
          (error) => {
            // this.messageErrorDepart = error.description;
            this.progressBar.list_page = false;
            this.progressbarService.setVisibility(this.progressBar.list_page);
          }
        );

      } else {
        // NO PENDING IMPLEMENTATION
      }
    } else {
      // this.messageErrorDepart = 'No se encontraron datos para la consulta de asientos disponibles';
    }
  }

  getAgenciesReturn() {
    if (this.agency_list_depart) {

      this.messageErrorDepart = undefined;
      if (this.agency_list_depart.value == 'API') {

        let spec: any = lodash.find(this.agency_list_depart.specs, { value: this.agency_list_depart.value });

        let entity: any = {};
        entity.localidadEmbarque = this.shoppingCart.extended_fields['cds_destination_code'];
        entity.localidadDesembarque = this.shoppingCart.extended_fields['cds_origin_code'];
        entity.fechaProgramacion = this.shoppingCart.extended_fields['cds_date_return_trip'];
        entity.service_key = this.shoppingCart.extended_fields['cds_service_key_return'];
        entity.key_programming = this.shoppingCart.extended_fields['cds_key_programming_return'];

        this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
          (response: any) => {
            if (response.header.status) {
              console.log('retorno > ', response);
              this.agenciesListDepartRetorno = lodash.filter(response.body, { direction: 'embarque' });
              this.agenciesListArrivalRetorno = lodash.filter(response.body, { direction: 'desembarque' });

              this.key_agency_departRetorno = this.agenciesListDepartRetorno[0].agency_key;
              this.key_agency_arrivalRetorno = this.agenciesListArrivalRetorno[0].agency_key;

              this.progressBar.list_page = false;
              this.progressbarService.setVisibility(this.progressBar.list_page);
            }
            // else {
            //   this.messageErrorDepart = response.header.message_description;
            // }
          },
          (error) => {
            // this.messageErrorDepart = error.description;
            this.progressBar.list_page = false;
            this.progressbarService.setVisibility(this.progressBar.list_page);
          }
        );

      } else {
        // NO PENDING IMPLEMENTATION
      }
    } else {
      // this.messageErrorDepart = 'No se encontraron datos para la consulta de asientos disponibles';
    }
  }

  getSeatListDepart() {
    if (this.seat_list_depart) {

      this.messageErrorDepart = undefined;
      if (this.seat_list_depart.value == 'API') {

        let spec: any = lodash.find(this.seat_list_depart.specs, { value: this.seat_list_depart.value });

        let entity: any = {};
        entity.programacionLlave = this.shoppingCart.extended_fields['id_programming_depart'];
        this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
          (response: any) => {

            if (response.header.status) {
              this.itineraryDetailIda = Object.assign(response.body[0], ItineraryDetailModel);
              if (this.itineraryDetailIda.limite_reserva && this.itineraryDetailIda.limite_reserva != 0) this.seat_limit = this.itineraryDetailIda.limite_reserva;
              this.primerPisoIda = this.itineraryDetailIda.bus ? this.itineraryDetailIda.bus.level_1 : undefined;
              this.segundoPisoIda = this.itineraryDetailIda.bus ? this.itineraryDetailIda.bus.level_2 : undefined;

              if (this.primerPisoIda && this.primerPisoIda.elements) {
                for (let i = 0; i < this.primerPisoIda.elements.length; i++) {
                  const asiento = this.primerPisoIda.elements[i];
                  asiento.IDENTIFICADOR = uuid();
                  asiento.PRODUCT_CODE = 'DEPART_' + asiento.piso + '_' + asiento.asiento
                  asiento.selected = false;
                }
              }

              if (this.segundoPisoIda && this.segundoPisoIda.elements) {
                for (let i = 0; i < this.segundoPisoIda.elements.length; i++) {
                  const asiento = this.segundoPisoIda.elements[i];
                  asiento.IDENTIFICADOR = uuid();
                  asiento.PRODUCT_CODE = 'DEPART_' + asiento.piso + '_' + asiento.asiento
                  asiento.selected = false;
                }
              }

              if (this.shoppingCart != undefined && this.shoppingCart.order_detail.length > 0) {

                for (let i = 0; i < this.shoppingCart.order_detail.length; i++) {
                  const selectedDetail = this.shoppingCart.order_detail[i];

                  if (selectedDetail.product_extended_fields.destination === 'DEPART') {
                    if (selectedDetail.product_extended_fields &&
                      selectedDetail.product_extended_fields.NIVEL_PISO == '1' && this.itineraryDetailIda.bus &&
                      this.itineraryDetailIda.bus.level_1 && this.itineraryDetailIda.bus.level_1.elements &&
                      this.itineraryDetailIda.bus.level_1.elements.length > 1) {

                      let index = lodash.findIndex(this.itineraryDetailIda.bus.level_1.elements, { type: 'asiento', PRODUCT_CODE: selectedDetail.product_code });

                      if (index != -1) {
                        this.itineraryDetailIda.bus.level_1.elements[index].selected = true;
                        this.itineraryDetailIda.bus.level_1.elements[index].bloq_inter = '0';
                      }

                    }

                    if (selectedDetail.product_extended_fields &&
                      selectedDetail.product_extended_fields.NIVEL_PISO == '2' && this.itineraryDetailIda.bus &&
                      this.itineraryDetailIda.bus.level_2 && this.itineraryDetailIda.bus.level_2.elements &&
                      this.itineraryDetailIda.bus.level_2.elements.length > 1) {
                      let index = lodash.findIndex(this.itineraryDetailIda.bus.level_2.elements, { type: 'asiento', PRODUCT_CODE: selectedDetail.product_code });

                      if (index != -1) {
                        this.itineraryDetailIda.bus.level_2.elements[index].selected = true;
                        this.itineraryDetailIda.bus.level_2.elements[index].bloq_inter = '0';
                      }

                    }
                    this.seatSelectedIdaList.push(selectedDetail);
                  }

                }
              }

              this.progressBar.list_page = false;
              this.progressbarService.setVisibility(this.progressBar.list_page);
            } else {
              this.messageErrorDepart = response.header.message_description;
            }

          },
          (error) => {
            this.messageErrorDepart = error.description;
            this.progressBar.list_page = false;
            this.progressbarService.setVisibility(this.progressBar.list_page);
          }
        );

      } else {
        // NO PENDING IMPLEMENTATION
      }
    } else {
      this.messageErrorDepart = 'No se encontraron datos para la consulta de asientos disponibles';
    }
  }

  getSeatListReturn() {

    if (this.seat_list_return) {

      this.messageErrorReturn = undefined;

      if (this.seat_list_return.value == 'API') {

        let spec: any = lodash.find(this.seat_list_return.specs, { value: this.seat_list_return.value });

        let entity: any = {};
        entity.programacionLlave = this.shoppingCart.extended_fields['id_programming_return'];
        this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
          (response: any) => {

            if (response.header.status) {
              this.itineraryDetailRetorno = Object.assign(response.body[0], ItineraryDetailModel);

              this.primerPisoRetorno = this.itineraryDetailRetorno.bus ? this.itineraryDetailRetorno.bus.level_1 : undefined;
              this.segundoPisoRetorno = this.itineraryDetailRetorno.bus ? this.itineraryDetailRetorno.bus.level_2 : undefined;

              if (this.primerPisoRetorno && this.primerPisoRetorno.elements) {
                for (let i = 0; i < this.primerPisoRetorno.elements.length; i++) {
                  const asiento = this.primerPisoRetorno.elements[i];
                  asiento.IDENTIFICADOR = uuid();
                  asiento.PRODUCT_CODE = 'RETURN_' + asiento.piso + '_' + asiento.asiento

                }
              }

              if (this.segundoPisoRetorno && this.segundoPisoRetorno.elements) {
                for (let i = 0; i < this.segundoPisoRetorno.elements.length; i++) {
                  const asiento = this.segundoPisoRetorno.elements[i];
                  asiento.IDENTIFICADOR = uuid();
                  asiento.PRODUCT_CODE = 'RETURN_' + asiento.piso + '_' + asiento.asiento

                }
              }

              if (this.shoppingCart != undefined && this.shoppingCart.order_detail.length > 0) {
                for (let i = 0; i < this.shoppingCart.order_detail.length; i++) {
                  const selectedDetail = this.shoppingCart.order_detail[i];
                  console.log(selectedDetail.product_extended_fields.destination);
                  if (selectedDetail.product_extended_fields.destination === 'RETURN') {
                    if (selectedDetail.product_extended_fields &&
                      selectedDetail.product_extended_fields.NIVEL_PISO == '1' && this.itineraryDetailRetorno.bus &&
                      this.itineraryDetailRetorno.bus.level_1 && this.itineraryDetailRetorno.bus.level_1.elements &&
                      this.itineraryDetailRetorno.bus.level_1.elements.length > 1) {
                      let index = lodash.findIndex(this.itineraryDetailRetorno.bus.level_1.elements, { type: 'asiento', PRODUCT_CODE: selectedDetail.product_code });

                      if (index != -1) {
                        this.itineraryDetailRetorno.bus.level_1.elements[index].selected = true;
                        this.itineraryDetailRetorno.bus.level_1.elements[index].bloq_inter = '0';
                      }
                    }

                    if (selectedDetail.product_extended_fields &&
                      selectedDetail.product_extended_fields.NIVEL_PISO == '2' && this.itineraryDetailRetorno.bus
                      && this.itineraryDetailRetorno.bus.level_2 && this.itineraryDetailRetorno.bus.level_2.elements &&
                      this.itineraryDetailRetorno.bus.level_2.elements.length > 1) {
                      let index = lodash.findIndex(this.itineraryDetailRetorno.bus.level_2.elements, { type: 'asiento', PRODUCT_CODE: selectedDetail.product_code });

                      if (index != -1) {
                        this.itineraryDetailRetorno.bus.level_2.elements[index].selected = true;
                        this.itineraryDetailRetorno.bus.level_2.elements[index].bloq_inter = '0'
                      }

                    }
                    this.seatSelectedRetornoList.push(selectedDetail);
                  }

                }

              }
            } else {
              this.messageErrorReturn = response.header.message_description;
            }

          },
          (error) => {
            this.messageErrorReturn = error.description;
          }
        );

      } else {
        // NO PENDING IMPLEMENTATION
      }
    } else {
      this.messageErrorReturn = 'No se encontraron datos para la consulta de asientos disponibles';
    }

  }

  selectSeatDepart(seatSelected: AsientoModel) {

    this.messageErrorSeatLimit = undefined;
    this.spinnerService.show();
    if (seatSelected.selected) {

      if (this.lock_seat) {
        if (this.lock_seat.value = 'API') {
          if (this.seatSelectedIdaList.length < this.seat_limit) {

            this.maxSeatDepartLimit = false;
            let spec: any = lodash.find(this.lock_seat.specs, { value: this.lock_seat.value });
            // Block Seats
            let entity: any = {};
            entity.order_id = this.shoppingCart.order_id;
            entity.product_code = seatSelected.PRODUCT_CODE;
            entity.codigoAsiento = seatSelected.asiento;
            entity.nivelUnidad = seatSelected.nivel;
            entity.tipoTarifa = seatSelected.tarifa_descripcion;
            entity.ciudadOrigen = this.shoppingCart.extended_fields['cds_origin'];
            entity.ciudadDestino = this.shoppingCart.extended_fields['cds_destination'];
            entity.localidadEmbarque = this.shoppingCart.extended_fields['cds_origin_code'];
            entity.localidadDesembarque = this.shoppingCart.extended_fields['cds_destination_code'];
            entity.numeroServicio = this.shoppingCart.extended_fields['cds_service_number_depart'];
            entity.programacionLlave = this.shoppingCart.extended_fields['cds_key_programming_depart'];
            entity.servicioLlave = this.shoppingCart.extended_fields['cds_service_key_depart'];
            entity.codigoRuta = this.shoppingCart.extended_fields['cds_code_ruta_depart'];
            entity.unidadLlave = this.shoppingCart.extended_fields['cds_unit_key_depart'];
            entity.agenciaEmbarqueLlave = this.key_agency_departIda;
            entity.agenciaDesembarqueLlave = this.key_agency_arrivalIda;

            this.checkoutService.callAdapter(spec.adapter_save, entity).subscribe(
              (response: any) => {
                if (response.header.status) {
                  seatSelected.codigoTransaccion = response.body[0].transaction_code;
                  // seatSelected.bloq_inter = '1';
                  let detailTemp = new ShoppingCartDetailModel();
                  detailTemp.product_code = seatSelected.PRODUCT_CODE;
                  detailTemp.amount_total = parseInt(seatSelected.precio);
                  detailTemp.product_extended_fields = {
                    ASIENTO: seatSelected.asiento,
                    NIVEL_PISO: seatSelected.nivel,
                    codigoTransaccion: seatSelected.codigoTransaccion,
                    TIPO_TARIFA: seatSelected.tarifa_descripcion,
                    destination: 'DEPART'
                  };
                  this.shoppingCart.order_detail.push(detailTemp);
                  this.seatSelectedIdaList.push(detailTemp);
                  this.shoppingCart.total_amount += detailTemp.amount_total;
                  this.spinnerService.hide();
                } else {
                  seatSelected.selected = !seatSelected.selected;
                  this.messageErrorSeat = response.header.message_description;
                  $('#modalMessage').modal('show');
                  this.spinnerService.hide();
                }
              },
              (error) => {
                seatSelected.selected = !seatSelected.selected;
                this.messageErrorSeat = 'Hubo un inconveniente al bloquear el asiento. Intentalo nuevamente por favor';
                $('#modalMessage').modal('show');
                this.spinnerService.hide();
              }
            );
          } else {
            this.spinnerService.hide();
            seatSelected.selected = !seatSelected.selected;
            this.maxSeatDepartLimit = true;
            this.messageErrorSeatLimit = 'Ha alcanzado el número máximo de asientos por ruta';
            $('#modalMessageLimit').modal('show');
          }
        }
      }
    } else {
      if (this.unlock_seat) {
        if (this.unlock_seat.value = 'API') {

          this.maxSeatDepartLimit = false;
          let spec: any = lodash.find(this.unlock_seat.specs, { value: this.unlock_seat.value });
          //  Unlock seat
          let entity: any = {};
          entity.order_id = this.shoppingCart.order_id;
          entity.product_code = seatSelected.PRODUCT_CODE;

          this.checkoutService.callAdapter(spec.adapter_save, entity).subscribe(
            (response: any) => {
              if (response.header.status) {
                let seatIndex = lodash.findIndex(this.shoppingCart.order_detail, { product_code: seatSelected.PRODUCT_CODE });
                let seatSelectedIndex = lodash.findIndex(this.seatSelectedIdaList, { product_code: seatSelected.PRODUCT_CODE });
                // $('#btnDeleteProductDepart_' + seatSelectedIndex + ' > .svg-icon-spinner').removeClass('d-none');
                // $('#dcard_bus_detail_depart_' + seatSelectedIndex).addClass('animated fadeOut');
                // seatSelected.bloq_inter = '0';
                seatSelected.codigoTransaccion = '';
                this.shoppingCart.total_amount -= this.shoppingCart.order_detail[seatIndex].amount_total;
                this.shoppingCart.order_detail.splice(seatIndex, 1);
                this.seatSelectedIdaList.splice(seatSelectedIndex, 1);
                this.spinnerService.hide();
              } else {
                seatSelected.selected = !seatSelected.selected;
                this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
                $('#modalMessage').modal('show');
                this.spinnerService.hide();
              }

            },
            (error) => {
              seatSelected.selected = !seatSelected.selected;
              this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
              $('#modalMessage').modal('show');
              this.spinnerService.hide();
            }
          );
        }
      }
    }
  }

  selectSeatReturn(seatSelected: AsientoModel) {
    console.log(seatSelected);
    this.messageErrorSeatLimit = undefined;
    this.spinnerService.show();
    if (seatSelected.selected) {

      if (this.lock_seat) {
        if (this.lock_seat.value = 'API') {
          if (this.seatSelectedRetornoList.length < this.seat_limit) {

            this.maxSeatReturnLimit = false;
            let spec: any = lodash.find(this.lock_seat.specs, { value: this.lock_seat.value });

            // Block Seats
            let entity: any = {};
            entity.order_id = this.shoppingCart.order_id;
            entity.product_code = seatSelected.PRODUCT_CODE;
            entity.codigoAsiento = seatSelected.asiento;
            entity.nivelUnidad = seatSelected.nivel;
            entity.tipoTarifa = seatSelected.tarifa_descripcion;
            entity.ciudadOrigen = this.shoppingCart.extended_fields['cds_destination'];
            entity.ciudadDestino = this.shoppingCart.extended_fields['cds_origin'];
            entity.localidadEmbarque = this.shoppingCart.extended_fields['cds_destination_code'];
            entity.localidadDesembarque = this.shoppingCart.extended_fields['cds_origin_code'];
            entity.numeroServicio = this.shoppingCart.extended_fields['cds_service_number_return'];
            entity.programacionLlave = this.shoppingCart.extended_fields['cds_key_programming_return'];
            entity.servicioLlave = this.shoppingCart.extended_fields['cds_service_key_return'];
            entity.codigoRuta = this.shoppingCart.extended_fields['cds_code_ruta_return'];
            entity.unidadLlave = this.shoppingCart.extended_fields['cds_unit_key_return'];
            entity.agenciaEmbarqueLlave = this.shoppingCart.extended_fields['cds_boarding_agency_return'];
            entity.agenciaDesembarqueLlave = this.agenciesListArrivalRetorno[0].agency_key;

            this.checkoutService.callAdapter(spec.adapter_save, entity).subscribe(
              (response: any) => {
                if (response.header.status) {
                  seatSelected.codigoTransaccion = response.body[0].transaction_code;
                  // this.itineraryDetailRetorno[i].DISPONIBLE = 2;
                  let detailTemp = new ShoppingCartDetailModel();
                  detailTemp.product_code = seatSelected.PRODUCT_CODE;
                  detailTemp.amount_total = parseInt(seatSelected.precio);
                  detailTemp.product_extended_fields = {
                    ASIENTO: seatSelected.asiento,
                    NIVEL_PISO: seatSelected.nivel,
                    codigoTransaccion: seatSelected.codigoTransaccion,
                    TIPO_TARIFA: seatSelected.tarifa_descripcion,
                    destination: 'RETURN'
                  };
                  this.shoppingCart.total_amount += detailTemp.amount_total;
                  this.shoppingCart.order_detail.push(detailTemp);
                  this.seatSelectedRetornoList.push(detailTemp);
                  this.spinnerService.hide();
                } else {
                  seatSelected.selected = !seatSelected.selected;
                  this.messageErrorSeat = response.header.message_description;
                  $('#modalMessage').modal('show');
                  this.spinnerService.hide();
                }
              },
              (error) => {
                seatSelected.selected = !seatSelected.selected;
                this.messageErrorSeat = 'Hubo un inconveniente al bloquear el asiento. Intentalo nuevamente por favor';
                $('#modalMessage').modal('show');
                this.spinnerService.hide();
              }
            );
          } else {
            seatSelected.selected = !seatSelected.selected;
            this.maxSeatReturnLimit = true;
            this.spinnerService.hide();
            this.messageErrorSeatLimit = 'Ha alcanzado el número máximo de asientos por ruta';
            $('#modalMessageLimit').modal('show');
          }
        }
      }

    } else {
      if (this.unlock_seat) {
        if (this.unlock_seat.value = 'API') {

          this.maxSeatReturnLimit = false;
          let spec: any = lodash.find(this.unlock_seat.specs, { value: this.unlock_seat.value });

          //  Unlock seat
          let entity: any = {};
          entity.order_id = this.shoppingCart.order_id;
          entity.product_code = seatSelected.PRODUCT_CODE;

          this.checkoutService.callAdapter(spec.adapter_save, entity).subscribe(
            (response: any) => {
              if (response.header.status) {
                let seatIndex = lodash.findIndex(this.shoppingCart.order_detail, { product_code: seatSelected.PRODUCT_CODE });
                let seatSelectedIndex = lodash.findIndex(this.seatSelectedRetornoList, { product_code: seatSelected.PRODUCT_CODE });
                // $('#btnDeleteProductReturn_' + seatSelectedIndex + ' > .svg-icon-spinner').removeClass('d-none');
                // $('#dcard_bus_detail_return_' + seatSelectedIndex).addClass('animated fadeOut');
                // seatSelected.bloq_inter = '0';
                seatSelected.codigoTransaccion = '';
                this.shoppingCart.total_amount -= this.shoppingCart.order_detail[seatIndex].amount_total;
                this.shoppingCart.order_detail.splice(seatIndex, 1);
                this.seatSelectedRetornoList.splice(seatSelectedIndex, 1);
                this.spinnerService.hide();
              } else {
                seatSelected.selected = !seatSelected.selected;
                this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
                $('#modalMessage').modal('show');
                this.spinnerService.show();
              }
            },
            (error) => {
              seatSelected.selected = !seatSelected.selected;
              this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
              $('#modalMessage').modal('show');
              this.spinnerService.hide();
            }
          );
        }
      }
    }
  }

  deleteSeatDepart(index: number) {

    let btn = $('#btnDeleteProductDepart_' + index);
    if (btn.data('isbusy') === false) {
      btn.data('isbusy', true);
      $('#btnDeleteProductDepart_' + index).addClass('dfont-white btn-disabled');
      $('#btnDeleteProductDepart_' + index + ' > .svg-icon-spinner').removeClass('d-none');

      this.spinnerService.show();

      if (this.unlock_seat) {
        if (this.unlock_seat.value = 'API') {

          let spec: any = lodash.find(this.unlock_seat.specs, { value: this.unlock_seat.value });

          //  Unlock seat
          let entity: any = {};
          entity.order_id = this.shoppingCart.order_id;
          entity.product_code = this.itineraryDetailIda[index].PRODUCT_CODE;


          if (this.shoppingCart.order_detail[index].product_extended_fields && this.shoppingCart.order_detail[index].product_extended_fields.NIVEL_PISO == '1') {

          }

          // this.checkoutService.callAdapter(spec.adapter_save, entity).subscribe(
          //   (response: any) => {
          //     if (response.header.status) {
          //       $('#dcard_bus_detail_depart_' + index).addClass('animated fadeOut');
          //       // this.itineraryDetailIda[index].DISPONIBLE = 1;
          //       this.itineraryDetailIda[index].codigoTransaccion = '';
          //       let seatIndex = lodash.findIndex(this.shoppingCart.order_detail, { product_code: this.itineraryDetailIda[indexAsientoIda].PRODUCT_CODE });
          //       let seatSelectedIndex = lodash.findIndex(this.seatSelectedIdaList, { product_code: this.itineraryDetailIda[indexAsientoIda].PRODUCT_CODE });
          //       this.shoppingCart.order_detail.splice(seatIndex, 1);
          //       this.seatSelectedIdaList.splice(seatSelectedIndex, 1);
          //       this.spinnerService.hide();

          //     } else {
          //       this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
          //       $('#modalMessage').modal('show');
          //       this.spinnerService.hide();
          //     }

          //   },
          //   (error) => {
          //     this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
          //     $('#modalMessage').modal('show');
          //     this.spinnerService.hide();
          //   }
          // );
        }
      }
    }

  }

  deleteSeatReturn(index: number) {

    let btn = $('#btnDeleteProductReturn_' + index);
    if (btn.data('isbusy') === false) {
      btn.data('isbusy', true);

      $('#btnDeleteProductReturn_' + index).addClass('dfont-white btn-disabled');
      $('#btnDeleteProductReturn_' + index + ' > .svg-icon-spinner').removeClass('d-none');

      let indexAsientoRetorno = -1;//lodash.findIndex(this.itineraryDetailRetorno, { PRODUCT_CODE: this.seatSelectedRetornoList[index].product_code });

      if (this.itineraryDetailRetorno[indexAsientoRetorno].DISPONIBLE != 0) {

        this.spinnerService.show();

        // Remove detail from order_detail
        if (this.itineraryDetailRetorno[indexAsientoRetorno].DISPONIBLE == 2 && this.itineraryDetailRetorno[indexAsientoRetorno].ELEMENTO == 'ASIENTO PAX') {

          if (this.unlock_seat) {
            if (this.unlock_seat.value = 'API') {

              let spec: any = lodash.find(this.unlock_seat.specs, { value: this.unlock_seat.value });

              //  Unlock seat
              let entity: any = {};
              entity.order_id = this.shoppingCart.order_id;
              entity.product_code = this.itineraryDetailRetorno[indexAsientoRetorno].PRODUCT_CODE;

              this.checkoutService.callAdapter(spec.adapter_save, entity).subscribe(
                (response: any) => {
                  if (response.header.status) {
                    $('#dcard_bus_detail_return_' + index).addClass('animated fadeOut');
                    this.itineraryDetailRetorno[indexAsientoRetorno].DISPONIBLE = 1;
                    this.itineraryDetailRetorno[indexAsientoRetorno].codigoTransaccion = '';
                    let seatIndex = lodash.findIndex(this.shoppingCart.order_detail, { product_code: this.itineraryDetailRetorno[indexAsientoRetorno].PRODUCT_CODE });
                    let seatSelectedIndex = lodash.findIndex(this.seatSelectedRetornoList, { product_code: this.itineraryDetailRetorno[indexAsientoRetorno].PRODUCT_CODE });
                    this.shoppingCart.order_detail.splice(seatIndex, 1);
                    this.seatSelectedRetornoList.splice(seatSelectedIndex, 1);
                    this.spinnerService.hide();

                  } else {
                    this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
                    $('#modalMessage').modal('show');
                    this.spinnerService.hide();
                  }

                },
                (error) => {
                  this.messageErrorSeat = 'Hubo un inconveniente al desbloquear el asiento. Intentalo nuevamente por favor';
                  $('#modalMessage').modal('show');
                  this.spinnerService.hide();
                }
              );
            }
          }

        }

      }
    }

  }

  calc(item1, item2) {
    return item1 - item2;
  }

  redirectToCart() {

    this.messageErrorSeat = undefined;
    let btn = $('#btnSeat');
    if (btn.data('isbusy') === false) {
      btn.data('isbusy', true);
      this.progressButton.seat = true;
      if (this.hasReturn == true && this.shoppingCart.order_detail.length > 0) {

        let hasSelectedDepart = lodash.filter(this.shoppingCart.order_detail, ((detail) => {
          return detail.product_extended_fields['destination'] == 'DEPART';
        }));
        let hasSelectedReturn = lodash.filter(this.shoppingCart.order_detail, ((detail) => {
          return detail.product_extended_fields['destination'] == 'RETURN';
        }));

        if (hasSelectedDepart.length > 0 && hasSelectedReturn.length == 0) {
          this.messageErrorSeat = 'No seleccionó asientos de retorno ¿Desea continuar sin itinerario de retorno?';
          $('#modalMessageConfirm').modal('show');
          btn.data('isbusy', false);
          this.progressButton.seat = false;
        } else if (hasSelectedReturn.length > 0 && hasSelectedDepart.length == 0) {
          this.messageErrorSeat = 'No seleccionó asientos de ida ¿Desea continuar sin itinerario de ida?';
          $('#modalMessageConfirm').modal('show');
          btn.data('isbusy', false);
          this.progressButton.seat = false;
        } else if (hasSelectedReturn.length != hasSelectedDepart.length) {
          this.messageErrorSeat = 'No seleccionó la misma cantidad de asientos para su viaje de ida y de retorno';
          $('#modalMessageConfirm').modal('show');
          btn.data('isbusy', false);
          this.progressButton.seat = false;
        } else {
          this.router.navigate(['checkout/cart']);
        }

      } else if (this.shoppingCart.order_detail.length == 0) {
        btn.data('isbusy', false);
        this.progressButton.seat = false;
        this.messageErrorSeat = 'Por favor seleccionar uno o más asientos para poder continuar';
        $('#modalMessage').modal('show');
      } else {
        this.router.navigate(['checkout/cart']);
      }
    }

  }

  redirectDirectToCart() {
    let btn = $('#btnSeat');
    if (btn.data('isbusy') === false) {
      this.spinnerService.show();
      btn.data('isbusy', true);
      this.progressButton.seat = true;
      $('#modalMessageConfirm').modal('hide');
      this.spinnerService.hide();
      this.router.navigate(['checkout/cart']);
    }
  }

  selectMissingDestination() {
    let hasSelectedDepart = lodash.filter(this.shoppingCart.order_detail, ((detail) => {
      return detail.product_extended_fields['destination'] == 'DEPART';
    }));
    let hasSelectedReturn = lodash.filter(this.shoppingCart.order_detail, ((detail) => {
      return detail.product_extended_fields['destination'] == 'RETURN';
    }));
    if (hasSelectedDepart.length == 0) {
      $('#nav-seat-depart').addClass('show active');
      $('#nav-seat-depart-tab').addClass('active');
      $('#nav-seat-return').removeClass('show active');
      $('#nav-seat-return-tab').removeClass('active');
    }
    if (hasSelectedReturn.length == 0) {
      $('#nav-seat-depart').removeClass('show active');
      $('#nav-seat-depart-tab').removeClass('active');
      $('#nav-seat-return').addClass('show active');
      $('#nav-seat-return-tab').addClass('active');
    }
    $('#modalMessageConfirm').modal('hide');
  }

  reloadPage() {
    window.location.reload(true);
  }

  getColorTypeTafifa(type: string) {
    let color = ''

    switch (type) {
      case '03':
        color = '#ff0000';
        break;
      case '04':
        color = '#00C';
        break;
      case '06':
        color = '#f79c15';
        break;
      case '07':
        color = '#227351';
        break;
      case '99':
        color = '#212529';
        break;
      default:
        console.log('type => ', type);
        color = '#c5def2';
        // color = '#59937b';
        break;
    }

    return color;
  }

}

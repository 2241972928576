import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyModel } from '../../models/company';

// import { StorageUtil } from '../../utils/storage-util';
// import { EnumType } from '../../models/enums/enum';
// import { environment } from '../../../environments/environment';

import { Subscription } from 'rxjs/Subscription';
import { CheckoutService } from '../../services/checkout/checkout.service';
import { ProgressBarService } from '../../services/progress-bar/progress-bar.service';
import { EcommerceParametersModel } from '../../models/ecommerce-parameters';

const pkg = require('../../../../package.json');

@Component({
	selector: 'app-master',
	templateUrl: './master.page.html',
	styleUrls: ['./master.page.scss']
})
export class MasterPageComponent implements OnInit {

	company: CompanyModel = new CompanyModel();
	subscription: Subscription;

	hideArrow: boolean = true;
	progressBar: boolean = true;
	previous_route: string = '';

	version_app: string = '0.0.0';

	ecommerce_parameter: EcommerceParametersModel = new EcommerceParametersModel();

	constructor(
		private router: Router,
		private checkoutService: CheckoutService,
		private progressbarService: ProgressBarService
	) {
		this.ecommerce_parameter = this.checkoutService.getEcommerceParameters();
		this.progressbarService.visibilityChange.subscribe((value) => {
			this.progressBar = value;
		});
	}

	ngOnInit() {
		this.progressbarService.visibilityChange.subscribe((value) => {
			this.progressBar = value;
		});
		this.version_app = pkg.version ? ('v' + pkg.version) : '';

		this.initCustomCss();
		this.initCompany();
	}

	setRouteBack(message) {

		if (message.text && message.text == "hiden") {
			this.hideArrow = true;
		} else {
			this.hideArrow = false;
			this.previous_route = message.text;
		}
	}

	goBack() {
		this.router.navigate([this.previous_route]);
	}

	initCompany() {

		if (this.ecommerce_parameter && this.ecommerce_parameter.company_data) {
			this.company.address = ' ';
			this.company.logo = this.ecommerce_parameter.company_data.logo;
			this.company.icono_phone = this.ecommerce_parameter.company_data.icono_phone;
			this.company.call_center_phone = this.ecommerce_parameter.company_data.text_phone;
			this.company.contact_mail = this.ecommerce_parameter.company_data.text_mail;
			this.company.icono_mail = this.ecommerce_parameter.company_data.icono_mail;
		}

	}

	initCustomCss() {
		let bodyStyle = document.body.style;
		// Test
		bodyStyle.setProperty('--main-bg-color', '#C4B8A8');
		bodyStyle.setProperty('--button-primary-bg-color', 'red');
		// Site Header
		bodyStyle.setProperty('--site-header-background-color', '#ffffff');
		bodyStyle.setProperty('--site-header-border-bottom-color', '#d1d1d1');
		// Site Content
		bodyStyle.setProperty('--site-content-background-color', '#f1f1f1');
		// Site Footer
		bodyStyle.setProperty('--site-footer-background-color', '#ffffff');
		bodyStyle.setProperty('--site-footer-border-top-color', '#d1d1d1');
		// Font
		bodyStyle.setProperty('--font-size-main', '0.9em');
		bodyStyle.setProperty('--font-size-control', '1em');
		// bodyStyle.setProperty('--color-danger', 'green');
		// bodyStyle.setProperty('--color-warning', 'purple');
		// Button
		bodyStyle.setProperty('--button-font-color', '#ffffff');
		bodyStyle.setProperty('--button-alternative-font-color', '#993399');
		// Button Primary
		bodyStyle.setProperty('--button-primary-background-color', '#993399');
		bodyStyle.setProperty('--button-primary-border-color', '#993399');
		bodyStyle.setProperty('--button-primary-active-background-color', '#9c009c');
		bodyStyle.setProperty('--button-primary-active-border-color', '#9c009c');
		// Button Transparent
		bodyStyle.setProperty('--button-transparent-background-color', 'transparent');
		bodyStyle.setProperty('--button-transparent-border-color', 'transparent');
		bodyStyle.setProperty('--button-transparent-active-background-color', 'transparent');
		bodyStyle.setProperty('--button-transparent-active-border-color', 'transparent');
		// Button Only Border
		bodyStyle.setProperty('--button-only-border-background-color', '#ffffff');
		bodyStyle.setProperty('--button-only-border-border-color', '#993399');
		bodyStyle.setProperty('--button-only-border-active-background-color', '#f9f9f9');
		bodyStyle.setProperty('--button-only-border-active-border-color', '#9c009c');
		// Form Control
		bodyStyle.setProperty('--form-control-focus-border-color', 'rgb(138, 146, 156)');
		// Card
		bodyStyle.setProperty('--dcard-background-color', '#ffffff');
		bodyStyle.setProperty('--dcard-border', '1px solid transparent');

		if (this.ecommerce_parameter) {
			// button primary
			bodyStyle.setProperty('--button-primary-border-color', this.ecommerce_parameter.color_buttons.primary.border);
			bodyStyle.setProperty('--button-primary-background-color', this.ecommerce_parameter.color_buttons.primary.background);
			bodyStyle.setProperty('--button-primary-active-border-color', this.ecommerce_parameter.color_buttons.primary.active_border);
			bodyStyle.setProperty('--button-primary-active-background-color', this.ecommerce_parameter.color_buttons.primary.active_background);
			bodyStyle.setProperty('--button-font-color', this.ecommerce_parameter.color_buttons.primary.font);
			// button secondary
			bodyStyle.setProperty('--button-only-border-border-color', this.ecommerce_parameter.color_buttons.secondary.border);
			bodyStyle.setProperty('--button-only-border-background-color', this.ecommerce_parameter.color_buttons.secondary.background);
			bodyStyle.setProperty('--button-only-border-active-border-color', this.ecommerce_parameter.color_buttons.secondary.active_border);
			bodyStyle.setProperty('--button-only-border-active-background-color', this.ecommerce_parameter.color_buttons.secondary.active_background);
			bodyStyle.setProperty('--button-alternative-font-color', this.ecommerce_parameter.color_buttons.secondary.font);
			// button third
			bodyStyle.setProperty('--button-danger-border-color', this.ecommerce_parameter.color_buttons.third.border);
			bodyStyle.setProperty('--button-danger-background-color', this.ecommerce_parameter.color_buttons.third.background);
			bodyStyle.setProperty('--button-danger-active-border-color', this.ecommerce_parameter.color_buttons.third.active_border);
			bodyStyle.setProperty('--button-danger-active-background-color', this.ecommerce_parameter.color_buttons.third.active_background);
			bodyStyle.setProperty('--button-danger-font-color', this.ecommerce_parameter.color_buttons.third.font);
			// seats
			bodyStyle.setProperty('--seat-depart-background-color', this.ecommerce_parameter.css.seat_depart_tab.background);
			bodyStyle.setProperty('--seat-depart-border-bottom-color', this.ecommerce_parameter.css.seat_depart_tab.border);
			bodyStyle.setProperty('--seat-depart-font-color', this.ecommerce_parameter.css.seat_depart_tab.font);
			bodyStyle.setProperty('--seat-depart-active-background-color', this.ecommerce_parameter.css.seat_depart_tab.active_background);
			bodyStyle.setProperty('--seat-depart-active-border-bottom-color', this.ecommerce_parameter.css.seat_depart_tab.active_border);
			bodyStyle.setProperty('--seat-depart-active-font-color', this.ecommerce_parameter.css.seat_depart_tab.active_font);
			bodyStyle.setProperty('--seat-return-background-color', this.ecommerce_parameter.css.seat_return_tab.background);
			bodyStyle.setProperty('--seat-return-border-bottom-color', this.ecommerce_parameter.css.seat_return_tab.border);
			bodyStyle.setProperty('--seat-return-font-color', this.ecommerce_parameter.css.seat_return_tab.font);
			bodyStyle.setProperty('--seat-return-active-background-color', this.ecommerce_parameter.css.seat_return_tab.active_background);
			bodyStyle.setProperty('--seat-return-active-border-bottom-color', this.ecommerce_parameter.css.seat_return_tab.active_border);
			bodyStyle.setProperty('--seat-return-active-font-color', this.ecommerce_parameter.css.seat_return_tab.active_font);
		}
	}

	reloadPage() {
		window.location.reload(true);
	}

}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es-PE';
registerLocaleData(localeEs);

// Routing
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Pages
import { AppComponent } from './app.component';
import { MasterPageComponent } from './pages/master/master.page';
import { CheckoutDetailPageComponent } from './pages/checkout/checkout-detail/checkout-detail.page';
import { CheckoutShippingPageComponent } from './pages/checkout/checkout-shipping/checkout-shipping/checkout-shipping.page';
import { CheckoutShippingAddressPageComponent } from './pages/checkout/checkout-shipping/checkout-shipping-address/checkout-shipping-address.page';
import { CheckoutCartPageComponent } from './pages/checkout/checkout-cart/checkout-cart.page';
import { CheckoutDetectionPageComponent } from './pages/checkout/checkout-detection/checkout-detection.page';
import { CheckoutCustomerPageComponent } from './pages/checkout/checkout-customer/checkout-customer.page';
import { CheckoutPaymentDocumentPageComponent } from './pages/checkout/checkout-payment-document/checkout-payment-document.page';
import { CheckoutShippingStorePageComponent } from './pages/checkout/checkout-shipping/checkout-shipping-store/checkout-shipping-store.page';
import { CheckoutPaymentMethodPageComponent } from './pages/checkout/checkout-payment-method/checkout-payment-method.page';
import { CheckoutStepPaymentConfirmationPageComponent } from './pages/checkout/checkout-payment-confirmation/checkout-payment-confirmation.page';
import { CheckoutDeliveryTimePageComponent } from './pages/checkout/checkout-delivery-time/checkout-delivery-time.page';
import { CheckoutSeatAssignmentPageComponent } from './pages/checkout/checkout-seat-assignment/checkout-seat-assignment.component';
import { NotFoundPageComponent } from './pages/not-found/not-found.page';
import { StatusServicePageComponent } from './pages/status-service/status-service.page';

// Pipes
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import { LabelTranslatorPipe } from './pipes/label-translator/label-translator.pipe';

// components
import { AuthComponent } from './auth/component/auth.component';
import { TemplateLoadingComponent } from './components/template-loading/template-loading.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

// External Modules
import { MatProgressBarModule } from '@angular/material/progress-bar';

//services
import { SendMessagesService } from './services/send-messages/send-messages.service';
import { CheckoutService } from './services/checkout/checkout.service';
import { StoreService } from './services/store/store.service';
import { AuthService } from './auth/services/auth/auth.service';
import { MetadataService } from './services/metadata/metadata.service';
import { EcommerceRulesService } from './services/ecommerce-rules/ecommerce-rules.service';
import { IntegrationsVisaService } from './services/integrations-visa/integrations-visa.service';
import { IntegrationsMasterCardService } from './services/integrations-master-card/integrations-master-card.service';
import { IntegrationsPagoEfectivoService } from './services/integrations-pago-efectivo/integrations-pago-efectivo.service';
import { ProgressBarService } from './services/progress-bar/progress-bar.service';
import { EcommerceFieldTemplateService } from './services/ecommerce-field-template/ecommerce-field-template.service';

// Directives
import { IntegersDirective } from './directives/integers/integers.directive';
import { UppercaseDirective } from './directives/uppercase/uppercase.directive';
import { LowercaseDirective } from './directives/lowercase/lowercase.directive';
import { MetadataPageComponent } from './pages/metadata/metadata.page';
import { CheckoutPaymentErrorPageComponent } from './pages/checkout/checkout-payment-error/checkout-payment-error.page';
import { CheckoutSeatAssignmentV2Component } from './pages/checkout/checkout-seat-assignment-v2/checkout-seat-assignment-v2.component';
import { SeatsComponent } from './components/seats/seats.component';

@NgModule({
	declarations: [
		AppComponent,
		MasterPageComponent,
		CheckoutCartPageComponent,
		CheckoutDetectionPageComponent,
		CheckoutPaymentDocumentPageComponent,
		CheckoutShippingStorePageComponent,
		CheckoutPaymentMethodPageComponent,
		CheckoutStepPaymentConfirmationPageComponent,
		SafeUrlPipe,
		AuthComponent,
		NotFoundPageComponent,
		TemplateLoadingComponent,
		IntegersDirective,
		LowercaseDirective,
		UppercaseDirective,
		CheckoutDetailPageComponent,
		CheckoutCustomerPageComponent,
		CheckoutShippingPageComponent,
		CheckoutShippingAddressPageComponent,
		CheckoutDeliveryTimePageComponent,
		CheckoutSeatAssignmentPageComponent,
		MetadataPageComponent,
		LabelTranslatorPipe,
		CheckoutPaymentErrorPageComponent,
		CheckoutSeatAssignmentV2Component,
		SeatsComponent,
		StatusServicePageComponent
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		MatProgressBarModule,
		BsDatepickerModule.forRoot(),
		Ng4LoadingSpinnerModule.forRoot(),
		DeviceDetectorModule.forRoot()
	],
	providers: [
		SafeUrlPipe,
		CheckoutService,
		StoreService,
		AuthService,
		SendMessagesService,
		MetadataService,
		EcommerceRulesService,
		IntegrationsVisaService,
		IntegrationsMasterCardService,
		IntegrationsPagoEfectivoService,
		ProgressBarService,
		EcommerceFieldTemplateService,
		{ provide: LOCALE_ID, useValue: 'es-PE' }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

    <div class="content-title">
        {{ page_title | labelTranslator }}
    </div>

    <div class="dcard p-0">
        <div class="container-fluid">
            <div class="row" [formGroup]="infoFormGroup">
                <div class="col">
                    <div class="form-group">
                        <label for="lblInputInfo_2" class="dfont dfont-bold text-uppercase">{{ 'Nombres' |
                            labelTranslator }} * </label>
                        <div class="input-group">
                            <input id="lblInputInfo_2" type="text" class="form-control" formControlName="name"
                                [(ngModel)]="customer.names" placeholder="Ingresar nombre"
                                [ngClass]="{'is-invalid': infoFormGroup.controls.name.invalid && infoFormGroup.controls.name.dirty}"
                                (keyup.enter)="focusInputField($event)" uppercase>
                            <div class="form-control-input" *ngIf="customer.names != ''"
                                (click)="customer.names = '';focusTarget('#lblInputInfo_2')">
                                <i class="fa fa-lg fa-times-circle  " aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lblInputInfo_3" class="dfont dfont-bold text-uppercase">{{ 'Apellidos' |
                            labelTranslator }} *</label>
                        <div class="input-group">
                            <input id="lblInputInfo_3" type="text" class="form-control" formControlName="lastName"
                                [(ngModel)]="customer.last_names" placeholder="Ingresar apellidos"
                                [ngClass]="{'is-invalid': infoFormGroup.controls.lastName.invalid && infoFormGroup.controls.lastName.dirty}"
                                (keyup.enter)="focusInputField($event)" uppercase>
                            <div class="form-control-input" *ngIf="customer.last_names != ''"
                                (click)="customer.last_names = '';focusTarget('#lblInputInfo_3')">
                                <i class="fa fa-lg fa-times-circle  fa-icon-input" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lblInputInfo_4" class="dfont dfont-bold text-uppercase">{{ 'Tipo de documento' |
                            labelTranslator }}
                            *</label>
                        <select id="lblInputInfo_4" class="form-control" formControlName="documentType"
                            [(ngModel)]="customer.document_type"
                            [ngClass]="{'is-invalid': infoFormGroup.controls.documentType.invalid && infoFormGroup.controls.documentType.dirty}"
                            uppercase>
                            <option value="CARNE_EXTRANJERIA">{{ 'Carné de extranjeria' | labelTranslator }}
                            </option>
                            <option value="DNI" selected>{{ 'DNI' | labelTranslator }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="lblInputInfo_5" class="dfont dfont-bold text-uppercase">{{ 'Número de documento' |
                            labelTranslator }}
                            *</label>
                        <div class="input-group">
                            <input id="lblInputInfo_5" type="text" class="form-control" formControlName="documentNumber"
                                [(ngModel)]="customer.document_number" placeholder="Ingresar documento"
                                [ngClass]="{'is-invalid': infoFormGroup.controls.documentNumber.invalid && infoFormGroup.controls.documentNumber.dirty}"
                                (keyup.enter)="focusInputField($event)" integers>
                            <div class="form-control-input" *ngIf="customer.document_number != ''"
                                (click)="customer.document_number = '';focusTarget('#lblInputInfo_5')">
                                <i class="fa fa-lg fa-times-circle  fa-icon-input" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group pt-1">
                        <label for="lblInputInfo_1" class="dfont dfont-bold text-uppercase">{{ 'Correo electrónico' |
                            labelTranslator }}
                            *</label>
                        <div class="input-group">
                            <input id="lblInputInfo_1" type="text" class="form-control" [(ngModel)]="customer.mail"
                                formControlName="mail" placeholder="Correo"
                                [ngClass]="{'is-invalid': infoFormGroup.controls.mail.invalid && infoFormGroup.controls.mail.dirty}"
                                (keyup.enter)="focusInputField($event)" lowercase>
                            <div class="form-control-input" *ngIf="customer.mail != ''"
                                (click)="customer.mail = '';focusTarget('#lblInputInfo_1')">
                                <i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lblInputInfo_6" class="dfont dfont-bold text-uppercase">{{ 'Teléfono de Contacto' |
                            labelTranslator }}
                            *</label>
                        <div class="input-group">
                            <input id="lblInputInfo_6" type="text" class="form-control" formControlName="phoneNumber"
                                [(ngModel)]="customer.phone_number" placeholder="Ingresar teléfono"
                                [ngClass]="{'is-invalid': infoFormGroup.controls.phoneNumber.invalid && infoFormGroup.controls.phoneNumber.dirty}"
                                (keyup.enter)="focusInputField($event)" integers>
                            <div class="form-control-input" *ngIf="customer.phone_number != ''"
                                (click)="customer.phone_number = '';focusTarget('#lblInputInfo_6')">
                                <i class="fa fa-lg fa-times-circle  fa-icon-input" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div class="row">
            <div class="col p-0">
                <button id="btnSaveCustomer" type="button" class="btn btn-primary btn-block my-2"
                    (click)="validateForm()" [ngClass]="{'btn-disabled': progressButton.process === true}"
                    data-isbusy="false">
                    <div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
                    {{ 'CONTINUAR' | labelTranslator }}
                </button>
            </div>
        </div>
    </div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row">
                    <div class="form-group col-md-12 body-card-pending">
                        <span>{{ messageError | labelTranslator }} </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer mf-center">
                <button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
                    {{ 'CERRAR' | labelTranslator }}
                </button>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class ProgressBarService {

	isVisible: boolean = true;
	visibilityChange: Subject<boolean> = new Subject<boolean>();

	constructor() {
		this.visibilityChange.subscribe((value) => {
			this.isVisible = value
		});
	}

	setVisibility(isVisible: boolean) {
		this.visibilityChange.next(isVisible);
	}

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { ShoppingCartModel } from '../../../models/shopping-cart';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DateUtil } from '../../../utils/date-util';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
import { EcommerceFieldTemplateService } from '../../../services/ecommerce-field-template/ecommerce-field-template.service';
import { EcommerceFieldTemplateModel } from '../../../models/ecommerce-field-template';
import { EnumType } from '../../../models/enums/enum';
declare let MessengerExtensions: any;

@Component({
	selector: 'app-checkout-payment-confirmation',
	templateUrl: './checkout-payment-confirmation.page.html',
	styleUrls: ['./checkout-payment-confirmation.page.scss']
})

export class CheckoutStepPaymentConfirmationPageComponent implements OnInit {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	ecommerceFieldTemplate: EcommerceFieldTemplateModel = new EcommerceFieldTemplateModel();
	bankAccountList: any[] = new Array<any>();
	quantityProducts: number = 0;

	progressBar: any = {
		list_page: true
	};
	progressButton: any = {
		process: false
	};
	isDesktop: any;
	transactionDate: string = '';

	constructor(
		private checkoutService: CheckoutService,
		private deviceService: DeviceDetectorService,
		private router: Router,
		private progressbarService: ProgressBarService,
		private ecommerceFieldService: EcommerceFieldTemplateService
	) {
		// this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'Messenger'));

		console.log('isDesktop=', this.deviceService.isDesktop());
		console.log('isMobile=', this.deviceService.isMobile());
		console.log('deviceInfo=', this.deviceService.getDeviceInfo());
		this.isDesktop = this.deviceService.isDesktop();
	}

	ngOnInit() {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.getOrderDetail();
	}

	getOrderDetail() {

		this.getFieldMap();

		this.progressBar.list_page = false;

		if (this.shoppingCart.order_number == 0 &&
			this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {
			this.shoppingCart.order_detail.forEach(detail => {
				this.quantityProducts += detail.quantity;
			});

			if (this.shoppingCart.payment_method && this.shoppingCart.payment_method.code == 'PMIL') {
				// console.log(this.shoppingCart.payment_method);
				if (this.shoppingCart.payment_method.card && this.shoppingCart.payment_method.card.name == 'Visa') {
					this.transactionDate = DateUtil.getDateNumberFormat(this.shoppingCart.payment_method.visa_net.transaction_authorization.order.transactionDate, 'YYMMDDHHmmss', 'DD/MM/YYYY HH:mm:ss');
				}
			}

			if (this.shoppingCart.payment_method.code == 'PMDB') {
				this.checkoutService.getBankAccountsList().subscribe(
					(response: any) => {
						this.progressBar.list_page = false;
						this.progressbarService.setVisibility(false);
						if (response.header.status) {
							this.bankAccountList = response.body;
						}
					},
					(err) => {
						console.log(err);
					}
				);
			} else {
				this.progressBar.list_page = false;
				this.progressbarService.setVisibility(false);
			}
		}

	}

	closeMyWindow() {
		console.log('Attempting to close window');
		MessengerExtensions.requestCloseBrowser(function success() {
			console.log('Close window');
		}, function error(err) {
			console.log('Error on close window =', err);
		});
	}

	getFieldMap() {
		this.ecommerceFieldService.getFieldMap(EnumType.Pages.CONFIRM).subscribe(
			(response: any) => {
				this.ecommerceFieldTemplate = response.body;
				console.log(response.body);
			},
			(error) => {
				console.log('error => ', error);
			}
		);
	}

}

export class EcommerceFieldTemplateModel {

    page_name: string;
    values: [{
        value: string;
        caps: string;
        css_styles: string;
        css_class: string;
        is_visible: boolean;
        field_type: string;
        field_format: string;
        group_order: any;
        output: string[];
        cssStyles: any;
    }];

    constructor() { }

}
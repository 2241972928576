import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

declare let $: any;
import * as lodash from 'lodash';

import { ShoppingCartModel } from '../../../../models/shopping-cart';
import { CheckoutService } from '../../../../services/checkout/checkout.service';
import { ProgressBarService } from '../../../../services/progress-bar/progress-bar.service';

@Component({
	selector: 'app-checkout-shipping-store',
	templateUrl: './checkout-shipping-store.page.html',
	styleUrls: ['./checkout-shipping-store.page.scss']
})
export class CheckoutShippingStorePageComponent implements OnInit, OnDestroy {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	storelist: any[] = new Array<any>();
	selectedStoreId: string = '';

	progressBar: any = {
		list_page: true
	};

	// ProgressButton
	progressButton: any = {
		process: false
	};

	messageError: string = undefined;

	page_title: string = "";

	constructor(
		private router: Router,
		private checkoutService: CheckoutService,
		private progressbarService: ProgressBarService
	) {
		// this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
		this.selectedStoreId = this.shoppingCart.shipping_method.pick_up.store_id;
	}

	ngOnInit() {

		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {

			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.shipping_store;
			}
			this.getStoreList();
			this.appendModalsToBody();
		}
	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
	}

	getStoreList() {
		this.storelist = this.checkoutService.getStoreList();
		this.progressBar.list_page = false;
		this.progressbarService.setVisibility(false);
	}

	saveStoreSelected() {
		this.progressButton.process = true;
		let btn = $('#btnProcess');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			let store: any = lodash.find(this.storelist, ['store_id', this.selectedStoreId]);
			if (store) {
				this.shoppingCart.shipping_method.type = 'STORE';
				this.shoppingCart.shipping_method.shipping_address._id = '';
				this.shoppingCart.shipping_method.shipping_address.address = '';
				this.shoppingCart.shipping_method.shipping_address.address_id = '';
				this.shoppingCart.shipping_method.shipping_address.department = '';
				this.shoppingCart.shipping_method.shipping_address.district = '';
				this.shoppingCart.shipping_method.shipping_address.province = '';
				this.shoppingCart.shipping_method.shipping_address.receiver = {
					full_name: '',
					document_number: '',
					phone_number: ''
				};
				this.shoppingCart.shipping_method.shipping_address.references = '';
				this.shoppingCart.delivery_time = {
					_id: '',
					date: '',
					schedule: ''
				};
				this.shoppingCart.shipping_method.pick_up = {
					store_id: store.store_id,
					store_name: store.name,
					store_address: store.address
				};

				this.checkoutService.updateShippingStore(this.shoppingCart.shipping_method).subscribe(
					(response: any) => {

						if (response.header.status) {
							this.checkoutService.setShoppingCart(this.shoppingCart);
							this.router.navigate(['/checkout/detail']);
						} else {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}
					},
					(err) => {
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = err.description;
						$('#modalMessage').modal('show');
						console.log('error => ', err);
					}
				);
			} else {
				this.progressButton.process = false;
				btn.data('isbusy', false);
				this.messageError = "Por favor seleccionar una tienda";
				$('#modalMessage').modal('show');
			}
		}


	}

	selectStore(store_id: string) {
		this.selectedStoreId = store_id;
	}


}

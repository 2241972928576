export class ShoppingCartModel {

    sender_id: string;
    order_id: string;
    order_number: number;
    sub_total: number;
    shipping_amount: number;
    igv_amount: number;
    total_amount: number;
    order_detail: ShoppingCartDetailModel[];
    customer: CustomerModel;
    shipping_method: {
        type?: string;
        pick_up?: {
            store_id: string;
            store_name: string;
            store_address: string;
        };
        shipping_address?: ShippingAddressModel;
    };
    delivery_time: ShoppingCartDeliveryTimeModel;

    payment_method: {
        payment_id: string;
        code: string;
        description: string;
        payment_amount: number,
        card: {
            id: string,
            name: string
        },
        visa_net: {
            // merchant_id: string;
            session_Key: string,
            expirationTime: string,
            attempt_number: string,
            merchant_id: string,
            src_script: string,
            timeout_url: string,
            merchant_logo: string,
            created_timestamp: string,
            transaction_authorization: any
        },
        master_card: {
            signature: string,
            transaction_result: any
        },
        summary_object: {
            number_references: string,
            tx_date: string,
            tx_time: string,
            auth_code: string,
            tx_card: string,
            message_response: string
        },
        pago_efectivo: {
            cip_result: any;
            transaction_result: any;
        }

    };
    payment_document: {
        type: string;
        company: string;
        ruc_number: string;
        address: string;
    };
    currency: {
        code: string;
        symbol: string;
    }
    extended_fields: any;
    accepted_policies: boolean;
    status: string;
    created_timestamp: Date;
    updated_timestamp: Date;
    to_pending_timestamp: Date;
    to_accepted_timestamp: Date;
    to_shipped_timestamp: Date;
}


export class ShoppingCartDetailModel {
    _id: string;
    quantity: number;
    name: string;
    description: string;
    description_two: string;
    product_code: string;
    unit_price: number;
    amount_total: number;
    amount_igv: number;
    image: string;
    product_url: string;
    product_extended_fields: any;
    metadata_detail: any;
    category_code: string[];
    added_timestamp: Date
}

export class CustomerModel {
    _id?: string;
    customer_id: string;
    mail: string;
    names: string;
    last_names: string;
    document_type: string;
    document_number: string;
    phone_number: string;
}

export class ShippingAddressModel {
    _id?: string;
    address_id: string;
    address: string;
    department: string;
    province: string;
    district: string;
    references: string;
    receiver: {
        full_name: string;
        document_number: string;
        phone_number: string;
    };
    shipping_time?: string;
}

// TODO: SC
export class ShoppingCartDeliveryTimeModel {
    _id?: string;
    date: string;
    schedule: string;
}
// TODO: LIST
export class DeliveryTimeModel {
    _id?: string;
    date: string;
    schedule: string[];
}

export class CurrencyModel {
    code: string;
    description: string;
    symbol: string;
}
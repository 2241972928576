/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./not-found.page";
var styles_NotFoundPageComponent = [i0.styles];
var RenderType_NotFoundPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundPageComponent, data: {} });
export { RenderType_NotFoundPageComponent as RenderType_NotFoundPageComponent };
export function View_NotFoundPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["style", "border-top: solid 5px #1E4B74;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["style", "margin-top: 100px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "alert alert-danger text-center"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Conexaris Platform"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" P\u00E1gina no encontrada (404) "]))], null, null); }
export function View_NotFoundPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundPageComponent_0, RenderType_NotFoundPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.NotFoundPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundPageComponentNgFactory = i1.ɵccf("app-not-found", i2.NotFoundPageComponent, View_NotFoundPageComponent_Host_0, {}, {}, []);
export { NotFoundPageComponentNgFactory as NotFoundPageComponentNgFactory };

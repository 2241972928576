/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-service.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./status-service.page";
var styles_StatusServicePageComponent = [i0.styles];
var RenderType_StatusServicePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusServicePageComponent, data: {} });
export { RenderType_StatusServicePageComponent as RenderType_StatusServicePageComponent };
export function View_StatusServicePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["status ok!"]))], null, null); }
export function View_StatusServicePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-service", [], null, null, null, View_StatusServicePageComponent_0, RenderType_StatusServicePageComponent)), i1.ɵdid(1, 114688, null, 0, i2.StatusServicePageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusServicePageComponentNgFactory = i1.ɵccf("app-status-service", i2.StatusServicePageComponent, View_StatusServicePageComponent_Host_0, {}, {}, []);
export { StatusServicePageComponentNgFactory as StatusServicePageComponentNgFactory };

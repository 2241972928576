import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/services/auth/auth.service';
import { CheckoutService } from '../../services/checkout/checkout.service';
import { Component, OnInit } from '@angular/core';
import { EnumType } from '../../models/enums/enum';
import { environment } from '../../../environments/environment';
import { ShoppingCartModel } from '../../models/shopping-cart';
import { StorageUtil } from '../../utils/storage-util';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  token: string = undefined;
  progressBar: boolean = true;
  shoppingCart: ShoppingCartModel = new ShoppingCartModel();

  messageError: string = undefined;

  redirectToSeatAssignment: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private checkoutService: CheckoutService
  ) { }

  ngOnInit() {
    StorageUtil.remove(EnumType.LocalStorage.AUTH);
    // StorageUtil.remove(EnumType.LocalStorage.SEAT);
    StorageUtil.remove(EnumType.LocalStorage.VISANET);

    this.route.params.subscribe(params => {
      let key = params['token'];
      let language = params['language'] == undefined ? 'es' : params['language'];
      if (key) {
        this.token = key.split("?")[0];
        // StorageUtil.set(EnumType.LocalStorage.SEAT, queryParams, environment.storage_encrypt_flag);
        this.validateToken(language.trim());
        // this.route.queryParams.subscribe(queryParams => {
        // });
      }
    });
  }

  validateToken(language: string) {
    this.authService.validateToken(this.token, language).subscribe(
      (authReponse: any) => {
        if (authReponse.header.status) {
          if (authReponse.body.order_id) {
            let authModel: any = {
              token: authReponse.body.token
            }
            StorageUtil.set(EnumType.LocalStorage.AUTH, authModel, environment.storage_encrypt_flag);
            this.shoppingCart.order_id = authReponse.body.order_id;
            this.checkoutService.setShoppingCart(this.shoppingCart);
            if (authReponse.body.session_language) 
              this.checkoutService.setlanguage(authReponse.body.session_language);
            
            this.router.navigate([authReponse.body.page]);
          } else {
            this.messageError = authReponse.header.message_description;
            this.progressBar = false;
          }
        } else {
          this.messageError = authReponse.header.message_description;
          this.progressBar = false;
        }
      },
      (err) => {
        this.messageError = err.description;
        this.progressBar = false;
      }
    );
  }
}

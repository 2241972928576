import * as moment from 'moment';

export class DateUtil {

    static getMonthRangeDates(year: number, month: number, format: string) {
        const startDate = moment([year, month - 1]).format(format);
        const endDate = moment(startDate).endOf('month').format(format);
        return { start: startDate, end: endDate };
    }

    static getDaysByMonthArray(start_date: string, end_date: string, format: string) {
        const dates = [];
        let currentDate = new Date(start_date);
        const addDays = function (days) {
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        while (currentDate <= new Date(end_date)) {
            currentDate = addDays.call(currentDate, 1);
            dates.push(moment(currentDate).format(format));
        }
        return dates;
    }

    static getActualDate(format: string) {
        return moment().format(format);
    }

    static getFormattedDate(date: Date, format: string) {
        if (!date) {
            return null;
        }
        return moment(date).format(format);
    }

    static getDaysDifference(start_date: Date, end_date: Date) {
        return moment(start_date).diff(end_date, 'days');
    }

    static getSecondsDifference(start_date: Date, end_date: Date) {
        return moment(start_date).diff(end_date, 'seconds');
    }

    static getDateNumberFormat(number: string, format_input: string, format_output: string) {
        return moment(number, format_input).format(format_output);
    }

    static getDateNumber(number: number) {
        return moment(number);
    }

    static getDifference(date_number: number, date_string: string) {
        return moment(date_string).diff(moment(date_number), 'seconds');
    }

    static compareDatesByMinuts(date, minuts_compare) {
        let currentDate = moment(this.getActualDate('YYYY-MM-DD hh:mm:ss'));
        let lastDate = moment(date).format('YYYY-MM-DD hh:mm:ss');

        let diff = moment(currentDate).diff(lastDate);
        let minuts = moment.duration(diff).asMinutes();

        if (minuts > minuts_compare)
            return false;
        else
            return true;
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { environment } from '../../../../environments/environment';
import { ShoppingCartModel } from '../../../models/shopping-cart';
import { StorageUtil } from '../../../utils/storage-util';
import { EnumType } from '../../../models/enums/enum';
import { EcommerceRulesModel } from '../../../models/ecommerce-rules';
import { EcommerceRulesService } from '../../../services/ecommerce-rules/ecommerce-rules.service';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
import * as lodash from 'lodash';
declare let $: any;

@Component({
	selector: 'app-checkout-detection',
	templateUrl: './checkout-detection.page.html',
	styleUrls: ['./checkout-detection.page.scss']
})
export class CheckoutDetectionPageComponent implements OnInit {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	detectionFormGroup: FormGroup;
	verifyFormGroup: FormGroup;

	progressBar: any = {
		list_page: true
	};
	progressButton: any = {
		process: false,
		two_factor: false,
		verify: false
	};

	messageError: string = undefined;

	page_title: string = '';

	text_message: string = '';
	placeholder: string = '';
	identify_customer: EcommerceRulesModel = new EcommerceRulesModel();
	identify_two_factor: boolean = false;

	dato: string = '';

	code: string = '';

	constructor(
		private checkoutService: CheckoutService,
		private ecommerceRulesService: EcommerceRulesService,
		private formBuilder: FormBuilder,
		private router: Router,
		private progressbarService: ProgressBarService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
	}

	ngOnInit() {
		if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
			this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.mail;
			this.text_message = this.checkoutService.getEcommerceParameters().label_texts.message_page_identify;
			this.placeholder = this.checkoutService.getEcommerceParameters().label_texts.placeholder_page_identify;
			this.initForm();
			this.appendModalsToBody();

			if (this.shoppingCart.order_number != 0) {
				this.progressbarService.setVisibility(false);
				this.router.navigate(['/checkout/confirm']);

			} else if (this.shoppingCart.order_detail.length == 0) {
				this.progressbarService.setVisibility(false);
				this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
			} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
				this.shoppingCart.payment_method.card.name == 'Visa' &&
				this.shoppingCart.payment_method.visa_net &&
				this.shoppingCart.payment_method.visa_net.transaction_authorization &&
				!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
				this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
				this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

				// Validate if order have a visa success transaction
				// If Exists, an error occurred during the purchase generation
				this.progressbarService.setVisibility(false);
				this.router.navigate(['/checkout/payment-error']);

			} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
				this.shoppingCart.payment_method.card.name == 'Mastercard' &&
				this.shoppingCart.payment_method.master_card &&
				this.shoppingCart.payment_method.master_card.transaction_result &&
				this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
				this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

				// Validate if order have a visa success transaction
				// If Exists, an error occurred during the purchase generation
				this.progressbarService.setVisibility(false);
				this.router.navigate(['/checkout/payment-error']);

			} else {
				this.getRules();
			}
		} else {
			this.progressBar.list_page = false;
			this.progressbarService.setVisibility(this.progressBar.list_page);
			this.router.navigate(['/checkout/cart']);
		}

	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	getRules() {
		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.IDENTIFY_CUSTOMER).subscribe(
			(response: any) => {

				if (response.header.status) {
					let bypass: any = lodash.find(response.body, { name: EnumType.EcommerceRules.BYPASS_IDENTIFY_PAGE });
					let identify: any = lodash.find(response.body, { name: EnumType.EcommerceRules.IDENTIFY_CUSTOMER });
					let required: any = lodash.find(response.body, { name: EnumType.EcommerceRules.IDENTIFICATION_REQUIRED });
					let two_factor: any = lodash.find(response.body, { name: EnumType.EcommerceRules.TWO_FACTOR_IDENTIFY_CUSTOMER });

					this.dato = StorageUtil.get(EnumType.LocalStorage.IDENTIFY_FIELD, environment.storage_encrypt_flag);
					if (bypass && identify && required && two_factor) {
						this.identify_customer = identify;
						this.identify_two_factor = two_factor.value == 'true';

						if (bypass.value == 'true') {
							if (this.identify_customer.value == 'API') {
								this.completeCustomerDataWithAdaper();
							} else {
								this.router.navigate(['/checkout/detail']);
							}
						}
					} else {

					}
					this.progressBar.list_page = false;
					this.progressbarService.setVisibility(this.progressBar.list_page);
				} else {

				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
		$('#modalTwoFactor').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
		$('body > #modalTwoFactor').remove();
	}

	initForm() {
		this.detectionFormGroup = this.formBuilder.group({
			field: ['']
		});

		this.verifyFormGroup = this.formBuilder.group({
			code: ['', [Validators.minLength(6), Validators.maxLength(6)]]
		});
	}

	validate() {
		console.log('validate');
		if (this.detectionFormGroup.valid) {

			if (this.identify_customer.value == 'PAGE') {
				let customer = {};

				let spec: any = lodash.find(this.identify_customer.specs, (r) => { return r.value.toString() == this.identify_customer.value.toString() });

				if (spec) {
					let valid = true;
					if (spec.regular_expression) {
						let value_regex = new RegExp(spec.regular_expression);
						if (value_regex.test(this.dato)) {
							customer[spec.search_field] = this.dato;
						} else {
							this.messageError = "Por favor ingresar el dato con un formato válido";
							valid = false;
							$("#modalMessage").modal("show");
						}
					}
					if (valid) {
						this.completeCustomerDataPage(customer);
					}
				} else {

				}

			} else {
				let spec: any = lodash.find(this.identify_customer.specs, (r) => { return r.value.toString() == this.identify_customer.value.toString() });

				if (spec) {

					let valid = true;
					if (spec.regular_expression) {
						let value_regex = new RegExp(spec.regular_expression);
						if (!value_regex.test(this.dato)) {
							this.messageError = "Por favor ingresar el dato con un formato válido";
							valid = false;
							$("#modalMessage").modal("show");
						}
					}
					if (valid) {
						this.shoppingCart.customer[this.identify_customer.adapter_fields[0]] = this.dato;

						this.progressButton.process = true;
						let btn = $('#btnCheckMail');
						if (btn.data('isbusy') === false) {
							btn.data('isbusy', true);
							this.completeCustomerDataWithAdaper(btn);
						}

					}
				} else {

				}
			}

		} else {
			for (const field in this.detectionFormGroup.controls) {
				this.detectionFormGroup.get(field).markAsDirty({ onlySelf: true });
			}
		}
	}

	focusTarget(id: String) {
		$(id).focus();
	}

	completeCustomerDataWithAdaper(btn?: any) {

		let entity = {};
		let spec: any = lodash.find(this.identify_customer.specs, (r) => { return r.value.toString() == this.identify_customer.value.toString() });

		// Validate that the fields requested by the adapter exist.
		if (this.identify_customer.adapter_fields && this.identify_customer.adapter_fields.length > 0 && this.identify_customer.adapter_fields[0] != '') {

			entity[this.identify_customer.adapter_fields[0]] = this.shoppingCart.customer[this.identify_customer.adapter_fields[0]];

			this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
				(responseAdapter: any) => {
					if (responseAdapter.header.status) {

						this.checkoutService.updateCustomer(this.shoppingCart.customer, EnumType.Pages.IDENTIFY_CUSTOMER).subscribe(
							(response: any) => {
								if (response.header.status) {
									$('#modalTwoFactor').modal('hide');
									StorageUtil.set(EnumType.LocalStorage.IDENTIFY_FIELD, this.shoppingCart.customer[this.identify_customer.adapter_fields[0]], environment.storage_encrypt_flag);
									this.router.navigate(['/checkout/detail']);
								}
							},
							(error) => {
								$('#modalTwoFactor').modal('hide');
								console.log('error => ', error);
								this.progressButton.process = false;
								if (btn) btn.data('isbusy', false);
							}
						);

					} else {
						$('#modalTwoFactor').modal('hide');
						this.progressButton.process = false;
						if (btn) btn.data('isbusy', false);
					}
				},
				(err) => {
					$('#modalTwoFactor').modal('hide');
					console.log(err);
					this.progressButton.process = false;
					if (btn) btn.data('isbusy', false);
				}
			);

		}

	}

	completeCustomerDataPage(customer) {
		let spec: any = lodash.find(this.identify_customer.specs, (r) => { return r.value.toString() == this.identify_customer.value.toString() });
		console.log("specs => ", spec);
		console.log("search_field => ", spec.search_field);
		StorageUtil.set(EnumType.LocalStorage.IDENTIFY_FIELD, this.dato, environment.storage_encrypt_flag);
		this.progressButton.process = true;
		let btn = $('#btnCheckMail');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);

			this.checkoutService.updateDetectionCustomer(customer).subscribe(
				(response: any) => {
					if (response.header.status) {
						$('#modalTwoFactor').modal('hide');
						this.checkoutService.setShoppingCart(this.shoppingCart);
						this.router.navigate(['/checkout/detail']);
					} else {
						$('#modalTwoFactor').modal('hide');
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = response.header.message_description;
						$('#modalMessage').modal('show');
					}
				},
				(error) => {
					$('#modalTwoFactor').modal('hide');
					this.progressButton.process = false;
					btn.data('isbusy', false);
					this.messageError = error.description;
					$('#modalMessage').modal('show');

				}
			);
		}
	}

	showModalTwoFactor() {
		this.code = '';
		this.progressButton.verify = false;
		let btn1 = $('#btnVerifyTwoFactor');
		btn1.data('isbusy', false);

		this.progressButton.process = true;
		let btn = $('#btnCheckMail');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);

			let spec: any = lodash.find(this.identify_customer.specs, (r) => { return r.value.toString() == this.identify_customer.value.toString() });
			let valid = true;
			if (spec) {

				if (spec.regular_expression) {
					let value_regex = new RegExp(spec.regular_expression);
					if (!value_regex.test(this.dato)) {
						this.messageError = "Por favor ingresar el dato con un formato válido";
						valid = false;
					}
				}
			}

			if (valid) {
				// this.shoppingCart.customer[this.identify_customer.adapter_fields[0]] = this.dato;
				this.checkoutService.generateTwoFactorCode(this.dato).subscribe(
					(response: any) => {
						if (response.header.status) {
							$('#modalTwoFactor').modal('show');
							this.progressButton.process = false;
							btn.data('isbusy', false);
						} else {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}
					},
					(err) => {
						console.log(err);
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = err.description;
						$('#modalMessage').modal('show');
					}
				);


			} else {
				$("#modalMessage").modal("show");
			}

		}
	}

	verifyCode() {

		this.progressButton.verify = true;
		let btn = $('#btnVerifyTwoFactor');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			this.checkoutService.verifyTwoFactorCode(this.code).subscribe(
				(response: any) => {
					if (response.header.status) {
						// this.progressButton.verify = false;
						// btn.data('isbusy', false);
						this.validate();
					} else {
						$('#modalTwoFactor').modal('hide');
						this.progressButton.verify = false;
						btn.data('isbusy', false);
						this.messageError = response.header.message_description;
						$('#modalMessage').modal('show');
					}
				},
				(err) => {
					$('#modalTwoFactor').modal('hide');
					console.log(err);
					this.progressButton.verify = false;
					btn.data('isbusy', false);
					this.messageError = err.description;
					$('#modalMessage').modal('show');
				}
			);
		}

	}

}

export class ItineraryDetailModel {
    bus: BusModel;
    limite_reserva: number;
    tarifa: TarifaModel;

    constructor() { }
}

export class AsientoModel {

    x: number;
    y: number;
    w: string;
    h: string;
    asiento: string;
    nivel: string;
    piso: string;
    type: string;
    descripcion: string;
    disponible: string;
    selusr: number;
    tarifa_id: string;
    tarifa_descripcion: string;
    precio: string;
    precio_dolar: string;
    bloq_inter: string;
    servicio: string;

    selected: boolean;

    IDENTIFICADOR: string;
    PRODUCT_CODE: string;

    codigoTransaccion: string;

    constructor() { }
}

export class BusModel {
    level_1: LevelModel;
    level_2: LevelModel;
}

export class LevelModel {
    elements: AsientoModel[];
    props: PropiedadesModel
}

export class PropiedadesModel {
    bn: number;
    bw: number;
}

export class TarifaModel {
    VIP: string;
    VIP_DOL: string;
    REGULAR: string;
    REGULAR_DOL: string;
    INSUPERABLE: string;
    INSUPERABLE_DOL: string;
    OCASION: string;
    OCASION_DOL: string;
    PREF_ALTA_1: string;
    PREF_ALTA_2: string;
    PREF_MEDIA_1: string;
    PREF_MEDIA_2: string;
    PREF_ALTA_1_DOL: string;
    PREF_ALTA_2_DOL: string;
    PREF_MEDIA_1_DOL: string;
    PREF_MEDIA_2_DOL: string;
    LOCURA: string;
    LOCURA_DOL: string;
};
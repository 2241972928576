export class EcommerceParametersModel {

    session: {
        page_redirect: string,
        timeout: string,
        expired_message: string,
        detail_limit: number,
        adapter_clean_detail: string,
        timeout_detail_limit: number
    };

    company_data: {
        logo: string,
        icono_phone: string,
        text_phone: string,
        icono_mail: string,
        text_mail: string
    };

    pages_titles: {
        cart: string,
        mail: string,
        detail: string,
        customer: string,
        shipping_address: string,
        register_address: string,
        shipping_store: string,
        shipping_time: string,
        payment_method: string,
        payment_document: string,
        metadata: string,
        seat_assignment: string
    };

    color_buttons: {
        primary: {
            background: string,
            border: string,
            active_background: string,
            active_border: string,
            font: string
        },
        secondary: {
            background: string,
            border: string,
            active_background: string,
            active_border: string,
            font: string
        },
        third: {
            background: string,
            border: string,
            active_background: string,
            active_border: string,
            font: string
        }
    };

    css: {
        seat_depart_tab: {
            background: string,
            border: string,
            font: string,
            active_background: string,
            active_border: string,
            active_font: string
        },
        seat_return_tab: {
            background: string,
            border: string,
            font: string,
            active_background: string,
            active_border: string,
            active_font: string
        }
    };

    payment_methods: {
        payment_id: string,
        code: string,
        description: string,
        payment_amount: string,
        cards: [{
            id: string,
            name: string,
            image: string
        }],
        bank_account: [{
            id: string,
            bank: string,
            number_account: string,
            money: string
        }],
        visa_data: {
            user: string,
            password: string,
            merchant_id: string,
            api_token: string,
            api_session: string,
            api_transaction: string
        },
        mastercard_data: {
            trade_code: string,
            merchant_key: string,
            api_action: string,
            api_action_amex: string,
            api_action_dinners: string,
        },
        status: string,
        created_timestamp: Date,
        updated_timestamp: Date
    };

    messages_client: {
        no_accepted_policy: string,
        clean_detail_by_timeout: string
    };

    label_texts: {
        button_metadata_label: string,
        message_page_identify: string,
        placeholder_page_identify: string
    };

    currencies: [{
        code: string,
        description: string,
        symbol: string,
    }];

    terms_and_conditions: [{
        language_code: string,
        terms: string,
    }];

}
<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title | labelTranslator }}
	</div>

	<div class="dcard">
		<div class="container-fluid p-0">
			<div class="row">
				<div class="col">
					<div class="row">
						<div class="col d-flex flex-row" [formGroup]="detectionFormGroup">
							<label for="lblInputMail_1"
								class="d-flex align-items-center justify-content-center m-0 pr-2 body-card-pending">
								<i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
							</label>
							<div class="input-group">
								<input id="lblInputMail_1" type="text" class="form-control"
									placeholder="{{ placeholder }}" formControlName="field"
									[ngClass]="{'is-invalid': detectionFormGroup.controls.field.invalid && detectionFormGroup.controls.field.dirty}"
									[(ngModel)]="dato" (keydown.enter)="validate()" lowercase>
								<div class="form-control-input" *ngIf="dato != ''"
									(click)="dato = '';focusTarget('#lblInputMail_1')">
									<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<span class="dfont text-justify">
								{{ text_message | labelTranslator }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<ng-container *ngIf="!identify_two_factor">
					<button id="btnCheckMail" class="btn btn-primary btn-block my-2" (click)="validate()"
						[disabled]="dato === ''" [ngClass]="{'btn-disabled': progressButton.process === true}"
						data-isbusy="false">
						<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
						{{ 'CONTINUAR' | labelTranslator }}
					</button>
				</ng-container>
				<ng-container *ngIf="identify_two_factor">
					<button id="btnCheckMail" class="btn btn-primary btn-block my-2" [disabled]="dato === ''"
						(click)="showModalTwoFactor()" [ngClass]="{'btn-disabled': progressButton.process === true}"
						data-isbusy="false">
						<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
						{{ 'CONTINUAR' | labelTranslator }}
					</button>
				</ng-container>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'Identificación de cliente' | labelTranslator }} </div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError | labelTranslator }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					{{ 'CERRAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="modalTwoFactor" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'Verificar Código' | labelTranslator }} </div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="col" [formGroup]="verifyFormGroup">
						<label for="txtInputCode"
							class="d-flex align-items-center justify-content-center mx-0 mt-0 mb-1 pr-2 body-card-pending">
							{{ 'Por favor ingresa el código que se envió a al correo que registraste en el sistema: ' | labelTranslator }}
						</label>
						<div class="input-group">
							<input id="txtInputCode" type="text" class="form-control" formControlName="code"
								[ngClass]="{'is-invalid': verifyFormGroup.controls.code.invalid && verifyFormGroup.controls.code.dirty}"
								[(ngModel)]="code" (keydown.enter)="verifyCode()" integers>
							<div class="form-control-input" *ngIf="code != ''"
								(click)="code = '';focusTarget('#txtInputCode')">
								<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button id="btnVerifyTwoFactor" class="btn btn-primary my-2"
					[disabled]="code === '' || !verifyFormGroup.valid" (click)="verifyCode()"
					[ngClass]="{'btn-disabled': progressButton.verify === true}" data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.verify === true"></div>
					{{ 'VERIFICAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>
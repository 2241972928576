import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { EcommerceRulesService } from '../../../services/ecommerce-rules/ecommerce-rules.service';
import { ShoppingCartModel, ShoppingCartDetailModel, CustomerModel } from '../../../models/shopping-cart';
import { EnumType } from '../../../models/enums/enum';
import * as lodash from 'lodash';
import { EcommerceRulesModel } from '../../../models/ecommerce-rules';
import { StorageUtil } from '../../../utils/storage-util';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
import { EcommerceFieldTemplateService } from '../../../services/ecommerce-field-template/ecommerce-field-template.service';
import { EcommerceFieldTemplateModel } from '../../../models/ecommerce-field-template';

declare let $: any;

@Component({
	selector: 'app-checkout-cart',
	templateUrl: './checkout-cart.page.html',
	styleUrls: ['./checkout-cart.page.scss']
})
export class CheckoutCartPageComponent implements OnInit {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	ecommerceFieldTemplate: EcommerceFieldTemplateModel = new EcommerceFieldTemplateModel();
	progressBar: any = {
		list_page: true
	};

	progressButton: any = {
		process: false,
		customer: false,
		back: false
	}

	productCalc: any = {
		total: 0,
		quantityTotal: 0
	}

	messageError: string = undefined;
	page_title: string = "";

	view_metadata: boolean = false;
	view_sku_product: boolean = true;

	modifiy_quantities: boolean = true;
	affected_IGV: boolean = true;

	label_metadata: string = "";
	template_name: string = '';

	remove_detail: EcommerceRulesModel = new EcommerceRulesModel();
	metadata_buyer: EcommerceRulesModel = new EcommerceRulesModel();

	metadata_buyers: any[] = [];

	constructor(
		private checkoutService: CheckoutService,
		private ecommerceRulesService: EcommerceRulesService,
		private router: Router,
		private route: ActivatedRoute,
		private progressbarService: ProgressBarService,
		private ecommerceFieldService: EcommerceFieldTemplateService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
	}

	ngOnInit() {
		this.route.params.subscribe(
			param => {
				let status = this.route.snapshot.queryParams["s"];
				if (status !== undefined) {
					console.log(this.shoppingCart.payment_method.summary_object.message_response);
					this.messageError = this.shoppingCart.payment_method.summary_object.message_response;
					$('#modalMessage').modal('show');
				}

				if (this.shoppingCart.order_number != 0) {
					this.progressbarService.setVisibility(false);
					this.router.navigate(['/checkout/confirm']);
				} else if (this.shoppingCart.order_detail.length == 0 && this.checkoutService.getEcommerceParameters().session.page_redirect != '/checkout/cart') {
					this.progressbarService.setVisibility(false);
					this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
				} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
					this.shoppingCart.payment_method.card.name == 'Visa' &&
					this.shoppingCart.payment_method.visa_net &&
					this.shoppingCart.payment_method.visa_net.transaction_authorization &&
					!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
					this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
					this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

					// Validate if order have a visa success transaction
					// If Exists, an error occurred during the purchase generation
					this.progressbarService.setVisibility(false);
					this.router.navigate(['/checkout/payment-error']);

				} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
					this.shoppingCart.payment_method.card.name == 'Mastercard' &&
					this.shoppingCart.payment_method.master_card &&
					this.shoppingCart.payment_method.master_card.transaction_result &&
					this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
					this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

					// Validate if order have a visa success transaction
					// If Exists, an error occurred during the purchase generation
					this.progressbarService.setVisibility(false);
					this.router.navigate(['/checkout/payment-error']);

				} else {

					StorageUtil.remove(EnumType.LocalStorage.VISANET);
					if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
						this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.cart;
						this.label_metadata = this.checkoutService.getEcommerceParameters().label_texts.button_metadata_label;
						this.getRules();
						this.getFieldMap();
					}

				}
			}
		);

	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	getRules() {
		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.SHOPPING_CART).subscribe(
			(response: any) => {
				if (response.header.status) {

					let rule_metada: any = lodash.find(response.body, { name: EnumType.EcommerceRules.REQUIRES_METADATA_DETAIL });
					let rule_quantities: any = lodash.find(response.body, { name: EnumType.EcommerceRules.MODIFY_QUANTITIES_DETAIL });
					let rule_remove_detail: any = lodash.find(response.body, { name: EnumType.EcommerceRules.REMOVE_DETAIL });
					let rule_metadata_buyer: any = lodash.find(response.body, { name: EnumType.EcommerceRules.INDICATE_METADATA_BUYER });
					let rule_IGV: any = lodash.find(response.body, { name: EnumType.EcommerceRules.AFFECTED_IGV_ORDER });
					let rule_sku: any = lodash.find(response.body, { name: EnumType.EcommerceRules.SHOW_SKU_PRODUCT });

					if (rule_metada && rule_quantities && rule_remove_detail && rule_metadata_buyer && rule_IGV) {
						this.remove_detail = rule_remove_detail;
						this.view_metadata = (rule_metada.value === "true");
						this.affected_IGV = (rule_IGV.value === "true");
						this.view_sku_product = (rule_sku.value === "true");

						let spec: any = lodash.find(rule_metada.specs, { value: rule_metada.value })
						if (spec && spec.template_name) {
							this.template_name = spec.template_name;
						}
						this.modifiy_quantities = (rule_quantities.value === "true");
						this.metadata_buyer = rule_metadata_buyer;

						this.appendModalsToBody();
						if (this.shoppingCart.order_detail && this.shoppingCart.order_detail.length > 0) {
							this.calcDetails();
						}
						this.progressBar.list_page = false;
						this.progressbarService.setVisibility(this.progressBar.list_page);
					}
				} else {
					this.messageError = 'Ocurrio un error en el sistema';
					console.log("rules not configured")
					$('#modalMessage').modal('show');
				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	/**
	 * Move modal within 'body' tags
	 */
	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
		$('#modalBuyer').appendTo('body');
	}

	/**
	 * Remove modal of the 'body' tags
	 */
	removeModalsFromBody() {
		$('body > #modalMessage').remove();
		$('body > #modalBuyer').remove();
	}

	/**
	 * Remove shoping cart detail
	 * @param item ShoppingCartDetailModel
	 * @param index index of detail
	 */
	deleteItem(item: ShoppingCartDetailModel, index: number) {
		let btn = $('#btnDeleteProduct_' + index);
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);

			$('#btnDeleteProduct_' + index).addClass('dfont-white btn-disabled');
			$('#btnDeleteProduct_' + index + ' > .svg-icon-spinner').removeClass('d-none');

			if (this.remove_detail) {

				if (this.remove_detail.value == 'LOCAL') {
					this.checkoutService.deleteProduct(item.product_code).subscribe(
						(response: any) => {
							if (response.header.status) {
								btn.data('isbusy', false);
								$('#btnDeleteProduct_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
								$('#dcard_' + index).addClass('animated fadeOut');
								setTimeout(() => {
									this.shoppingCart.sub_total -= this.shoppingCart.order_detail[index].amount_total;
									this.shoppingCart.igv_amount -= this.shoppingCart.order_detail[index].amount_igv;
									this.shoppingCart.total_amount = this.shoppingCart.sub_total + this.shoppingCart.igv_amount;

									lodash.remove(this.shoppingCart.order_detail, ['product_code', item.product_code]);
									this.calcDetails();
								}, 500);
							} else {
								btn.data('isbusy', false);
								$('#btnDeleteProduct_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
								this.messageError = response.header.message_description;
								$('#modalMessage').modal('show');
							}
						},
						(error) => {
							$('#btnDeleteAddress_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
							btn.data('isbusy', false);
							this.messageError = error.description;
							$('#modalMessage').modal('show');
						}
					);
				} else if (this.remove_detail.value == 'API - LOCAL') {

					let spec: any = lodash.find(this.remove_detail.specs, (r) => { return r.value.toString() == this.remove_detail.value.toString() });

					let entity = {
						order_id: this.shoppingCart.order_id,
						product_code: item.product_code
					};

					this.checkoutService.callAdapter(spec.adapter_save, entity).subscribe(
						(response: any) => {
							btn.data('isbusy', false);
							$('#btnDeleteProduct_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
							$('#dcard_' + index).addClass('animated fadeOut');
							setTimeout(() => {
								this.shoppingCart.sub_total -= this.shoppingCart.order_detail[index].amount_total;
								this.shoppingCart.igv_amount -= this.shoppingCart.order_detail[index].amount_igv;
								this.shoppingCart.total_amount = this.shoppingCart.sub_total + this.shoppingCart.igv_amount;

								lodash.remove(this.shoppingCart.order_detail, ['product_code', item.product_code]);
								this.calcDetails();
							}, 500);
						},
						(error) => {
							$('#btnDeleteAddress_' + index + ' > .svg-icon-spinner-black').addClass('d-none');
							btn.data('isbusy', false);
							this.messageError = error.description;
							$('#modalMessage').modal('show');
						}

					);

				}

			}

		}
	}

	/**
	 * Redirect to metadata page
	 * @param item ShoppingCartDetailModel
	 * @param index index of detail
	 */
	redirectFromMetadata(item: ShoppingCartDetailModel, index: number) {
		let btn = $('#btnRedirectMetadata_' + index);
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			// $('#btnRedirectMetadata_' + index).addClass('');
			$('#btnRedirectMetadata_' + index).addClass('dfont-white btn-disabled');
			$('#btnRedirectMetadata_' + index + ' > .svg-icon-spinner').removeClass('d-none');
			console.log(this.template_name);
			this.router.navigate(['/checkout/metadata', item.product_code, this.template_name]);

		}
	}

	/**
	 * Increase the quantity of items
	 * @param item ShoppingCartDetailModel
	 */
	addItems(index: number) {
		this.shoppingCart.sub_total -= this.shoppingCart.order_detail[index].amount_total;
		this.shoppingCart.igv_amount -= this.shoppingCart.order_detail[index].amount_igv;

		this.shoppingCart.order_detail[index].quantity++;
		this.shoppingCart.order_detail[index].amount_total = this.shoppingCart.order_detail[index].unit_price * this.shoppingCart.order_detail[index].quantity;
		this.shoppingCart.order_detail[index].amount_igv = Math.round(this.shoppingCart.order_detail[index].amount_total * 0.18 * 100) / 100;

		console.log(this.shoppingCart.order_detail[index].quantity);
		this.shoppingCart.sub_total += this.shoppingCart.order_detail[index].amount_total;
		this.shoppingCart.igv_amount += this.shoppingCart.order_detail[index].amount_igv;

		this.shoppingCart.total_amount += this.shoppingCart.sub_total + this.shoppingCart.igv_amount;
		this.calcDetails();
		// this.calcByItem(item);
		// let findItem = lodash.find(this.shoppingCart.order_detail, ['product_code', item.product_code]);
		// if (findItem) {
		// 	findItem.quantity = findItem.quantity + 1;
		// 	this.calcByItem(item);
		// 	this.calcDetails();
		// }
	}

	/**
	 * Decrease the quantity of items
	 * @param item ShoppingCartDetailModel
	 */
	removeItems(index: number) {
		this.shoppingCart.sub_total -= this.shoppingCart.order_detail[index].amount_total;
		this.shoppingCart.igv_amount -= this.shoppingCart.order_detail[index].amount_igv;
		if (this.shoppingCart.order_detail[index].quantity >= 2) {
			this.shoppingCart.order_detail[index].quantity -= 1;
			this.calcByItem(index);
			this.calcDetails();
		}
	}

	calcByItem(index: any) {
		this.shoppingCart.order_detail[index].amount_total = this.shoppingCart.order_detail[index].unit_price * this.shoppingCart.order_detail[index].quantity;
	}

	calcDetails() {
		console.log("calcul");
		this.productCalc.quantityTotal = lodash.sum(lodash.map(this.shoppingCart.order_detail, function (values) { return values.quantity; }));
		this.productCalc.total = lodash.sum(lodash.map(this.shoppingCart.order_detail, function (values) { return values.amount_total; }));
	}

	/**
	 * Save shopping cart, init to process checkout
	 * @param btn button that have effect of loading
	 * @param route_redirect route to redirect when end process 
	 */
	saveShoppingCart(btn, route_redirect: string) {
		console.log('saveShoppingCart => ', this.shoppingCart);
		this.checkoutService.confirmOrderDetail(this.shoppingCart).subscribe(
			(response: any) => {
				if (response.header.status) {
					this.progressBar.process = true;
					$('#modalBuyer').modal('hide');
					this.router.navigate([route_redirect]);
				} else {
					this.progressButton.process = false;
					this.progressBar.customer = false;
					btn.data('isbusy', false);
					this.messageError = response.header.message_description;
					$('#modalBuyer').modal('hide');
					$('#modalMessage').modal('show');
				}

			},
			(error) => {
				this.progressButton.process = false;
				this.progressBar.customer = false;
				btn.data('isbusy', false);
				console.log(error);
				this.messageError = error.description;
				$('#modalBuyer').modal('hide');
				$('#modalMessage').modal('show');
			}
		);
	}

	/**
	 * Data evaluation and rules to follow the next step
	 */
	redirectToNextStep() {
		console.log('redirectToNextStep => ', this.shoppingCart);
		this.messageError = undefined;
		this.metadata_buyers = [];

		let btn = $('#btnProcess');
		if (btn.data('isbusy') === false) {
			this.progressButton.process = true;
			btn.data('isbusy', true);

			// this.shoppingCart.sub_total = parseFloat((this.productCalc.total / 1.18).toFixed(2));

			// Verify if exists rule for the metadata
			if (this.view_metadata) {

				// Validate that the metadata is complete
				if (this.validadMetada()) {

					// Validate if the rule to select buyer of the metadata is active
					if (this.metadata_buyer.value === "true") {
						let spec: any = lodash.find(this.metadata_buyer.specs, (r) => { return r.value.toString() === this.metadata_buyer.value });

						let tempArray: CustomerModel[] = [];

						// create array of customers
						this.shoppingCart.order_detail.forEach(detail => {

							if (detail.metadata_detail) {
								let customer: CustomerModel = new CustomerModel();

								// customer.mail
								let array_mail = spec.fields.mail.split('|');
								array_mail.forEach(item => {
									if (!customer.mail || customer.mail == '')
										customer.mail = detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
									else
										customer.mail = customer.mail + ' ' + detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
								});

								// customer.names
								let array_names = spec.fields.names.split('|');
								array_names.forEach(item => {
									if (!customer.names || customer.names == '')
										customer.names = detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
									else
										customer.names = customer.names + ' ' + detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
								});

								// customer.last_names
								let array_last_names = spec.fields.last_names.split('|');
								array_last_names.forEach(item => {
									if (!customer.last_names || customer.last_names == '')
										customer.last_names = detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
									else
										customer.last_names = customer.last_names + ' ' + detail.metadata_detail[item].toString();
								});

								// customer.document_type
								let array_document_type = spec.fields.document_type.split('|');
								array_document_type.forEach(item => {
									if (!customer.document_type || customer.document_type == '')
										customer.document_type = detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
									else
										customer.document_type = customer.document_type + ' ' + detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
								});

								// customer.document_number
								let array_document_number = spec.fields.document_number.split('|');
								array_document_number.forEach(item => {
									if (!customer.document_number || customer.document_number == '')
										customer.document_number = detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
									else
										customer.document_number = customer.document_number + ' ' + detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
								});

								// customer.phone_number
								let array_phone_number = spec.fields.phone_number.split('|');
								array_phone_number.forEach(item => {
									if (!customer.phone_number || customer.phone_number == '')
										customer.phone_number = detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
									else
										customer.phone_number = customer.phone_number + ' ' + detail.metadata_detail[item] ? detail.metadata_detail[item].toString() : '';
								});

								tempArray.push(customer);
							}
						});

						console.log(tempArray);
						// Delete repeated values
						let metadata_buyers_tmp = lodash.uniqBy(tempArray, 'document_number');

						// if there are more than one client registered in the metadata, the modal to select payer must be shown
						if (metadata_buyers_tmp.length > 1) {
							let is_selected_buyer = false;
							metadata_buyers_tmp.forEach(buyer => {
								let select = false;
								select = this.shoppingCart.customer.document_number == buyer.document_number;
								this.metadata_buyers.push({ customer: buyer, is_select: select });

								if (select) is_selected_buyer = true;
							});

							if (!is_selected_buyer) this.metadata_buyers[0].is_select = true;

							this.progressButton.process = false;
							btn.data('isbusy', false);
							$('#modalBuyer').modal('show');
						} else {
							// If there is only one client in the metadata, it is considered as the payer
							metadata_buyers_tmp[0].customer_id = this.shoppingCart.customer.customer_id;
							this.checkoutService.updateCustomerByMetadata(metadata_buyers_tmp[0]).subscribe(
								(response: any) => {
									if (response.header.status) {
										// redirect to checkout detail
										this.saveShoppingCart(btn, '/checkout/detail');
									} else {
										this.progressButton.process = false;
										btn.data('isbusy', false);
										this.messageError = response.header.message_description;
										$('#modalMessage').modal('show');
									}
								},
								(error) => {
									this.progressButton.process = false;
									btn.data('isbusy', false);
									this.messageError = error.description;
									$('#modalMessage').modal('show');

								}
							);
						}

					} else {
						// If the rule to select buyer of the metadata is inactive continue process
						this.getRuleOfIdentifyCustomer(btn);
					}
				} else {
					this.progressButton.process = false;
					btn.data('isbusy', false);
					this.messageError = "Por favor completar todos los datos solicitados";
					$('#modalMessage').modal('show');
				}
			} else {
				// iF not exists rule for metadata, continue process
				this.getRuleOfIdentifyCustomer(btn);
			}
		}
	}

	/**
	 * validate that all fields of metadata are complete
	 */
	validadMetada() {
		let valid = true;
		// console.log('this.shoppingCart => checkout-cart', this.shoppingCart);
		this.shoppingCart.order_detail.forEach(detail => {
			// console.log('detail.metadata_detail => ', detail);
			if (detail.metadata_detail) {
				let keys = Object.keys(detail.metadata_detail);
				// console.log('keys => ', keys);
				if (!keys || keys.length == 0) valid = false;
			} else {
				valid = false;
			}
		});

		return valid;
	}

	/**
	 * validate that all fields of metadata are complete
	 * @param index index of customer selected
	 */
	setBuyerOfMetadata(index: number) {
		this.metadata_buyers.forEach((item, i) => {
			if (i == index) item.is_select = true;
			else item.is_select = false;
		});
	}

	/**
	 * Save shopping cart with customer selected of the metadata
	 */
	saveShoppingCartWithCustomer() {
		this.messageError = undefined;

		let btn = $('#btnProcess_Customer');
		if (btn.data('isbusy') === false) {
			this.progressButton.customer = true;
			btn.data('isbusy', true);

			let buyer = lodash.find(this.metadata_buyers, (b) => { return b.is_select == true });
			if (buyer && buyer.customer) {
				buyer.customer.customer_id = this.shoppingCart.customer.customer_id;
				this.checkoutService.updateCustomerByMetadata(buyer.customer).subscribe(
					(response: any) => {
						if (response.header.status) {
							this.saveShoppingCart(btn, '/checkout/detail');
						} else {
							this.progressButton.customer = false;
							this.progressBar.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalBuyer').modal('hide');
							$('#modalMessage').modal('show');
						}
					},
					(error) => {
						this.progressButton.customer = false;
						this.progressBar.process = false;
						btn.data('isbusy', false);
						this.messageError = error.description;
						$('#modalBuyer').modal('hide');
						$('#modalMessage').modal('show');

					}
				);
			} else {
				this.progressButton.customer = true;
				this.progressBar.process = false;
				btn.data('isbusy', false);
			}
		}
	}

	/**
	 * obtain rule for know if it must be redirected to page de identify customer
	 * @param btn button that have effect of loading
	 */
	getRuleOfIdentifyCustomer(btn) {
		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.IDENTIFY_CUSTOMER).subscribe(
			(response: any) => {

				if (response.header.status) {
					let bypass: any = lodash.find(response.body, { name: EnumType.EcommerceRules.BYPASS_IDENTIFY_PAGE });
					let identify: any = lodash.find(response.body, { name: EnumType.EcommerceRules.IDENTIFY_CUSTOMER });

					if (bypass && identify) {
						identify;

						if (bypass.value == 'true') {
							if (identify.value == 'API') {
								this.completeCustomerDataWithAdaper(btn, identify);
							} else {
								this.saveShoppingCart(btn, '/checkout/detail');
							}
						} else {
							this.saveShoppingCart(btn, '/checkout/detection');
						}
					} else {
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = 'Ha habido un incoveniente en el servicio. Intentelo nuevamente por favor.';
						$('#modalMessage').modal('show');
					}
					this.progressBar.list_page = false;
					this.progressbarService.setVisibility(this.progressBar.list_page);
				} else {
					this.progressButton.process = false;
					btn.data('isbusy', false);
					this.messageError = response.header.message_description;;
					$('#modalMessage').modal('show');
				}
			},
			(err) => {
				this.progressButton.process = false;
				btn.data('isbusy', false);
				this.messageError = err.description;
				$('#modalMessage').modal('show');
			}
		);
	}

	/**
	 * Complete data of customer with adapter
	 * @param btn button that have effect of loading
	 * @param identify_customer rule for identify customer
	 */
	completeCustomerDataWithAdaper(btn, identify_customer) {

		let entity = {};
		let spec: any = lodash.find(identify_customer.specs, (r) => { return r.value.toString() == identify_customer.value.toString() });

		// Validate that the fields requested by the adapter exist.
		if (identify_customer.adapter_fields && identify_customer.adapter_fields.length > 0 && identify_customer.adapter_fields[0] != '') {

			entity[identify_customer.adapter_fields[0]] = this.shoppingCart.customer[identify_customer.adapter_fields[0]];

			this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
				(responseAdapter: any) => {
					if (responseAdapter.header.status) {

						this.checkoutService.updateCustomer(this.shoppingCart.customer, EnumType.Pages.IDENTIFY_CUSTOMER).subscribe(
							(response: any) => {
								if (response.header.status) {
									this.saveShoppingCart(btn, '/checkout/detail');
								}
							},
							(error) => {
								console.log('error => ', error);
								btn.data('isbusy', false);
							}
						);

					} else {
						this.progressButton.process = false;
						btn.data('isbusy', false);
					}
				},
				(err) => {
					console.log(err);
					this.progressButton.process = false;
					btn.data('isbusy', false);
				}
			);

		}

	}

	getFieldMap() {
		this.ecommerceFieldService.getFieldMap(EnumType.Pages.SHOPPING_CART).subscribe(
			(response: any) => {
				console.log(response.body);
				this.ecommerceFieldTemplate = response.body;
			},
			(error) => {
				console.log('error => ', error);
			}
		);
	}

	redirectToPreviousStep() {
		this.progressBar.back = true;
		this.router.navigate(['/checkout/seat-assignment']);
	}
}

import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[integers]'
})
export class IntegersDirective {

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  @HostListener('keypress', ['$event'])
  onKeyUp($event) {
    const key = $event.keyCode;
    if ((key >= 48 && key <= 57)) {
      this.value = $event.target.value;
      this.ngModelChange.emit(this.value);
    } else {
      $event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  blockPaste($event: KeyboardEvent) {
    $event.preventDefault();
  }

}

<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">


	<div class="dcard">
		<div class="container-fluid p-0">
			<div class="row">
				<div class="col text-center">
					<i class="fa fa-check-circle-o fa-4x" aria-hidden="true" style="color:#6CC163"></i>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
						<ng-container *ngIf="fieldValue.group_order == 1">
							<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
								*ngIf="fieldValue.is_visible==true">
								{{ fieldValue.output[0] }}
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<div class="dcard">
		<div class="container-fluid p-0">

			<ng-container *ngIf="shoppingCart.payment_method.code == 'PMIL'">
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmil-left'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmil-right'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>

						</ng-container>
					</div>
				</div>
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmil-bottom'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="shoppingCart.payment_method.code == 'PMPE'">
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmpe-top'">
								<div class="pb-3" [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmpe-left'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmpe-right'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>

						</ng-container>
					</div>
				</div>
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmpe-bottom'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="shoppingCart.payment_method.code == 'PMEF'">
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmef-left'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmef-right'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>

						</ng-container>
					</div>
				</div>
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmef-bottom'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="shoppingCart.payment_method.code == 'PMCC'">
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmcc-left'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmcc-right'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>

						</ng-container>
					</div>
				</div>
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmcc-bottom'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="shoppingCart.payment_method.code == 'PMDB'">
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmdb-left'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmdb-right'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>

						</ng-container>
					</div>
				</div>
				<div class="row">
					<div class="col px-3">
						<ng-container *ngFor="let fieldValue of ecommerceFieldTemplate.values">
							<ng-container *ngIf="fieldValue.group_order == 'pmdb-bottom'">
								<div [ngClass]="fieldValue.css_class" [ngStyle]="fieldValue.cssStyles"
									*ngIf="fieldValue.is_visible==true">
									{{ fieldValue.output[0] }}
								</div>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>

		</div>
	</div>

	<div class="container-fluid">
		<div class="row">
			<div class="col offset-md-2 offset-lg-3 offset-xl-3 col-sm-12 col-md-8 col-lg-6 col-xl-6 text-center">
				<div class="row">
					<div class="col p-0">
						<button id="btnClose" type="button" class="btn btn-primary btn-block my-2 my-2"
							(click)="closeMyWindow()" [ngClass]="{'btn-disabled': progressButton.process === true}"
							data-isbusy="false">
							<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
							{{ 'CERRAR' | labelTranslator }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../../utils/http-util';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { EnumType } from '../../models/enums/enum';

@Injectable()
export class StoreService {

  constructor(
    private http: HttpClient
  ) { }

  // /**
  //  * Call API Rest for get list of stores
  //  */
  // getListsInit() {
  //   const url = environment.base_url + '/v1.0.0/store';
  //   return this.http.get(url, HttpUtil.getHttpOptions(EnumType.ContentType.JSON))
  //     .map(HttpUtil.extractData).catch(HttpUtil.handleErrorObservable);
  // }

}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { LevelModel, AsientoModel } from '../../models/travels/itinerary-detail';

@Component({
  selector: 'app-seats',
  templateUrl: './seats.component.html',
  styleUrls: ['./seats.component.scss']
})
export class SeatsComponent implements OnInit {

  @Input() primerPiso: LevelModel;
  @Input() segundoPiso: LevelModel;

  @Output() onClickSeat: EventEmitter<AsientoModel> = new EventEmitter<AsientoModel>();

  constructor() { }

  ngOnInit() {
  }

  blockSeat(piso, index) {
    console.log(index);
    //let lock = false;
    if (piso === 'primerPiso' && this.primerPiso.elements[index].bloq_inter == '0') {
      this.primerPiso.elements[index].selected = !this.primerPiso.elements[index].selected;
      this.onClickSeat.emit(this.primerPiso.elements[index]);
    }

    if (piso === 'segundoPiso' && this.segundoPiso.elements[index].bloq_inter == '0') {
      this.segundoPiso.elements[index].selected = !this.segundoPiso.elements[index].selected;
      this.onClickSeat.emit(this.segundoPiso.elements[index]);
    }

  }

}

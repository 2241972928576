import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtil } from '../../utils/http-util';
import { environment } from '../../../environments/environment';
import { ShoppingCartModel, ShippingAddressModel, CustomerModel, ShoppingCartDeliveryTimeModel, ShoppingCartDetailModel, CurrencyModel } from '../../models/shopping-cart';

import { EnumType } from '../../models/enums/enum';
import { StoreModel } from '../../models/store';
import { EcommerceParametersModel } from '../../models/ecommerce-parameters';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CheckoutService {

	private _shippingAddresList: ShippingAddressModel[] = new Array<ShippingAddressModel>();
	private _shoppingCart: ShoppingCartModel = undefined;
	private _storeList: StoreModel[] = new Array<StoreModel>();
	private _ecommerce_parameter: EcommerceParametersModel = new EcommerceParametersModel();
	private _pageLabel: any = {};
	private _language: string = 'es';

	constructor(
		private http: HttpClient
	) { }

	getShoppingCart() {
		return this._shoppingCart;
	}

	setShoppingCart(shoppingCart: ShoppingCartModel) {
		this._shoppingCart = shoppingCart;
	}

	getShippingAddressList() {
		return this._shippingAddresList;
	}

	setShippingAddressList(shippingAddressList: ShippingAddressModel[]) {
		this._shippingAddresList = shippingAddressList;
	}

	getStoreList() {
		return this._storeList;
	}

	setStoreList(storeList: StoreModel[]) {
		this._storeList = storeList;
	}

	getEcommerceParameters() {
		return this._ecommerce_parameter;
	}

	setEcommerceParameters(parameters: EcommerceParametersModel) {
		this._ecommerce_parameter = parameters;
	}

	setPageLabel(pageLabel: any) {
		this._pageLabel = pageLabel;
	}

	getPageLabel() {
		return this._pageLabel;
	}

	setlanguage(language: string) {
		this._language = language;
	}

	getlanguage() {
		return this._language;
	}

	validShoppingCart() {
		if (this._shoppingCart.order_number != 0) {

			return '/checkout/confirm';

		} else if (this._shoppingCart.payment_method.code == 'PMIL' &&
			this._shoppingCart.payment_method.card.name == 'Visa' &&
			this._shoppingCart.payment_method.visa_net &&
			this._shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this._shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this._shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this._shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			return '/checkout/payment-error';

		} else if (this._shoppingCart.order_number == 0 && this._shoppingCart.payment_method.code == 'PMIL' &&
			this._shoppingCart.payment_method.card.name == 'Mastercard' &&
			this._shoppingCart.payment_method.master_card &&
			this._shoppingCart.payment_method.master_card.transaction_result &&
			this._shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this._shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			return '/checkout/payment-error';

		} else if (this._shoppingCart.order_number == 0 && this._shoppingCart.payment_method.code == 'PMPE' &&
			this._shoppingCart.payment_method.pago_efectivo && this._shoppingCart.payment_method.pago_efectivo.cip_result &&
			this._shoppingCart.payment_method.pago_efectivo.cip_result.code == '100' && this._shoppingCart.payment_method.pago_efectivo.cip_result.data &&
			this._shoppingCart.payment_method.pago_efectivo.cip_result.data.cip != '') {
			return '/checkout/confirm';
		} else {
			return '';
		}
	}

	/**
	 * Call API Rest for get order detail
	 * @param page_id Page route
	 */
	getOrderDetail(page_id?: string) {
		let headers = {};
		if (page_id) headers['page-id'] = page_id;
		const url = environment.base_url + '/v1.0.0/checkout';
		return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON, headers)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for confirm order detail
	 * @param shoppingcart shoppingcart info
	 */
	confirmOrderDetail(shoppingcart: ShoppingCartModel) {
		const body = JSON.stringify(shoppingcart);
		const url = environment.base_url + '/v1.0.0/checkout';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for update email order to customer
	 * @param email email
	 */
	updateDetectionCustomer(customer: any) {
		const body = JSON.stringify(customer);
		const url = environment.base_url + '/v1.0.0/checkout/detection-customer';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for update email order to customer
	 * @param customer CustomerModel
	 */
	updateCustomerByMetadata(customer: CustomerModel) {
		const body = JSON.stringify(customer);
		const url = environment.base_url + '/v1.0.0/checkout/customer-by-metadata';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest to update customer
	 * @param shoppingcart email
	 */
	updateCustomer(customer: CustomerModel, page: string) {
		const body = JSON.stringify(customer);
		const url = environment.base_url + '/v1.0.0/checkout/customer/' + page;
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for get all stores, regions and shipping info
	 */
	getListsInitShippingInfo(orderId: string) {
		const url = environment.base_url + '/v1.0.0/checkout/shipping-Info/' + orderId;
		return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest to update shipping address
	 * @param customer_id Customer ID
	 * @param shippingAddress Shipping address data
	 */
	updateShippingAddress(customer_id: string, shippingAddress: ShippingAddressModel) {
		let shippingAddressBody = {
			shipping_address: shippingAddress
		}
		const body = JSON.stringify(shippingAddressBody);
		const url = environment.base_url + '/v1.0.0/checkout/shipping-address/' + customer_id;
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest to delete shipping address
	 * @param customer_id Customer ID
	 * @param address_id Address ID
	 */
	deleteShippingAddress(customer_id: string, address_id: string) {
		let params = {
			address_id: address_id
		};
		const url = environment.base_url + '/v1.0.0/checkout/shipping-address/' + customer_id;
		return this.http.delete(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON, null, params)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest to update delivery time
	 * @param customer_id Customer ID
	 * @param shippingAddress Shipping address data
	 */
	updateDeliveryTime(deliveryTime: ShoppingCartDeliveryTimeModel) {
		const body = JSON.stringify(deliveryTime);
		const url = environment.base_url + '/v1.0.0/checkout/delivery-time';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest to update shipping store
	 * @param shipping_method 
	 */
	updateShippingStore(shipping_method: any) {
		let shoppingCart = {
			shipping_method: shipping_method
		}
		const body = JSON.stringify(shoppingCart);
		const url = environment.base_url + '/v1.0.0/checkout/shipping-store';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for update payment document
	 * @param shoppingcart email
	 */
	updatePaymentDocument(payment_document) {
		let shoppingCart = {
			payment_document: payment_document
		}
		const body = JSON.stringify(shoppingCart);
		const url = environment.base_url + '/v1.0.0/checkout/payment-document';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for get payment methods list
	 */
	getPaymentMethods() {
		const url = environment.base_url + '/v1.0.0/payment-method/';
		return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for update payment method
	 * @param shoppingcart 
	 */
	updatePaymentMethod(shoppingcart: ShoppingCartModel) {
		let paymentMethod = {
			payment_method: shoppingcart.payment_method,
			accepted_policies: shoppingcart.accepted_policies
		}

		const body = JSON.stringify(paymentMethod);
		const url = environment.base_url + '/v1.0.0/checkout/payment-method';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}


	updateShoppingCartStatus(shoppingcart: ShoppingCartModel) {
		let scart = {
			shipping_amount: shoppingcart.shipping_amount,
			igv_amount: shoppingcart.igv_amount,
			total_amount: shoppingcart.total_amount,
			accepted_policies: shoppingcart.accepted_policies
		}
		const body = JSON.stringify(scart);
		const url = environment.base_url + '/v1.0.0/checkout/shopping-cart';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest to delete product from cart
	 * @param product_code Product Code
	 */
	deleteProduct(product_code: string) {
		const url = environment.base_url + '/v1.0.0/checkout/product/' + product_code;
		return this.http.delete(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for get bank account list
	 */
	getBankAccountsList() {
		const url = environment.base_url + '/v1.0.0/payment-method/bank-account/';
		return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	callAdapter(adapter_name: string, entity: any) {
		let adapter = {
			adapter_name: adapter_name
		};
		if (entity) adapter['entity'] = entity;

		const body = JSON.stringify(adapter);
		const url = environment.base_url + '/v1.0.0/checkout/execute-adapter';
		return this.http.post(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for update order based on metadata
	 * @param shoppingcart email
	 */
	updateOrderByDetail(sender_id: string, token: string, detail: ShoppingCartDetailModel[], service_key: string) {
		let shoppingCart = {
			sender_id: sender_id,
			detail: detail,
			service_key: service_key
		}
		if (token) shoppingCart['token'] = token;
		const body = JSON.stringify(shoppingCart);
		const url = environment.base_url + '/v1.0.0/checkout/product-detail';
		return this.http.post(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for update currency
	 */
	updateCurrency(currency: CurrencyModel) {
		const body = JSON.stringify(currency);
		const url = environment.base_url + '/v1.0.0/checkout/currency';
		return this.http.put(url, body, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for create two factor code for indetify customer
	 * @param mail mail
	 */
	generateTwoFactorCode(mail: string) {
		const url = environment.base_url + '/v1.0.0/checkout/create-two-factor/' + mail;
		return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}

	/**
	 * Call API Rest for verify two factor code for indetify customer
	 * @param code code
	 */
	verifyTwoFactorCode(code: string) {
		const url = environment.base_url + '/v1.0.0/checkout/verify-two-factor/' + code;
		return this.http.get(url, HttpUtil.getHttpOptionsWithTokenV2(EnumType.ContentType.JSON)).pipe(
			map(HttpUtil.extractData),
			catchError(HttpUtil.handleErrorObservable)
		);
	}
}

<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title | labelTranslator }}
	</div>

	<div class="dcard">
		<div class="pb-2 d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1">{{ 'BOLETA' | labelTranslator }}</div>
			</div>
			<div class="d-flex flex-column">
				<button type="button" class="btn btn-block"
					[ngClass]="{'btn-primary': shoppingCart.payment_document.type == 'BOLETA', 'btn-only-border': shoppingCart.payment_document.type != 'BOLETA' }"
					(click)="selectPaymentDocument('BOLETA')" data-isbusy="false">
					<i class="fa fa-check" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		<div class="pb-2 d-flex flex-row">
			<div class="d-flex flex-fill flex-column">
				<div class="dfont dfont-bold title-card pb-1">{{ 'FACTURA' | labelTranslator }}</div>
			</div>
			<div class="d-flex flex-column">
				<button type="button" class="btn btn-block"
					[ngClass]="{'btn-primary': shoppingCart.payment_document.type == 'FACTURA', 'btn-only-border': shoppingCart.payment_document.type != 'FACTURA' }"
					(click)="selectPaymentDocument('FACTURA')" data-isbusy="false">
					<i class="fa fa-check" aria-hidden="true"></i>
				</button>
			</div>
		</div>
		<ng-container *ngIf="shoppingCart.payment_document.type === 'FACTURA'">
			<div class="container-fluid p-0 animated fadeIn">
				<div class="row" [formGroup]="paymentFormGroup">
					<div class="col">
						<div class="form-group">
							<label for="lblInputPaymentDocument_1"
								class="dfont dfont-bold text-uppercase">{{ 'Razón Social' | labelTranslator }}
								*</label>
							<div class="input-group">
								<input id="lblInputPaymentDocument_1" type="text" class="form-control"
									[(ngModel)]="shoppingCart.payment_document.company" formControlName="company"
									placeholder="{{ 'Razón Social' | labelTranslator }}"
									[ngClass]="{'is-invalid': paymentFormGroup.controls.company.invalid && paymentFormGroup.controls.company.dirty}"
									(keyup.enter)="focusInputField($event)" uppercase>
								<div class="form-control-input" *ngIf="shoppingCart.payment_document.company != ''"
									(click)="shoppingCart.payment_document.company = '';focusTarget('#lblInputPaymentDocument_1')">
									<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="lblInputPaymentDocument_2"
								class="dfont dfont-bold text-uppercase">{{ 'RUC' | labelTranslator }}
								*</label>
							<div class="input-group">
								<input id="lblInputPaymentDocument_2" type="text" integers class="form-control"
									formControlName="ruc" [(ngModel)]="shoppingCart.payment_document.ruc_number"
									placeholder="{{ 'RUC' | labelTranslator }}"
									[ngClass]="{'is-invalid': paymentFormGroup.controls.ruc.invalid && paymentFormGroup.controls.ruc.dirty}"
									(keyup.enter)="focusInputField($event)" uppercase>
								<div class="form-control-input" *ngIf="shoppingCart.payment_document.ruc_number != ''"
									(click)="shoppingCart.payment_document.ruc_number = '';focusTarget('#lblInputPaymentDocument_2')">
									<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
								</div>
							</div>
						</div>
						<div class="form-group mb-0">
							<label for="lblInputPaymentDocument_3"
								class="dfont dfont-bold text-uppercase">{{ 'Dirección legal' | labelTranslator}}
								*</label>
							<div class="input-group">
								<input id="lblInputPaymentDocument_3" type="text" class="form-control"
									formControlName="address" [(ngModel)]="shoppingCart.payment_document.address"
									placeholder="{{ 'Dirección legal' | labelTranslator}}"
									[ngClass]="{'is-invalid': paymentFormGroup.controls.address.invalid && paymentFormGroup.controls.address.dirty}"
									(keyup.enter)="focusInputField($event)" uppercase>
								<div class="form-control-input" *ngIf="shoppingCart.payment_document.address != ''"
									(click)="shoppingCart.payment_document.address = '';focusTarget('#lblInputPaymentDocument_3')">
									<i class="fa fa-lg fa-times-circle " aria-hidden="true"></i>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnProcess" type="button" class="btn btn-primary btn-block my-2 my-2"
					[ngClass]="{'btn-disabled': progressButton.process === true}" data-isbusy="false"
					(click)="savePaymentDocument()"
					[disabled]="shoppingCart.payment_document.type === 'FACTURA' && !paymentFormGroup.valid">
					<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
					{{ 'CONTINUAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">{{ 'MENSAJE' | labelTranslator }}</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError | labelTranslator }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					{{ 'CERRAR' | labelTranslator }}
				</button>
			</div>
		</div>
	</div>
</div>
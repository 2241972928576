import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ShoppingCartModel, ShippingAddressModel } from '../../../../models/shopping-cart';
import { CheckoutService } from '../../../../services/checkout/checkout.service';
import { ShippingRegionsModel } from '../../../../models/shipping-regions';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import * as lodash from 'lodash';
import { ProgressBarService } from '../../../../services/progress-bar/progress-bar.service';
declare let $: any;

@Component({
	selector: 'app-checkout-shipping-address',
	templateUrl: './checkout-shipping-address.page.html',
	styleUrls: ['./checkout-shipping-address.page.scss']
})
export class CheckoutShippingAddressPageComponent implements OnInit, OnDestroy {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	shippingAddress: ShippingAddressModel = new ShippingAddressModel();
	shippingAddressList: ShippingAddressModel[] = new Array<ShippingAddressModel>();
	shippingAddressFormGroup: FormGroup;

	storeList: any = Array<any>(); // REMOVE
	regionList: ShippingRegionsModel[] = Array<ShippingRegionsModel>();
	provinceList: any[] = Array<any>();
	districtList: any[] = Array<any>();

	progressBar: any = {
		list_page: true
	};

	// ProgressButton
	progressButton: any = {
		process: false
	};

	messageError: string = undefined;

	page_title: string = "";

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private checkoutService: CheckoutService,
		private route: ActivatedRoute,
		private progressbarService: ProgressBarService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.route.params.subscribe(
			params => {

				this.shippingAddressList = this.checkoutService.getShippingAddressList();

				let findIndexIndex: number = lodash.findIndex(this.shippingAddressList, ['address_id', params['addressId']]);
				if (findIndexIndex != -1) {
					this.shippingAddress = this.shippingAddressList[findIndexIndex];
				} else {
					this.shippingAddress.address_id = params["addressId"]
					this.shippingAddress.address = '';
					this.shippingAddress.department = '';
					this.shippingAddress.province = '';
					this.shippingAddress.district = '';
					this.shippingAddress.references = '';
					this.shippingAddress.receiver = {
						document_number: '',
						full_name: '',
						phone_number: '',
					};
				}

				this.initForm();
			}
		);
		this.shoppingCart = this.checkoutService.getShoppingCart();
		this.progressBar.list_page = false;
		this.progressbarService.setVisibility(this.progressBar.list_page);
	}

	ngOnInit() {
		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {
			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.register_address;
			}
			this.appendModalsToBody();
			this.getListsInit();
			setTimeout(() => {
				$("#lblInputShippingAddress_1").focus();
			}, 100);

		}
	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
	}

	initForm() {
		this.shippingAddressFormGroup = this.formBuilder.group({
			address: ['', Validators.required],
			addressReference: [''],
			department: ['', Validators.required],
			province: ['', Validators.required],
			district: ['', Validators.required],
			fullName: ['', Validators.required],
			documentNumber: ['', Validators.required],
			phoneNumber: [''],
		});
	}

	validateForm() {

		if (this.shippingAddressFormGroup.valid) {

			let btn = $('#btnSaveShippingAddress');
			if (btn.data('isbusy') === false) {
				btn.data('isbusy', true);
				this.progressButton.process = true;
				this.checkoutService.updateShippingAddress(this.shoppingCart.customer.customer_id, this.shippingAddress).subscribe(
					(response: any) => {

						if (response.header.status) {
							this.router.navigate(['/checkout/detail']);
						} else {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}

					},
					(error) => {
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = error.description;
						$('#modalMessage').modal('show');
						console.log('error => ', error);
					}
				);

			}

		} else {
			for (const field in this.shippingAddressFormGroup.controls) {

				this.shippingAddressFormGroup.get(field).markAsDirty({ onlySelf: true });
			}
		}

	}

	getListsInit() {
		this.checkoutService.getListsInitShippingInfo(this.shoppingCart.order_id).subscribe(
			(response: any) => {
				if (response.header.status) {
					this.storeList = response.body.listStores;
					this.regionList = response.body.listRegions;
					this.onChangeDepartment();
					setTimeout(() => {
						this.onChangeProvince();
					}, 100);

				} else {

				}
				this.progressBar.list_page = false;
				this.progressbarService.setVisibility(this.progressBar.list_page);
			},
			(err) => {
				this.progressBar.list_page = false;
				this.progressbarService.setVisibility(this.progressBar.list_page);
				console.log(err);
			}
		);
	}

	onChangeDepartment() {
		if (this.regionList != undefined && this.regionList.length > 0) {
			let selectedRegion = lodash.find(this.regionList, ['code', this.shippingAddress.department]);
			if (selectedRegion != undefined) {
				this.provinceList = selectedRegion.provinces;
			} else {
				this.provinceList = [];
				this.districtList = [];
				this.shippingAddress.province = '';
				this.shippingAddress.district = '';
			}

		}
	}

	onChangeProvince() {
		let selectedDistrict = lodash.find(this.provinceList, ['code', this.shippingAddress.province]);
		if (selectedDistrict != undefined) {
			this.districtList = lodash.get(selectedDistrict, 'district');
		} else {
			this.districtList = [];
			this.shippingAddress.district = '';
		}
	}

	focusTarget(id: String) {
		$(id).focus();
	}

	focusInputField(event: Event) {
		let id: string = (event.target as Element).id;
		if (id == "lblInputShippingAddress_1") {
			$("#lblInputShippingAddress_2").focus();
		} else if (id == "lblInputShippingAddress_2") {
			$("#lblInputShippingAddress_3").focus();
		} else if (id == "lblInputShippingAddress_3") {
			$("#lblInputShippingAddress_4").focus();
		} else if (id == "lblInputShippingAddress_4") {
			$("#lblInputShippingAddress_5").focus();
		}
	}

}

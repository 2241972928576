<ng-container [ngSwitch]="template">
  <ng-container *ngSwitchCase="'list'">
    <div class="lg-empty">
      <div class="lg-content-header-empty"></div>
      <div class="lg-content-body-empty">
        <ng-container *ngFor="let rowIndex of indexArray">
          <div class="lg-content-body-item-empty"></div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'card'">
    <div class="card-empty">
      <ng-container *ngFor="let rowIndex of indexArray">
        <div class="card-content-empty"></div>
      </ng-container>
      <!-- <div class="lg-content-body-empty">
        
      </div> -->
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <!-- Template not defined -->
  </ng-container>
</ng-container>
/**
 * @license
 * Copyright Innovaris IT SAC. All Rights Reserved.
 *
 * The following source code is exclusive property of Innovaris IT SAC. 
 * It cannot be copied, backed up or reproduced without explicit authorization,
 * any lead of misusage will be treated according to Perú Laws.
 */
import { environment } from '../../environments/environment';
import { CryptoUtil } from './crypto-util';
import { CompanyModel } from '../models/company';
import { EnumType } from '../models/enums/enum';

export class StorageUtil {

    /**
     * Stores in localStorage the value
     * @param key Key that will contain the stored value
     * @param value Value that will be stored
     * @param encrypt Specifies if value needs to be encrypted
     */
    static set(key: string, value: any, encrypt: boolean = false, isString: boolean = false) {
        if (!isString) value = JSON.stringify(value);                               // Converts to string if is not that type
        if (encrypt) value = CryptoUtil.encrypt(value, environment.bcrypt_salt);    // Encrypts value if flag sent
        localStorage.setItem(key, value);                                           // Stores value
    }

    /**
     * Retrieves from localStorage a value based on its key
     * @param key Key to find value
     * @param decrypt Specifies if value needs decryption
     * @param isObject Specifies if value should return as object
     */
    static get(key: string, decrypt: boolean, isString: boolean = false) {
        let value = localStorage.getItem(key);
        if (value) {
            if (decrypt) value = CryptoUtil.decrypt(value, environment.bcrypt_salt);
            if (isString)
                return value;
            else
                return JSON.parse(value);
        }
        else
            return undefined
    }

    /**
     * Retrieves from localStorage a value based on its key
     * @param key Key to find value
     * @param decrypt Specifies if value needs decryption
     * @param isObject Specifies if value should return as object
     */
    static getCompany(key: string, decrypt: boolean, isString: boolean = false) {
        let value = localStorage.getItem(key);
        if (value) {
            if (decrypt) value = CryptoUtil.decrypt(value, environment.bcrypt_salt);
            if (isString)
                return value;
            else
                return JSON.parse(value);
        }
        else
            return undefined;
    }

    /**
     * Retrieves from localStorage a value based on its key
     * @param key Key to find value
     * @param decrypt Specifies if value needs decryption
     * @param isObject Specifies if value should return as object
     */
    static getCompanyWithoutParams() {
        let value = localStorage.getItem(EnumType.LocalStorage.COMPANY);
        if (value) {
            if (environment.storage_encrypt_flag) value = CryptoUtil.decrypt(value, environment.bcrypt_salt);
            // if (isString)
            //     return value;
            else
                return JSON.parse(value);
        }
        else
            return undefined;
    }

    /**
     * Retrieves account string based on company selected
     */
    static getAccountString() {
        let company: CompanyModel = StorageUtil.getCompany(EnumType.LocalStorage.COMPANY, environment.storage_encrypt_flag);
        let accountString: string;
        if (company) {
            environment.companies.forEach(item => {
                if (company.name == item.name) {
                    accountString = item.account_string;
                }
            });
        } else {
            accountString = undefined;
        }
        return accountString;
    }

    /**
     * Removes the key/value from localStorage
     * @param key Key to be removed with its value
     */
    static remove(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * Clears the whole localStorage
     */
    static clear() {
        localStorage.clear();
    }

}

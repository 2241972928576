import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetadataService } from '../../services/metadata/metadata.service';
import { MetadataTemplateModel } from '../../models/metadata-template';
import { ShoppingCartModel } from '../../models/shopping-cart';
import { CheckoutService } from '../../services/checkout/checkout.service';
import { EcommerceRulesService } from '../../services/ecommerce-rules/ecommerce-rules.service';
import { EcommerceRulesModel } from '../../models/ecommerce-rules';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { EnumType } from '../../models/enums/enum';
import { DateUtil } from '../../utils/date-util';
import * as lodash from 'lodash';
import { ProgressBarService } from '../../services/progress-bar/progress-bar.service';
declare let $: any;

@Component({
	selector: 'app-metadata',
	templateUrl: './metadata.page.html',
	styleUrls: ['./metadata.page.scss']
})
export class MetadataPageComponent implements OnInit, OnDestroy {

	page_title: string = "";
	fields: MetadataTemplateModel[] = Array<MetadataTemplateModel>();
	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	// ruler for indicate if required search data in external system
	// if metadata_integration_search.value is true, in the property "specs" specify the adapter name to use
	metadata_integration_search: EcommerceRulesModel = new EcommerceRulesModel();
	// ruler for indicate if required save metadata in external system
	// if metadata_integration_save.value is true, in the property "specs" specify the adapter name to use  
	metadata_integration_save: EcommerceRulesModel = new EcommerceRulesModel();
	product_code: string = '';
	entity: any = {};
	progressButton = {
		process: false
	}
	progressBar: any = {
		list_page: true
	};
	// metadata_is_ready: boolean = false;
	messageError: string = undefined;
	template_name: string = "";

	metadata_integration_search_complete: string = '';

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private metadataService: MetadataService,
		private checkoutService: CheckoutService,
		private spinnerService: Ng4LoadingSpinnerService,
		private ecommerceRulesService: EcommerceRulesService,
		private progressbarService: ProgressBarService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
	}

	ngOnInit() {

		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {
			$('#modalMessage').appendTo('body');

			if (this.checkoutService.getShoppingCart() && this.checkoutService.getShoppingCart().order_detail) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.metadata;

				this.getRules();
			} else {

				this.progressbarService.setVisibility(false);
				this.router.navigate(['/checkout/cart']);
			}
		}
	}

	ngOnDestroy() {
		$('body > #modalMessage').remove();
	}

	getRules() {
		this.ecommerceRulesService.getRulesBypage(EnumType.Pages.METADATA_FORM).subscribe(
			(response: any) => {

				if (response.header.status) {

					let metadata_search: any = lodash.find(response.body, { name: EnumType.EcommerceRules.METADATA_INTEGRATION_SEARCH });
					let metadata_save: any = lodash.find(response.body, { name: EnumType.EcommerceRules.METADATA_INTEGRATION_SAVE });

					if (metadata_search && metadata_save) {
						this.metadata_integration_search = metadata_search;
						this.metadata_integration_save = metadata_save;

						this.route.params.subscribe(
							params => {
								this.product_code = params["itemId"];

								this.template_name = params["templateName"];
								this.getFieldOfMetadata();
							}
						);
					} else {

					}
				} else {

				}
			},
			(err) => {
				console.log(err);
			}
		);
	}

	/**
	 * Get metadata fields
	 */
	getFieldOfMetadata() {
		// this.metadata_is_ready = false;
		this.metadataService.getFieldOfMetadata(this.template_name, this.product_code).subscribe(
			(response: any) => {
				if (response.header.status) {
					this.fields = response.body;

					// verify if the metadata was registered
					let index = lodash.findIndex(this.shoppingCart.order_detail, { product_code: this.product_code });

					// If exists metadata detail, fill saved fields
					if (index != -1 && this.shoppingCart.order_detail[index].metadata_detail) {
						this.metadata_integration_search_complete = 'Complete';
						// Fill saved fields
						this.fields.forEach(item => {
							if (item.type_data == "date") {
								let birthdate = this.shoppingCart.order_detail[index].metadata_detail[item.name].split('/');
								let dt_birthdate = new Date(birthdate[2], parseInt(birthdate[1]) - 1, birthdate[0]);

								item.value_date = dt_birthdate;
							} else {
								item.value = this.shoppingCart.order_detail[index].metadata_detail[item.name];
							}

							if (item.children && item.children.length > 0) {
								// Iterate all children
								for (let i = 0; i < item.children.length; i++) {

									// get index of the child that will affect this event
									let index_child = lodash.findIndex(this.fields, { name: item.children[i] });
									if (index_child != -1) {

										// Evaluate dependencies
										for (let j = 0; j < this.fields[index_child].dependencies.length; j++) {

											if (this.fields[index_child].dependencies[j].type == 'loading') {

												let entity = { value: item.value };
												this.checkoutService.callAdapter(this.fields[index_child].adapter, entity).subscribe(
													(response: any) => {
														if (response.header.status && response.body && response.body.length > 0) {
															this.fields[index_child].selectable_values = response.body;
														}
														this.progressBar.list_page = false;
														this.progressbarService.setVisibility(this.progressBar.list_page);
													},
													(err) => {
														console.log(err);
														this.progressBar.list_page = false;
														this.progressbarService.setVisibility(this.progressBar.list_page);
													}
												);
											}
										}
									}

								}
							}
						});
					} else {
						// Fill dropdown with first value as default
						this.fields.forEach(item => {

							if (item.type_data == 'dropdown' && item.value == '') {
								item.value = item.selectable_values.length > 0 ? item.selectable_values[0].value : '';
							} else if (item.value && item.value != '') {
								if (item.children && item.children.length > 0) {
									// Iterate all children
									for (let i = 0; i < item.children.length; i++) {

										// get index of the child that will affect this event
										let index_child = lodash.findIndex(this.fields, { name: item.children[i] });

										if (index_child != -1) {

											// Evaluate dependencies
											for (let j = 0; j < this.fields[index_child].dependencies.length; j++) {

												if (this.fields[index_child].dependencies[j].type == 'loading') {

													//this.callNestedLoading(index_parent, index_child);
													let entity = { value: item.value };

													this.checkoutService.callAdapter(this.fields[index_child].adapter, entity).subscribe(
														(response: any) => {
															if (response.header.status && response.body && response.body.length > 0) {
																this.fields[index_child].selectable_values = response.body;

															}
															this.progressBar.list_page = false;
															this.progressbarService.setVisibility(this.progressBar.list_page);
														},
														(err) => {
															console.log(err);
															this.progressBar.list_page = false;
															this.progressbarService.setVisibility(this.progressBar.list_page);
														}
													);
												}
											}
										}

									}
								}
							}
						});
					}

					let index_focus = -1;
					// NOTE: in the API REST is the process that adds values ​​to tag 'children', 
					// this array will be used for evaluate dependency between controls when executing the events					
					// Iterate fields to evaluate their dependencies of visibility or required.
					this.fields.forEach((item, index) => {

						if (index_focus == -1 && (item.type_data == 'text' || item.type_data == 'numeric')) index_focus = index;

						let evaluate_visibility = false; // variable for know if is necessary affect the visibility of the field
						let is_visible = false;

						let evaluate_mandatory = false; // variable for know if is necessary affect the mandatory of the field
						let is_mandatory = false;

						item.dependencies.forEach(dependency => {
							if (item.name == 'location') {
								console.log(item);
							}
							if (dependency.name == '' && dependency.type == 'visibility') {
								evaluate_visibility = true;
								switch (dependency.logic_function) {
									case 'and':
										let result = this.evaluateDependencyAND(dependency.rules);

										if (result) {
											is_visible = true;
										}
										break;
									case 'or':
										if (this.evaluateDependencyOR(dependency.rules)) is_visible = true;
										break;
								}
							}

							if (dependency.name == '' && dependency.type == 'mandatory') {
								evaluate_mandatory = true;
								switch (dependency.logic_function) {
									case 'and':
										if (this.evaluateDependencyAND(dependency.rules)) is_mandatory = true;
										break;
									case 'or':
										if (this.evaluateDependencyOR(dependency.rules)) is_mandatory = true;
										break;
								}
							}
						});

						if (evaluate_visibility) item.is_visible = is_visible;

						if (evaluate_mandatory) item.required = is_mandatory;
					});

					if (index_focus != -1) setTimeout(() => { $("#lblInputInfo_" + index_focus).focus(); console.log(index_focus); }, 1000)

					if (this.progressBar.list_page) {
						this.progressBar.list_page = false;
						this.progressbarService.setVisibility(this.progressBar.list_page);
					}
				}
			},
			(err) => {
				this.messageError = err.description;
				$('#modalMessage').modal('show');
			}
		);
	}

	/**
	 * Save metadata in detail and send to system assigned
	 */
	saveMetadata() {

		this.entity = {};
		let isValid = true;
		this.progressButton.process = true;
		let btn = $('#btnSaveMetadata');
		if (btn.data('isbusy') === false) {
			btn.data('isbusy', true);
			// build object for send to api
			this.fields.forEach((item, index) => {
				$("#lblInputInfo_" + index).removeClass('is-invalid');
				if (item.type_data != "date" && item.is_visible && item.required && (!item.value || item.value === '')) {
					isValid = false;
					$("#lblInputInfo_" + index).addClass('is-invalid');
				} else if (item.type_data == "date" && item.is_visible && item.required && !item.value_date) {
					isValid = false;
					$("#lblInputInfo_" + index).addClass('is-invalid');
				} else {
					if (item.is_visible && item.required && item.regular_expression) {
						let value_regex = new RegExp(item.regular_expression);
						if (value_regex.test(item.value)) {
							this.entity[item.name] = item.value;
						} else {
							this.messageError = "Por favor ingresar un dato validato para el campo: " + this.entity[item.name].text_label;
							isValid = false;
							$("#modalMessage").modal("show");
						}
					} else {
						if (item.type_data == "date") this.entity[item.name] = DateUtil.getFormattedDate(new Date(item.value_date), "DD/MM/YYYY");
						else this.entity[item.name] = item.is_visible ? item.value.toString() : '';
					}
				}
			});

			if (this.metadata_integration_search.value && this.metadata_integration_search_complete == '') {
				console.log("ingreso");
				this.searchMetadata();
				isValid = false;
				// this.messageError = "Por favor completar los datos solicitados";
				// $("#modalMessage").modal("show");
			}

			if (isValid) {
				this.metadataService.updateMetadataByOrderDetail(this.product_code, this.entity).subscribe(
					(response: any) => {
						if (response.header.status) {

							if (this.metadata_integration_save.value === "true") {
								// Save metadata on external system
								let spec: any = lodash.find(this.metadata_integration_save.specs, (r) => { return r.value.toString() == this.metadata_integration_save.value.toString() });
								this.checkoutService.callAdapter(spec.adapter_save, this.entity).subscribe(
									(response: any) => {
										if (response.header.status) {
											// Save metadata assigned values in form
											let indexDetail = lodash.findIndex(this.shoppingCart.order_detail, ['product_code', this.product_code]);
											let metadata_detail = {};
											this.fields.forEach((field) => {
												metadata_detail[field.name] = field.value;
											});
											// Save metadata in the corresponding detail
											this.shoppingCart.order_detail[indexDetail].metadata_detail = metadata_detail;
											this.checkoutService.setShoppingCart(this.shoppingCart);
											this.router.navigate(['/checkout/cart']);
										}
									},
									(err) => {
										console.log(err);
										this.router.navigate(['/checkout/cart']);
									}
								);
							} else {
								// Save metadata assigned values in form
								let indexDetail = lodash.findIndex(this.shoppingCart.order_detail, ['product_code', this.product_code]);
								let metadata_detail = {};
								this.fields.forEach((field) => {
									metadata_detail[field.name] = field.value;
								});
								// Save metadata in the corresponding detail
								this.shoppingCart.order_detail[indexDetail].metadata_detail = metadata_detail;
								this.checkoutService.setShoppingCart(this.shoppingCart);
								this.router.navigate(['/checkout/cart']);
							}

						} else {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}
					},
					(err) => {
						console.log(err);
						this.messageError = err.description;
						$('#modalMessage').modal('show');
						this.progressButton.process = false;
						btn.data('isbusy', false);
					}
				);
			} else {
				this.progressButton.process = false;
				btn.data('isbusy', false);
			}
		}
	}

	/**
	 * Evaluate each field if you must run an event
	 * @param item MetadataTemplateModel
	 * @param index_parent Index of array fields
	 * @param search If you should look metadata
	 */
	executeEvent(item: MetadataTemplateModel, index_parent: number) {
		console.log(index_parent);
		// Evaluate field for know if necessary execute event for find metadata in external system
		let index = lodash.findIndex(this.metadata_integration_search.adapter_fields, function (o) { return o == item.name });
		if (index != -1) {
			this.searchMetadata();
		}

		// if you have dependent controls
		if (item.children && item.children.length > 0) {

			// Iterate all children
			for (let i = 0; i < item.children.length; i++) {

				// get index of the child that will affect this event
				let index_child = lodash.findIndex(this.fields, { name: item.children[i] });

				if (index_child != -1) {

					// Evaluate dependencies
					for (let j = 0; j < this.fields[index_child].dependencies.length; j++) {

						if (this.fields[index_child].dependencies[j].name != '' && this.fields[index_child].is_visible && this.fields[index_child].dependencies[j].type == 'loading') {
							this.callNestedLoading(index_parent, index_child);
						}

						if (this.fields[index_child].dependencies[j].name != '' && this.fields[index_child].dependencies[j].type == 'visibility') {
							this.callNestedVisibility(index_child, this.fields[index_child].dependencies[j]);
						}

						if (this.fields[index_child].dependencies[j].name != '' && this.fields[index_child].dependencies[j].type == 'mandatory') {
							this.callNestedMandatory(index_child, this.fields[index_child].dependencies[j]);
						}

					}
				}

			}
		}

		if (index_parent + 1 <= this.fields.length) {
			$("#lblInputInfo_" + (index_parent + 1)).focus();
		}
	}

	/**
	 * Search Metadata in e-commerce local or external system depending on the rules
	 */
	searchMetadata() {
		let entity = {};
		let complete_fields = 0;

		// create entity for send to adapter, iterate the adapter_fields of the rule
		this.metadata_integration_search.adapter_fields.forEach(field => {

			// get index field of the metadata template
			let index = lodash.findIndex(this.fields, { name: field });

			// if index exists, set value to entity
			if (index != -1) {
				if (this.fields[index].value && this.fields[index].value != "") {
					entity[field] = this.fields[index].value;
					// add counter for complete_fields
					complete_fields++;
				}
			}

		});

		// Call external system
		if (this.metadata_integration_search.adapter_fields.length == complete_fields) {
			this.spinnerService.show();
			let spec = lodash.find(this.metadata_integration_search.specs, (r) => { return r.value.toString() == this.metadata_integration_search.value.toString() });

			this.checkoutService.callAdapter(spec.adapter_find, entity).subscribe(
				(response: any) => {
					this.metadata_integration_search_complete = 'Complete';
					let keys = [];
					if (response.header.status && response.body && response.body.length > 0) {
						keys = Object.keys(response.body[0]);
					}

					// Iterate fields to evaluate their dependencies of visibility or required.
					this.fields.forEach(item => {

						let evaluate_visibility = false; // variable for know if is necessary affect the visibility of the field
						let is_visible = false;

						let evaluate_mandatory = false; // variable for know if is necessary affect the mandatory of the field
						let is_mandatory = false;

						item.dependencies.forEach(dependency => {
							if (dependency.name == '' && dependency.type == 'visibility') {
								evaluate_visibility = true;
								switch (dependency.logic_function) {
									case 'and':
										if (this.evaluateDependencyAND(dependency.rules)) is_visible = true;
										break;
									case 'or':
										if (this.evaluateDependencyOR(dependency.rules)) is_visible = true;
										break;
								}
							}

							if (dependency.name == '' && dependency.type == 'mandatory') {
								evaluate_mandatory = true;
								switch (dependency.logic_function) {
									case 'and':
										if (this.evaluateDependencyAND(dependency.rules)) is_mandatory = true;
										break;
									case 'or':
										if (this.evaluateDependencyOR(dependency.rules)) is_mandatory = true;
										break;
								}
							}
						});

						if (evaluate_visibility) {
							item.is_visible = is_visible;
							if(keys.length == 0) {
								if(item.type_data == "date")
									item.value_date = undefined;
								else
									item.value = '';
							};
						}

						if (evaluate_mandatory) item.required = is_mandatory;
					});

					keys.forEach(key => {
						let index = lodash.findIndex(this.fields, { name: key });
						if (index != -1) {

							if (this.fields[index].type_data == "date") {
								if (response.body[0][key]) {
									let birthdate = response.body[0][key].split('/');
									let dt_birthdate = new Date(birthdate[2], parseInt(birthdate[1]) - 1, birthdate[0]);

									this.fields[index].value_date = dt_birthdate;
								}


							} else {
								// item.value = this.shoppingCart.order_detail[index].metadata_detail[item.name];
								this.fields[index].value = response.body[0][key];
							}

							// If exists value, verify dependencies
							if (this.fields[index].value && this.fields[index].value != '' && this.fields[index].children && this.fields[index].children.length > 0) {
								// Iterate all children
								for (let i = 0; i < this.fields[index].children.length; i++) {

									// get index of the child that will affect this event
									let index_child = lodash.findIndex(this.fields, { name: this.fields[index].children[i] });
									if (index_child != -1) {

										// Evaluate dependencies
										for (let j = 0; j < this.fields[index_child].dependencies.length; j++) {

											if (this.fields[index_child].is_visible && this.fields[index_child].dependencies[j].type == 'loading') {

												let entity = { value: this.fields[index].value };
												this.checkoutService.callAdapter(this.fields[index_child].adapter, entity).subscribe(
													(response: any) => {
														if (response.header.status && response.body && response.body.length > 0) {
															this.fields[index_child].selectable_values = response.body;
														}
													},
													(err) => {
														console.log(err);
													}
												);
											}
										}
									}

								}
							}
						}

					});
					this.spinnerService.hide();
					console.log(this.fields);
				},
				(err) => {
					console.log(err);
					this.spinnerService.hide();
				}
			);

		}

	}

	/**
	 * Get values for fields nested with dependency 'loading'
	 * @param index_parent index of parent field
	 * @param index_child index of child field 
	 */
	callNestedLoading(index_parent: number, index_child: number) {
		this.spinnerService.show();

		this.fields[index_child].selectable_values = [];

		let entity = { value: this.fields[index_parent].value };

		this.checkoutService.callAdapter(this.fields[index_child].adapter, entity).subscribe(
			(response: any) => {
				if (response.header.status && response.body && response.body.length > 0) {
					console.log(response.body);
					this.fields[index_child].selectable_values = response.body;
					this.fields[index_child].value = this.fields[index_child].selectable_values[0].value;
				}
				this.spinnerService.hide();
			},
			(err) => {
				console.log(err);
			}
		);

	}

	/**
	 * Get values for fields nested with dependency 'visibility'
	 * @param index_child index of field father
	 * @param dependency Array string with names of the children
	 */
	callNestedVisibility(index_child: number, dependency: any) {

		if (dependency && dependency.logic_function == 'and') {
			this.fields[index_child].is_visible = this.evaluateDependencyAND(dependency.rules);
		}

		if (dependency && dependency.logic_function == 'or') {
			this.fields[index_child].is_visible = this.evaluateDependencyOR(dependency.rules);
		}

	}

	/**
	 * Get values for fields nested with dependency 'mandatory'
	 * @param index_child index of field child
	 * @param dependency dependency
	 */
	callNestedMandatory(index_child: number, dependency: any) {

		if (dependency && dependency.logic_function == 'and') {
			this.fields[index_child].required = this.evaluateDependencyAND(dependency.rules);
		}

		if (dependency && dependency.logic_function == 'or') {
			this.fields[index_child].required = this.evaluateDependencyOR(dependency.rules);
		}

	}

	/**
	 * evaluate repedency rules regarding logical function "and"
	 */
	evaluateDependencyAND(rules: any) {
		let result = true;
		// Logical function 'AND': When exists only a rule false of the all collection, false return
		rules.forEach(rule => {

			let value;
			if (rule.source == 'shopping cart') {
				value = lodash.get(this.shoppingCart, rule.search_in_field);
			}

			if (rule.source == 'metadata template') {
				let field = lodash.find(this.fields, { name: rule.search_in_field });
				value = field ? field.value : '';
			}

			if (rule.source == 'shopping cart detail') {
				let detail = lodash.find(this.shoppingCart.order_detail, { product_code: this.product_code });
				value = lodash.get(detail, rule.search_in_field);
			}

			if (rule.source == 'metadata integration search') {
				value = this.metadata_integration_search_complete;
				// console.log('Value => ', this.metadata_integration_search_complete);
				// console.log('Rule => ', rule.value_to_compare);
			}

			if (rule.logical_operator == 'equals') {
				if (value != rule.value_to_compare) result = false;
			}

			if (rule.logical_operator == 'different') {
				if (value == rule.value_to_compare) result = false;
			}
		});

		return result;

	}

	/**
	 * evaluate repedency rules regarding logical function "or"
	 */
	evaluateDependencyOR(rules: any) {
		let result = false;
		// Logical function 'OR': all rules must be false to return false, otherwise return true
		rules.forEach(rule => {
			let value;
			if (rule.source == 'shopping cart') {
				value = lodash.get(this.shoppingCart, rule.search_in_field);
			}

			if (rule.source == 'metadata template') {
				let field = lodash.find(this.fields, { name: rule.search_in_field });
				value = field ? field.value : '';
			}

			if (rule.source == 'shopping cart detail') {
				let detail = lodash.find(this.shoppingCart.order_detail, { product_code: this.product_code });
				value = lodash.get(detail, rule.search_in_field);
			}

			if (rule.source == 'metadata integration search') {
				value = this.metadata_integration_search_complete;
			}

			// compare
			if (rule.logical_operator == 'equals') {
				if (value == rule.value_to_compare) result = true;
			}

			if (rule.logical_operator == 'different') {
				if (value != rule.value_to_compare) result = true;
			}
		});

		return result;

	}

	focusTarget(id: String) {
		$(id).focus();
	}
}

<div class="animated fadeIn slow" *ngIf="progressBar.list_page == false">

	<div class="content-title">
		{{ page_title }}
	</div>

	<div class="dcard p-0">
		<div class="container-fluid">
			<div class="row" [formGroup]="deliveryTimeFormGroup">
				<div class="col">
					<div class="form-group pt-1">
						<label for="lblInputDeliveryTime1" class="dfont dfont-bold text-uppercase">DÍA *</label>
						<select id="lblInputDeliveryTime1" class="form-control" formControlName="date"
							[(ngModel)]="deliveryTime.date" (change)="onChangeDay()"
							[ngClass]="{'is-invalid': deliveryTimeFormGroup.controls.date.invalid && deliveryTimeFormGroup.controls.date.dirty}">
							<option value="">SELECCIONAR</option>
							<option value="{{item}}" *ngFor="let item of dayList">{{item}}</option>
						</select>
					</div>
					<div class="form-group">
						<label for="lblInputDeliveryTime2" class="dfont dfont-bold text-uppercase">HORA *</label>
						<select id="lblInputDeliveryTime2" class="form-control" formControlName="schedule"
							[(ngModel)]="deliveryTime.schedule"
							[ngClass]="{'is-invalid': deliveryTimeFormGroup.controls.schedule.invalid && deliveryTimeFormGroup.controls.schedule.dirty}">
							<option value="">SELECCIONAR</option>
							<option value="{{item}}" *ngFor="let item of scheduleList">{{item}}</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		class="container-fluid offset-sm-4 offset-md-4 offset-lg-4 offset-xl-4 col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
		<div class="row">
			<div class="col p-0">
				<button id="btnSaveDeliveryTime" type="button" class="btn btn-primary btn-block my-2"
					(click)="validateForm()" [ngClass]="{'btn-disabled': progressButton.process === true}"
					data-isbusy="false">
					<div class="svg-icon-spinner" *ngIf="progressButton.process === true"></div>
					CONTINUAR
				</button>
			</div>
		</div>
	</div>

</div>

<div class="modal fade" id="modalMessage" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<div class="modal-title title-card">MENSAJE</div>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="form-row">
					<div class="form-group col-md-12 body-card-pending">
						<span>{{ messageError }} </span>
					</div>
				</div>
			</div>
			<div class="modal-footer mf-center">
				<button type="button" class="btn btn-only-border btn-sm" data-dismiss="modal" aria-label="Close">
					CERRAR
				</button>
			</div>
		</div>
	</div>
</div>
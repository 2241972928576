import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShoppingCartModel, CustomerModel } from '../../../models/shopping-cart';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { EnumType } from '../../../models/enums/enum';
import { ProgressBarService } from '../../../services/progress-bar/progress-bar.service';
declare let $: any;

@Component({
	selector: 'app-checkout-customer',
	templateUrl: './checkout-customer.page.html',
	styleUrls: ['./checkout-customer.page.scss']
})
export class CheckoutCustomerPageComponent implements OnInit, OnDestroy {

	shoppingCart: ShoppingCartModel = new ShoppingCartModel();
	customer: CustomerModel = new CustomerModel();
	infoFormGroup: FormGroup;
	progressBar: any = {
		list_page: false
	};
	progressButton: any = {
		process: false
	};
	messageError: string = undefined;
	page_title: string = "";

	constructor(
		private checkoutService: CheckoutService,
		private formBuilder: FormBuilder,
		private router: Router,
		private progressbarService: ProgressBarService
	) {
		this.progressbarService.setVisibility(this.progressBar.list_page);
		this.shoppingCart = this.checkoutService.getShoppingCart();
	}

	ngOnInit() {
		if (this.shoppingCart.order_number != 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/confirm']);

		} else if (this.shoppingCart.order_detail.length == 0) {
			this.progressbarService.setVisibility(false);
			this.router.navigate([this.checkoutService.getEcommerceParameters().session.page_redirect], { queryParams: { s: 'err' } });
		} else if (this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Visa' &&
			this.shoppingCart.payment_method.visa_net &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization &&
			!this.shoppingCart.payment_method.visa_net.transaction_authorization.errorCode &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header &&
			this.shoppingCart.payment_method.visa_net.transaction_authorization.header.ecoreTransactionUUID) {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else if (this.shoppingCart.order_number == 0 && this.shoppingCart.payment_method.code == 'PMIL' &&
			this.shoppingCart.payment_method.card.name == 'Mastercard' &&
			this.shoppingCart.payment_method.master_card &&
			this.shoppingCart.payment_method.master_card.transaction_result &&
			this.shoppingCart.payment_method.master_card.transaction_result.O1 == 'A' &&
			this.shoppingCart.payment_method.master_card.transaction_result.O16 == '00') {

			// Validate if order have a visa success transaction
			// If Exists, an error occurred during the purchase generation
			this.progressbarService.setVisibility(false);
			this.router.navigate(['/checkout/payment-error']);

		} else {
			this.progressBar.list_page = false;
			this.progressbarService.setVisibility(this.progressBar.list_page);
			this.initForm();
			this.appendModalsToBody();

			this.customer = this.shoppingCart.customer;
			this.customer.document_type = 'DNI';
			if (this.checkoutService.getEcommerceParameters() && this.checkoutService.getEcommerceParameters().pages_titles) {
				this.page_title = this.checkoutService.getEcommerceParameters().pages_titles.customer;
			}

			this.progressBar.list_page = false;
			this.validateForm();
			this.progressbarService.setVisibility(this.progressBar.list_page);
			setTimeout(() => {
				$("#lblInputInfo_1").focus();
			}, 100);
		}

	}

	ngOnDestroy() {
		this.removeModalsFromBody();
	}

	appendModalsToBody() {
		$('#modalMessage').appendTo('body');
	}

	removeModalsFromBody() {
		$('body > #modalMessage').remove();
	}

	initForm() {
		this.infoFormGroup = this.formBuilder.group({
			mail: ['', [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]],
			name: ['', Validators.required],
			lastName: ['', Validators.required],
			documentType: ['', Validators.required],
			documentNumber: ['', Validators.required],
			phoneNumber: ['', Validators.required],
		});
	}

	validateForm() {
		if (this.infoFormGroup.valid) {

			let btn = $('#btnSaveCustomer');
			if (btn.data('isbusy') === false) {
				btn.data('isbusy', true);
				this.progressButton.process = true;
				this.checkoutService.updateCustomer(this.customer, EnumType.Pages.CUSTOMER).subscribe(
					(response: any) => {
						if (response.header.status) {
							this.router.navigate(['/checkout/detail']);
						} else {
							this.progressButton.process = false;
							btn.data('isbusy', false);
							this.messageError = response.header.message_description;
							$('#modalMessage').modal('show');
						}
					},
					(error) => {
						this.progressButton.process = false;
						btn.data('isbusy', false);
						this.messageError = error.description;
						$('#modalMessage').modal('show');
						console.log('error => ', error);
					}
				);
			}

		} else {
			for (const field in this.infoFormGroup.controls) {
				this.infoFormGroup.get(field).markAsDirty({ onlySelf: true });
			}
		}
	}

	focusTarget(id: String) {
		$(id).focus();
	}

	focusInputField(event: Event) {
		let id: string = (event.target as Element).id;
		if (id == "lblInputInfo_1") {
			$("#lblInputInfo_2").focus();
		} else if (id == "lblInputInfo_2") {
			$("#lblInputInfo_3").focus();
		} else if (id == "lblInputInfo_3") {
			$("#lblInputInfo_5").focus();
		} else if (id == "lblInputInfo_5") {
			$("#lblInputInfo_6").focus();
		}
	}

}

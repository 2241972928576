<div style="border-top: solid 5px #1E4B74;">
  <div style="margin-top: 100px;">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="alert alert-danger text-center" role="alert">
            {{message}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>